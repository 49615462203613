import { Grid, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import { itemTypeList } from 'store/constant';
// import BasicPopover from 'ui-component/popover';

import BasicPopoverItem from 'ui-component/popoverItem';
import OutlinedDiv from 'views/filterPerformance/Outline';

const FilterAdvance = (props) => {
  const { isReset, tableFilter, setTableFilter, searchCampaign, setSearchCampaign, itemDropdown, handleItemDropdownChange } =
    props;

  return (
    <>
      <Grid item xs={12} md={3}>
        <div className="performance-wrapper">
          <OutlinedDiv label="Item">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Select
                value={itemDropdown}
                color="secondary"
                size="small"
                // label="Sory By"
                placeholder="Select Sort By"
                fullWidth
                variant="outlined"
                sx={{
                  '& fieldset': { border: 'none' },
                  padding: '2.5px 4px 2.5px 8px !important'
                  // width: "100px"
                }}
                onChange={(e) => handleItemDropdownChange(e.target.value)}
              >
                {itemTypeList?.map((item) => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
              ~
              <BasicPopoverItem
                tableFilter={tableFilter}
                setTableFilter={setTableFilter}
                isNoBorder={true}
                searchCampaign={searchCampaign}
                setSearchCampaign={setSearchCampaign}
                from={'Item id'}
                isReset={isReset}
              />
            </Box>
          </OutlinedDiv>
        </div>
      </Grid>
    </>
  );
};

export default FilterAdvance;
