import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import DownloadIcon from 'assets/images/dashna/Arrow.svg';
import editIcon from 'assets/images/dashna/edit.svg';
import MultiSelectIcon from 'assets/images/dashna/multiselect.svg';
import arrowDownIcon from 'assets/images/dashna/ArrowDown.svg';
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  // InputAdornment,
  InputLabel,
  Link,
  Menu,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TableCell,
  TableFooter,
  TextField,
  Tooltip,
  Typography,
  CircularProgress
} from '@mui/material';
import viewcolumnsIcon from 'assets/images/dashna/viewcolumns.svg';
import { debounce } from 'lodash';
import MUIDataTable from 'mui-datatables';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import {
  // getAlldvertiserList,
  getBrandDatas,
  getCampaignNameList,
  getItemsDetails,
  getSelectedFilterList,
  getAdGroupList,
  updateItemStatus,
  updateMultipleBidItem,
  updateMultipleItemBidPercentage,
  updateMultipleStatusItemMultiple
} from 'store/thunk/userThunk';
import MainCard from 'ui-component/cards/MainCard';
import CustomAutocomplete from '../../ui-component/multi-select/CustomAutocomplete';
import CloseIcon from '@mui/icons-material/Close';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import SearchIcon from '@mui/icons-material/Search';
import { TableRow } from '@mui/material';
import { Box } from '@mui/system';
import downIcon from 'assets/images/dashna/down.svg';
import upIcon from 'assets/images/dashna/up.svg';
import colors from 'assets/scss/_themes-vars.module.scss';
import { CAMPAIGN } from 'constant/campaignConstant';
import { alignRightColumn, commaStringToNumber, formateValue, getUpdatedColumns } from 'helper/commonHelper';
import moment from 'moment';
import { NestedDropdown } from 'mui-nested-menu';
import { /* `useLocation,` */ useNavigate, useParams } from 'react-router';
import { DateRangePicker, Loader } from 'rsuite';
import { PageValue, adGroup_state, attributeWindowList, campaigState, itemStatus } from 'store/constant';
import CustomColumnSelector from 'ui-component/CustomColumnSelector';
import CustomFilters from 'ui-component/Filters';
import SuccessAndFail from 'ui-component/SuccessAndFailModal';
import CompareValueWithColor from 'ui-component/compareWithValue';
import BasicPopover from 'ui-component/popover';
import BidPopover from 'ui-component/popover/BidPopover';
import TableSkeleton from 'ui-component/skeleton/TableSkeleton';
import __ from 'underscore';
import EditCampaignBrandSetting from 'views/campaign/editBrand/editCampaignSetting';
import ProductAdGroupSetting from 'views/campaign/editProduct/editAdgroupItems';
import EditCampaignSetting from 'views/campaign/editProduct/editCampaignSetting';
import TargetAdGroupSetting from 'views/campaign/editProduct/editKeywordItems';
import EditCampaignVideoSetting from 'views/campaign/editVideo';
import CustomTooltipPlacement from 'views/campaign/uiComponenet';
import FilterPerformance from 'views/filterPerformance';

import DisabledIcon from '../../assets/images/dashna/Disabled-cirlce.svg';
import EnabledIcon from '../../assets/images/dashna/enabled.svg';
import DeletedIcon from '../../assets/images/dashna/trash.svg';
import { setItemsDetails, setSkeletonLoader } from 'store/slices/userDetailsSlice';
import { useMemo } from 'react';

const Items = () => {
  const dispatch = useAppDispatch();
  const [focused, setFocused] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [rowPerPage, setRowPerPage] = useState(25);
  const [showDropdown, SetShowDropDown] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [search, setSearch] = useState('');
  const [successFailModal, setSuccessFailModal] = useState(false);
  const [SuccessFailData, setSuccessFailData] = useState([]);
  const [page, setPage] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedArray, setSelectedArray] = useState([]);
  const [isMultipleBidConfirmationOpen, setIsMultipleBidConfirmationOpen] = useState(false);
  const [bidType, setBidType] = useState('label');
  const [selectedOption, setSelectedOption] = useState('+');
  const [checkbox1Checked, setCheckbox1Checked] = useState(false);
  const [checkbox2Checked, setCheckbox2Checked] = useState(false);
  const [textField1Value, setTextField1Value] = useState('');
  const [textField2Value, setTextField2Value] = useState('');
  const [bidAmount, setBidAmount] = useState('');
  const [bidAmountCurrent, setBidAmountCurrent] = useState(100);
  const [bidAmountCurrentDollar, setBidAmountCurrentDollar] = useState('');
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [savedDataForEdit, setSaveDataForEdit] = useState({});
  const [DateStateHealth, SetDateStateHealth] = useState({});
  const [StatusValueForCondition, setStatusForCondition] = useState('');
  const [isDropdownOpenAdgroup, setIsDropdownOpenAdgroup] = useState(false);
  const [isCsvDownloadComplete, setIsCsvDownloadComplete] = useState(false);
  const [showSelectAdviser, setShowAdviser] = useState(false);
  const [filterValue, setFilterValue] = useState({
    campaign__in: [],
    campaign: [],
    brand: [],
    campaign_state: [],
    campaign_type: [],
    adGroup_state: [],
    item_status: [],
    attr_windows: [],
    ad_group: [],
    adgroup__in: []
  });
  const [tableFilter, setTableFilter] = useState({
    campaign__in: '',
    adgroup__in: '',
    campaign: '',
    brand: '',
    campaign_state: '',
    campaign_type: '',
    adGroup_state: '',
    item_status: '',
    attr_windows: '',
    ad_group: ''
  });
  const [filterForSearch, setFilterForSearch] = useState({});
  //For Select All Checkbox
  const [isSelectAll, setIsSelectAll] = useState({
    campaign_search: [],
    campaign_type: [],
    status: [],
    ad_group_status: [],
    item_status: [],
    brand: []
  });

  const [loading, setLoading] = useState(false);
  const [searchCampaign, setSearchCampaign] = useState('');
  const [groupBy, setGroupBy] = useState({ id: 2, name: 'Ad Group', value: 'adgroup' });
  const campaign_type = [
    { id: 1, name: CAMPAIGN.CAMPAIGN_TYPE.SB, value: 'sba' },
    { id: 2, name: CAMPAIGN.CAMPAIGN_TYPE.SP_AUTO, value: 'sponsoredProducts', type: CAMPAIGN.TARGETING_TYPE.AUTO },
    { id: 3, name: CAMPAIGN.CAMPAIGN_TYPE.SP_MANUAL, value: 'sponsoredProducts', type: CAMPAIGN.TARGETING_TYPE.MANUAL },
    { id: 4, name: CAMPAIGN.CAMPAIGN_TYPE.SV, value: 'video' }
  ];
  const [GroupByValue, setGroupByValue] = useState(false);
  const [viewColumnsSelector, setViewColumnsSelector] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [tableHeight, setTableHeight] = useState('550px');
  const selectedProfiles = localStorage.getItem('selectedProfiles');

  //for resize table based on screen size
  useEffect(() => {
    const handleResize = () => {
      const calculatedHeight = window.innerHeight * 0.8;
      setTableHeight(`${calculatedHeight}px`);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  /* ================================ Autocomplete Filters Start ================================*/

  //Filter Campaign, Campaign Status and Campaign Type Dropdown
  const handleAutoCompleteOnChange = (newValue, filterKey) => {
    let paramKey = '';
    if (filterKey === 'campaign_search' || filterKey === 'ad_group_search') {
      paramKey = 'id';
    } else if (
      filterKey === 'campaign_type' ||
      filterKey === 'status' ||
      filterKey === 'ad_group_status' ||
      filterKey === 'item_status' ||
      filterKey === 'brand'
    ) {
      paramKey = 'value';
    }

    const idArray = newValue?.map((item) => item?.[paramKey]);
    const uniqueIdArray = [...new Set(idArray)];
    let idString = uniqueIdArray?.join(',') || '';

    let baseArray = [];
    let tableStateFilterKey = '';

    if (filterKey === 'campaign_search') {
      baseArray = [...campaignNameList];
      tableStateFilterKey = 'campaign__in';
    } else if (filterKey === 'campaign_type') {
      baseArray = [...campaign_type];
      tableStateFilterKey = 'type';
    } else if (filterKey === 'status') {
      baseArray = [...campaigState];
      tableStateFilterKey = 'status';
    } else if (filterKey === 'ad_group_search') {
      baseArray = [...adGroupShortList];
      tableStateFilterKey = 'adgroup__in';
    } else if (filterKey === 'ad_group_status') {
      baseArray = [...adGroup_state];
      tableStateFilterKey = 'adGroup_state';
    } else if (filterKey === 'item_status') {
      baseArray = [...itemStatus];
      tableStateFilterKey = 'item_status';
    } else if (filterKey === 'brand') {
      baseArray = [...allBrandLists];
      tableStateFilterKey = 'brand';
    }

    let targetString = '';
    if (filterKey === 'campaign_type') {
      const targetArray = newValue?.map((item) => item?.type);
      targetString = targetArray.filter((item) => item !== undefined).join(',');
    }

    //Check if Select All checkbox is slected
    if (newValue.find((option) => option.all)) {
      const searchTerm = searchText?.[filterKey]?.toLowerCase() || ''; //Change as per selected filter
      const filteredCampaigns = searchTerm
        ? baseArray.filter((item) => item?.name?.toLowerCase().includes(searchTerm))
        : baseArray;
      const allIdsCam = filteredCampaigns.map((obj) => obj.id);

      setIsSelectAll((prevState) => ({
        ...prevState,
        [filterKey]: filteredCampaigns
      }));
      idString =
        newValue.length === 1 || newValue.length <= filteredCampaigns.length
          ? [...new Set(filteredCampaigns.map((item) => item?.[paramKey]))].join(',')
          : '';
      setTableFilter((prevState) => ({
        ...prevState,
        ...(filterKey === 'campaign_type' && { targeting: '' }),
        [tableStateFilterKey]: idString
      }));
      setFilterValue((prevState) => ({
        ...prevState,
        [tableStateFilterKey]: newValue.length - 1 === allIdsCam.length ? [] : filteredCampaigns
      }));
    } else {
      setTableFilter((prevState) => ({
        ...prevState,
        ...(filterKey === 'campaign_type' && { targeting: targetString }),
        [tableStateFilterKey]: idString
      }));
      setFilterValue((prevState) => ({
        ...prevState,
        [tableStateFilterKey]: newValue
      }));
    }
  };

  //For setting Select All checkbox for Filter Campaign, Campaign Status and Campaign Type Dropdown
  const handleAutoCompleteSelectAll = (filterKey) => {
    let baseArray = [];
    let tableStateFilterKey = '';

    if (filterKey === 'campaign_search') {
      baseArray = [...campaignNameList];
      tableStateFilterKey = 'campaign__in';
    } else if (filterKey === 'campaign_type') {
      baseArray = [...campaign_type];
      tableStateFilterKey = 'type';
    } else if (filterKey === 'status') {
      baseArray = [...campaigState];
      tableStateFilterKey = 'status';
    } else if (filterKey === 'ad_group_status') {
      baseArray = [...adGroup_state];
      tableStateFilterKey = 'adGroup_state';
    } else if (filterKey === 'item_status') {
      baseArray = [...itemStatus];
      tableStateFilterKey = 'item_status';
    } else if (filterKey === 'brand') {
      baseArray = [...allBrandLists];
      tableStateFilterKey = 'brand';
    }

    const status =
      filterValue?.[tableStateFilterKey]?.length && isSelectAll?.[filterKey]?.length
        ? !!(filterValue?.[tableStateFilterKey]?.length == baseArray?.length) ||
          !!(filterValue?.[tableStateFilterKey]?.length == isSelectAll?.[filterKey]?.length)
        : false;

    return status;
  };

  /* ================================ Autocomplete Filters End ================================*/

  useEffect(() => {
    if (groupBy?.id == 2) {
      setGroupByValue(true);
    } else {
      setGroupByValue(false);
    }
  }, [groupBy]);

  // startDate
  // const handleCustomRange = () => {
  //   const today = moment().endOf('day');
  //   const start = moment(today).subtract(179, 'days').startOf('day').toDate();
  //   handleDateRange(start, today.toDate());
  //   setShow(true);
  // };

  // const predefinedRanges = [
  //   {
  //     label: 'Custom Range',
  //     placement: 'left',
  //     value: [new Date(), new Date()],
  //     onClick: handleCustomRange
  //   }
  // ];

  const {
    itemsDetails,
    itemsDetailsTotal,
    filteredData,
    commonAdvertiser,
    campaignNameList,
    commonDateRange,
    brandLists,
    adGroupShortList,
    isComparingDates,
    skeletonLoader
  } = useAppSelector((state) => state?.userDetailsSlice);
  const { userData } = useAppSelector((state) => state.authorization);

  //Prevent Un-necessary loading when no profile changed
  const previousAdvertiser = useRef(commonAdvertiser);
  const isFirstRender = useRef(true);
  const shouldApplyFilter = useRef(true);

  // const location = useLocation();
  const params = useParams();
  // const Type = params?.type;
  // const IsNoSelected = ['campaign_name', 'adgroup_name', 'campaign_type'];
  const [filterDataList, setFilterDataList] = useState({});
  const [addDate, setAddDate] = useState([]);
  const [performanceRanges, setPerformanceRanges] = useState({}); //When change performance filter input
  const [PerformanceFilterData, setPerformanceFilterData] = useState(); //when click on Search button
  const [filterForSearchAddDate, setFilterForSearchAddDate] = useState([]);
  const [bidRanges, setBidRanges] = useState({});
  const [PerformanceFilterBidRanges, setPerformanceFilterBidRanges] = useState({});
  // const [initialFilters, setInitialFilters] = useState(false);
  const [attribute, setAttribute] = useState('14');
  const [error, setError] = useState({});
  const [hoveredRow, setHoveredRow] = useState(null);
  const [edit, setEdit] = useState({ campaign: null, total_budget: null, daily_budget: null, status: null, end_date: null });
  const [newData, setNewData] = useState();
  const [newDataTotal, setNewDataTotal] = useState();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // const [isDropdownOpenAdgroup, setIsDropdownOpenAdgroup] = useState(false);
  const [isDropdownOpenCamType, setIsDropdownOpenCamType] = useState(false);
  const [isDropdownOpenCamAdSt, setIsDropdownOpenCamAdSt] = useState(false);
  const [isDropdownOpenCamSt, setIsDropdownOpenCamSt] = useState(false);
  const [isDropdownOpenCamStItemSt, setIsDropdownOpenCamStItemSt] = useState(false);
  const [isDropdownOpenBrand, setIsDropdownOpenBrand] = useState(false);
  const [allBrandLists, setAllBrandLists] = useState([]);
  const [selectedItemsId, SetSelectedItemsId] = useState([]);
  const [campaignType, setCampaignType] = useState([]);
  const [statusLoader, setStatusLoader] = useState('');
  const [inlineLoader, setInlineLoader] = useState(null);
  // const [showSuggestedBid, setShowSuggestedBid] = useState(false);
  const [searchText, setSearchText] = useState({
    campaign_search: null,
    campaign_type: null,
    status: null,
    ad_group_status: null,
    ad_group_search: null,
    item_status: null
  });
  const [valueGroup, setValueGroup] = useState('adgroup');
  const [selectedAdgroup, setSelectAdgroup] = useState({ advertiser: null, campaign: null });
  const [targetingType, setTargetingType] = useState('');
  const [isManual, setIsManual] = useState('');
  const [campaignModal, setCampaignModal] = useState({ open: false, id: null });
  const [campaignBrandModal, setCampaignBrandModal] = useState({ open: false, id: null });
  const [campaignVideoModal, setCampaignVideoModal] = useState({ open: false, id: null });
  const [itemModal, setItemModal] = useState({ open: false, id: null, advertiser_id: null });
  const [keywordModal, setKeywordModal] = useState({ open: false, id: null, name: '' });
  const [anchorEl, setAnchorEl] = React.useState(null);

  //Permission checking
  const items = userData?.permission?.items || {};
  const itemsUpdate = items?.update;
  const itemsDownload = items?.download;

  const handleKeywordModalClose = () => {
    setKeywordModal({ open: false, id: null });
  };

  const handleItemModalClose = () => {
    setItemModal({ open: false, id: null, name: null });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handledEditClose = () => {
    setCampaignModal({ open: false, id: null });
  };

  const handledEditBrandClose = () => {
    setCampaignBrandModal({ open: false, id: null });
  };

  const handleEditVideoClose = () => {
    setCampaignVideoModal({ open: false, id: null });
  };

  const open = Boolean(anchorEl);
  const handleClick = (event, value, name) => {
    setSelectAdgroup({ advertiser: name?.ad_id, campaign: value });
    setAnchorEl(event.currentTarget);
    setTargetingType(name?.campaign_type);
    name?.targeting_type == CAMPAIGN.TARGETING_TYPE.MANUAL ? setIsManual(true) : setIsManual(false);
    setCampaignModal({ ...campaignModal, id: value });
    setItemModal({
      ...itemModal,
      id: value,
      name: name?.campaign_name,
      advertiser_id: name?.ad_id,
      targeting_type: name?.targeting_type
    });
    setCampaignBrandModal({ ...campaignBrandModal, id: value });
    setCampaignVideoModal({
      ...campaignVideoModal,
      id: value,
      advertiser_id: name?.ad_id,
      adgroup: name?.adgroup_number
    });
    setKeywordModal({ ...keywordModal, id: value, name: name?.campaign_name });
  };

  const handleAddAdGroup = () => {
    navigate('/create-adgroup', { state: { advertise: selectedAdgroup.advertiser, id: selectedAdgroup.campaign } });
  };

  const setInitialPageFilters = () => {
    setBidRanges({});
    setFilterValue({});
    setTableFilter({});
    setFilterForSearch({});
    setPerformanceFilterData({});
    setPerformanceRanges({});
    setAddDate([]);
    setSearchText({}); //Reset Type Text in Autocomplete Dropdown Filter
    setSearchCampaign(''); //Reset Search Item Id
    setFocused(false);
  };

  //Reset filters when we change Profile
  useMemo(() => {
    if (!shouldApplyFilter?.current) {
      setInitialPageFilters();
      // setFilterValue({}); //Rest Selected Value
      // setSearchText({}); //Reset Type Text in Autocomplete Dropdown Filter
      // setSearchCampaign(''); //Reset Search Item Id
      // setFocused(false);
      // setAddDate([]);
    }
  }, [shouldApplyFilter?.current]);

  useEffect(() => {
    if (adGroupShortList && params?.adgroupId) {
      let TempFilterValue = { ...filterValue };
      const findAdgroup = adGroupShortList?.find((adgroup) => adgroup?.id == params?.adgroupId) || {};
      TempFilterValue = { ...TempFilterValue, ad_group: [findAdgroup] };
      setFilterValue(TempFilterValue);
    }
  }, [adGroupShortList]);

  //Create array of object of brand instead of only array
  useEffect(() => {
    const brandArr = brandLists.map((l, i) => {
      return {
        id: i + 1,
        name: l,
        value: l
      };
    });
    setAllBrandLists(brandArr);
  }, [brandLists]);

  useEffect(() => {
    if (filteredData && filteredData?.length > 0) {
      if (filteredData[0]?.aditem_column) {
        let tempColumns = [...columns];
        tempColumns = tempColumns.filter((column) => column?.options?.display !== false);

        filteredData[0]?.aditem_column?.map((col) => {
          const columnsIndex = tempColumns?.findIndex((column) => column?.name === col?.name);
          if (columnsIndex !== -1) {
            tempColumns[columnsIndex].options.display = col?.display;
          }
        });
        setSelectedColumns(tempColumns);
      }

      setFilterDataList(filteredData[0]?.aditem_filter);
      let TemptableFilter = { ...tableFilter };
      let TempFilterValue = { ...filterValue };

      if (filteredData[0]?.aditem_filter?.Common?.findIndex((item) => item?.value == 'state') === -1) {
        TemptableFilter.campaign_state = '';
        TempFilterValue.campaign_state = [];
      }
      if (filteredData[0]?.aditem_filter?.Common?.findIndex((item) => item?.value == 'campaign') === -1) {
        TemptableFilter.campaign__in = '';
        TempFilterValue.campaign__in = [];
      }
      if (filteredData[0]?.aditem_filter?.Common?.findIndex((item) => item?.value == 'adgroup_Filter') === -1) {
        TemptableFilter.ad_group = '';
        TempFilterValue.ad_group = [];
      }

      if (filteredData[0]?.aditem_filter?.Common?.findIndex((item) => item?.value == 'adgroup_state') === -1) {
        TemptableFilter.adGroup_state = '';
        TempFilterValue.adGroup_state = [];
      }
      if (filteredData[0]?.aditem_filter?.Common?.findIndex((item) => item?.value == 'campaign_type') === -1) {
        TemptableFilter.campaign_type = '';
        TemptableFilter.targeting = '';
        TempFilterValue.campaign_type = [];
      }
      if (filteredData[0]?.aditem_filter?.Common?.findIndex((item) => item?.value == 'item_status') === -1) {
        TemptableFilter.item_status = '';
        TempFilterValue.item_status = [];
      }

      if (filteredData[0]?.aditem_filter?.Common?.findIndex((item) => item?.value == 'adgroup') === -1) {
        setGroupBy({ id: 2, name: 'Ad Group', value: 'adgroup' });
      }

      if (filteredData[0]?.aditem_filter?.Common?.findIndex((item) => item?.value == 'bid') === -1) {
        setBidRanges({});
      }

      if (filteredData[0]?.aditem_filter?.Common?.findIndex((item) => item?.value == 'brand') === -1) {
        TemptableFilter.brand = '';
        TempFilterValue.brand = [];
      }

      if (filteredData[0]?.aditem_filter?.Common?.findIndex((item) => item?.value == 'attr_window') === -1) {
        TemptableFilter.attr_windows = '';
        TempFilterValue.attr_windows = '';
      }

      setTableFilter(TemptableFilter);
      setFilterValue(TempFilterValue);

      if (Object.keys(performanceRanges).length > 0) {
        Object.keys(performanceRanges).map((appliedFilter) => {
          let tempPerformanceRanges = { ...performanceRanges };
          if (filteredData[0]?.campaign_filter?.Performance.findIndex((obj) => obj.value === appliedFilter) === -1) {
            delete tempPerformanceRanges[appliedFilter];
          }
          setPerformanceRanges(tempPerformanceRanges);
        });
      }
    }
  }, [filteredData]);

  const formateData = (keyArr) => {
    const formattedData = {};
    keyArr.forEach(([key, value]) => {
      formattedData[key] = formateValue(key, value);
    });
    return formattedData;
  };

  const handleChangeDate = (value) => {
    if (value) {
      shouldApplyFilter.current = true;
      setSelectedDate(value);
      setAddDate([moment(value[0]).format('YYYY-MM-DD'), moment(value[1]).format('YYYY-MM-DD')]);
    } else {
      shouldApplyFilter.current = false;
      setFocused(false);
      setAddDate([]);
      setSelectedDate(null);
    }
  };

  useEffect(() => {
    // if (itemsDetails && itemsDetails?.results?.length > 0) {
    const finalData = itemsDetails?.results?.map((val) => {
      return {
        ...formateData(Object.entries(val)),
        details: {
          images: val?.images,
          item_id: val?.item_id,
          product_name: val?.product_name
        }
      };
    });

    if (itemsDetailsTotal) {
      const TotalRowData = formateData(Object.entries(itemsDetailsTotal));

      setNewDataTotal(TotalRowData);
    }

    if (itemsDetails?.results?.length == 0) {
      dispatch(setSkeletonLoader(false));
    }

    if (finalData?.length > 0 && finalData[0]?.as_of_date?.length > 0) {
      let DateWithMmYy = moment(finalData[0]?.as_of_date).format('MM/DD');
      const subtractedDate = moment(finalData[0]?.as_of_date).subtract(7, 'days');
      const formattedDate7Days = subtractedDate.format('MM/DD');

      const datesFoHealthReport = {
        SimpleDate: DateWithMmYy,
        WeekMinus: formattedDate7Days
      };
      SetDateStateHealth(datesFoHealthReport);
    }
    setNewData(finalData);

    let tempColumns = [...columns];
    if (filteredData[0]?.aditem_column) {
      tempColumns = tempColumns.filter((column) => column?.options?.display !== false);

      filteredData[0]?.aditem_column?.map((col) => {
        const columnsIndex = tempColumns?.findIndex((column) => column?.name === col?.name);
        if (columnsIndex !== -1) {
          tempColumns[columnsIndex].options.display = col?.display;
        }
      });
    }
    setSelectedColumns(tempColumns);
  }, [itemsDetails]);

  useEffect(() => {
    if (newData?.length > 0) {
      dispatch(setSkeletonLoader(false));
    }
  }, [newData]);

  useEffect(() => {
    if (isCsvDownloadComplete) {
      setIsCsvDownloadComplete(false);
      dispatch(setSkeletonLoader(false));
    }
  }, [isCsvDownloadComplete]);

  const navigate = useNavigate();
  const getAdvertiserItemListWithPagination = (
    page,
    rowPerPage,
    search = '',
    changedColumn,
    direction,
    commonAdvertiserList,
    PerformanceFilter,
    tableFilter = {
      campaign__in: '',
      adgroup__in: '',
      campaign: '',
      campaign_type: '',
      campaign_state: '',
      adGroup_state: '',
      item_status: '',
      brand: '',
      attr_windows: ''
    },
    bidRanges,
    addDate,
    is_csv
  ) => {
    !is_csv && setTableLoading(true);
    const commonDate = commonDateRange;
    if ((commonDate?.startDate && commonDate?.endDate) || params?.id) {
      dispatch(
        getItemsDetails({
          page,
          rowPerPage,
          search,
          changedColumn,
          direction,
          commonAdvertiserList,
          PerformanceFilter,
          tableFilter,
          bidRanges,
          addDate,
          groupBy,
          commonDate,
          is_csv,
          isComparingDates,
          setIsCsvDownloadComplete
        })
      ).then(() => {
        setEdit({ campaign: null, total_budget: null, daily_budget: null, status: null, end_date: null });
        setTableLoading(false);
      });
    } else {
      dispatch(setSkeletonLoader(false));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getSelectedFilterList());

    applyParamsTableFilter();
  }, []);

  useEffect(() => {
    if (!selectedProfiles) {
      dispatch(setSkeletonLoader(false)); // Stop loader if no profile selected
    } else {
      setNewData([]);
      dispatch(setSkeletonLoader(true));
    }
  }, [selectedProfiles, dispatch]);

  const updateKeywordDetails = (details, ids, updates) => {
    return {
      ...details,
      results: details.results.map((campaign) =>
        ids.includes(campaign.id)
          ? {
              ...campaign,
              ...updates.reduce((acc, update) => ({ ...acc, ...update }), {})
            }
          : campaign
      )
    };
  };

  const resetFailedKeywordAttribute = (updatedCampaignDetails, campaignDetails, errorMessages, key) => {
    const failedIds = errorMessages.map((error) => error.id);
    const updatedResults = updatedCampaignDetails.results.map((campaign) => {
      if (failedIds.includes(campaign.id)) {
        const originalCampaign = campaignDetails.results.find((orig) => orig.id === campaign.id);
        return {
          ...campaign,
          [key]: originalCampaign ? originalCampaign[key] : campaign[key]
        };
      }
      return campaign;
    });

    return {
      ...updatedCampaignDetails,
      results: updatedResults
    };
  };

  useEffect(() => {
    const campaignID = params?.id;
    if (campaignNameList?.length > 0 && campaignID && commonAdvertiser?.length > 0) {
      const find = campaignNameList?.findIndex((item) => item?.id == campaignID);
      if (find === -1) {
        setFilterValue({ campaign__in: [], ad_group: [] });
      } else {
        setFilterValue({ campaign__in: [campaignNameList[find]] });
      }
    } else {
      setFilterValue({ campaign__in: [], ad_group: [] });
    }
  }, [campaignNameList]);

  useEffect(() => {
    dispatch(getBrandDatas());
  }, []);

  const getItemsList = (newpage, pageRow, isDownload) => {
    if (commonAdvertiser.length > 0) {
      setShowAdviser(true);
      if (params?.id) {
        let tempTableFilter = {};
        shouldApplyFilter.current = true;

        if (params?.type === 'Adgroup' && params?.adgroupId) {
          tempTableFilter = {
            ...tableFilter,
            campaign__in: params?.id,
            adgroup__in: params?.adgroupId
          };
        } else {
          tempTableFilter = {
            ...tableFilter,
            campaign__in: params?.id
          };
        }
        setTableFilter(tempTableFilter);
        getAdvertiserItemListWithPagination(
          newpage !== 'undefined' || newpage !== null ? newpage : page,
          pageRow || rowPerPage,
          search,
          appliedSortTing?.changedColumnSort,
          appliedSortTing?.directionSort,
          commonAdvertiser,
          shouldApplyFilter?.current ? PerformanceFilterData : {},
          shouldApplyFilter?.current ? tempTableFilter : {},
          shouldApplyFilter?.current ? PerformanceFilterBidRanges : {},
          shouldApplyFilter?.current ? filterForSearchAddDate : [],
          isDownload
        );
      } else {
        getAdvertiserItemListWithPagination(
          newpage !== 'undefined' || newpage !== null ? newpage : page,
          pageRow || rowPerPage,
          search,
          appliedSortTing?.changedColumnSort,
          appliedSortTing?.directionSort,
          commonAdvertiser,
          shouldApplyFilter.current ? PerformanceFilterData : {},
          shouldApplyFilter.current ? filterForSearch : {},
          shouldApplyFilter.current ? PerformanceFilterBidRanges : {},
          shouldApplyFilter?.current ? filterForSearchAddDate : [],
          isDownload
        );
      }
    } else {
      setShowAdviser(false);
    }
  };

  //To prevent aditem-list api call two time, i merge two useEffect to one
  useEffect(() => {
    if (JSON.stringify(previousAdvertiser.current) !== JSON.stringify(commonAdvertiser) || isFirstRender?.current) {
      if (commonAdvertiser.length > 0) {
        dispatch(getCampaignNameList({ id: commonAdvertiser }));
        dispatch(getAdGroupList({ id: commonAdvertiser }));
      }
      setInitialPageFilters();
      shouldApplyFilter.current = false;
      getItemsList(0, rowPerPage);
      previousAdvertiser.current = commonAdvertiser;
      isFirstRender.current = false;
    } else if (commonDateRange) {
      shouldApplyFilter.current = true;
      getItemsList(0, rowPerPage);
    }
  }, [commonAdvertiser, commonDateRange]);

  const applyParamsTableFilter = () => {
    let tempTableFilter = { ...tableFilter };
    if (params?.id) {
      tempTableFilter = {
        ...tempTableFilter,
        campaign__in: params?.id
      };
    }
    setTableFilter(tempTableFilter);
  };

  const handleSearchClick = () => {
    shouldApplyFilter.current = true;
    setFilterForSearch({ ...tableFilter });
    setPerformanceFilterBidRanges({ ...bidRanges });
    setFilterForSearchAddDate([...addDate]);
    const filledPerformanceRanges = Object.entries(performanceRanges).reduce((acc, [performanceId, values]) => {
      const { min, max } = values;
      if (min?.length > 0 || max?.length > 0) {
        acc[performanceId] = values;
      }
      return acc;
    }, {});

    const comparisonValues =
      filterDataList?.Performance &&
      filterDataList?.Performance?.length > 0 &&
      filterDataList?.Performance.map((item) => item.value);

    // Filter dataRanges based on comparisonValues
    var PerformanceFilter =
      comparisonValues &&
      Object.fromEntries(Object.entries(filledPerformanceRanges).filter(([key]) => comparisonValues.includes(key)));

    setPerformanceFilterData(PerformanceFilter);
    setValueGroup(groupBy?.value);

    if (tableFilter.campaign__in) {
      getAdvertiserItemListWithPagination(
        0,
        rowPerPage,
        '',
        appliedSortTing?.changedColumnSort,
        appliedSortTing?.directionSort,
        commonAdvertiser,
        PerformanceFilter ? PerformanceFilter : '',
        tableFilter,
        bidRanges ? bidRanges : '',
        addDate ? addDate : ''
      );
    } else if (params?.id && filterValue?.campaign__in[0]) {
      const campaignID = { campaign__in: params?.id };
      getAdvertiserItemListWithPagination(
        page,
        rowPerPage,
        search,
        appliedSortTing?.changedColumnSort,
        appliedSortTing?.directionSort,
        commonAdvertiser,
        PerformanceFilter ? PerformanceFilter : '',
        campaignID,
        bidRanges,
        addDate
      );
    } else {
      getAdvertiserItemListWithPagination(
        0,
        rowPerPage,
        search,
        appliedSortTing?.changedColumnSort,
        appliedSortTing?.directionSort,
        commonAdvertiser,
        PerformanceFilter ? PerformanceFilter : '',
        tableFilter,
        bidRanges,
        addDate
      );
    }

    SetShowDropDown(false);
  };

  const handleSaveButton = (v, id, type) => {
    setStatusLoader(type);
    const payload = {
      ids: id,
      newPayload: {
        status: v
      },
      defaultData: {
        page,
        rowPerPage,
        search,
        changedColumn: appliedSortTing?.changedColumnSort,
        direction: appliedSortTing?.directionSort,
        commonAdvertiserList: commonAdvertiser,
        PerformanceFilter: PerformanceFilterData || '',
        tableFilter: filterForSearch,
        groupBy,
        commonDate: commonDateRange,
        isComparingDates
      },
      oldData: itemsDetails
    };
    setSaveDataForEdit(payload);

    setDeleteConfirmationOpen(true);
  };

  const handleSaveButtonBid = (v, id) => {
    const payload = {
      ids: id,
      newPayload: {
        bid_amount: commaStringToNumber(v)
      },
      defaultData: {
        page,
        rowPerPage,
        search,
        changedColumn: appliedSortTing?.changedColumnSort,
        direction: appliedSortTing?.directionSort,
        commonAdvertiserList: commonAdvertiser,
        PerformanceFilter: PerformanceFilterData || '',
        tableFilter: filterForSearch,
        groupBy,
        commonDate: commonDateRange,
        isComparingDates
      },
      oldData: itemsDetails
    };
    setSaveDataForEdit(payload);
    setDeleteConfirmationOpen(true);
  };

  const handleCloseDeleteConfirmation = () => {
    // setReportToDelete(null);
    setInlineLoader({ Status: null });
    setStatusLoader('');

    setHoveredRow(null);
    setEdit({ campaign: null, total_budget: null, daily_budget: null, status: null, end_date: null });
    setDeleteConfirmationOpen(false);
  };

  const handleDeleteReport = () => {
    if (savedDataForEdit) {
      setLoading(true);
      setInlineLoader({ Status: savedDataForEdit?.ids });
      dispatch(updateItemStatus(savedDataForEdit)).then((res) => {
        if (res.payload) {
          setEdit({ campaign: null, total_budget: null, daily_budget: null, status: null, end_date: null });
          setHoveredRow(null);
          // setAllKeywordDataApi(true);/
          setLoading(false);
          setDeleteConfirmationOpen(false);

          setInlineLoader({ Status: null });
          setStatusLoader('');
        } else {
          setEdit({ campaign: null, total_budget: null, daily_budget: null, status: null, end_date: null });
          setHoveredRow(null);
          // setAllKeywordDataApi(true);/
          setLoading(false);
          setDeleteConfirmationOpen(false);
          setInlineLoader({ Status: null });
          setStatusLoader('');
        }
      });
      setDeleteConfirmationOpen(false);
    }
  };
  const [isMultipleStatusConfirmationOpen, setIsMultipleStatusConfirmationOpen] = useState(false);
  const handleItemsStatusUpdate = (id, event, type) => {
    const _request = {
      [type]: event.target.value
    };
    const existGroupId = id;
    setSaveDataForEdit({ _request, existGroupId });
    handleSaveButton(event.target.value, existGroupId, type);
    setDeleteConfirmationOpen(true);
  };

  const handleColumnSelectorOpen = () => {
    setViewColumnsSelector(true);
  };

  const handleColumnSelectorClose = () => {
    setViewColumnsSelector(false);
  };

  const handleChange = (value) => {
    if (value == 'Enabled') {
      setStatusForCondition('Enabled');
      setIsMultipleStatusConfirmationOpen(true);
    }
    if (value == 'Disabled') {
      setStatusForCondition('Disabled');
      setIsMultipleStatusConfirmationOpen(true);
    }
    if (value == 'Deleted') {
      setStatusForCondition('Deleted');
      setIsMultipleStatusConfirmationOpen(true);
    }
  };

  const handleChangeDropdownStatus = () => {
    let payload11 = {
      payload: {
        aditem_id: selectedArray,
        status: StatusValueForCondition?.toLowerCase()
      },
      defaultData: {
        page,
        rowPerPage,
        search,
        changedColumn: appliedSortTing?.changedColumnSort,
        direction: appliedSortTing?.directionSort,
        commonAdvertiserList: commonAdvertiser,
        PerformanceFilter: PerformanceFilterData,
        tableFilter: filterForSearch,
        groupBy,
        commonDate: commonDateRange,
        isComparingDates
      },
      oldData: itemsDetails
    };
    setLoadingStatus(true);
    const updatedKeywordDetails = updateKeywordDetails(itemsDetails, selectedArray, [
      { status: StatusValueForCondition?.toLowerCase() }
    ]);
    dispatch(updateMultipleStatusItemMultiple(payload11)).then((res) => {
      if (res.payload.status == 200) {
        const { data } = res.payload;
        const { totalcount: totalCount, successcount: successCount, error_message: statusChangeFailedId } = data.data;
        const finalCampaignDetails =
          totalCount !== successCount
            ? resetFailedKeywordAttribute(updatedKeywordDetails, itemsDetails, statusChangeFailedId, 'status')
            : updatedKeywordDetails;
        dispatch(setItemsDetails(finalCampaignDetails));
        setLoadingStatus(false);
        SetShowDropDown(false);
        setSuccessFailModal(true);
        setSuccessFailData(res?.payload?.data?.data);
        setIsMultipleStatusConfirmationOpen(false);
        setStatusForCondition('');
      }
    });
  };

  const handleCustomTableHead = ({
    columnMeta,
    handleToggleColumn,
    sortOrder,
    columnName,
    columnLabel_1,
    columnLabel_2 = '',
    customCss = {},
    isLabelAlignRight = false,
    isParentLabelAlignRight = false
  }) => {
    return (
      <TableCell
        sx={{
          position: 'sticky',
          top: '0px',
          zIndex: 1,
          cursor: columnMeta?.sort ? 'pointer' : 'text',
          ...customCss
        }}
        onClick={columnMeta?.sort ? () => handleToggleColumn(columnMeta?.index) : undefined}
        key={columnMeta?.index}
      >
        <Box display="flex" alignItems="center" justifyContent={isParentLabelAlignRight ? 'flex-end' : 'flex-start'} gap={'5px'}>
          <Box
            display="flex"
            alignItems={isLabelAlignRight ? 'flex-end' : 'flex-start'}
            flexDirection="column"
            justifyContent="flex-start"
          >
            <Tooltip title={columnMeta?.sort ? 'Sort' : ''}>
              <Box className="clr3 fw700 ffInter">{columnLabel_1}</Box>
              {columnLabel_2 && <Box className="clr3 fw700 ffInter">{columnLabel_2}</Box>}
            </Tooltip>
          </Box>
          {!__.isEmpty(sortOrder) && (
            <Box>
              {sortOrder?.direction === 'asc' && sortOrder?.name === columnName ? <img src={upIcon} alt="icon" /> : null}
              {sortOrder?.direction === 'desc' && sortOrder?.name === columnName ? <img src={downIcon} alt="icon" /> : null}
            </Box>
          )}
        </Box>
      </TableCell>
    );
  };

  const columns = [
    {
      name: 'details',
      label: 'Item',
      default: true,
      options: {
        filter: true,
        sort: true,
        viewColumns: false,
        sticky: true,
        leftTotal: 40,
        FontSize: '22px',
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          const customCss = { zIndex: 101, left: '40px', minWidth: '380px' };
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'details',
            columnLabel_1: 'Items',
            customCss
          });
        },
        customBodyRender: (value) => {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {value?.images ? (
                <Box component={'img'} sx={{ padding: 1, height: '40px', width: '40px' }} src={value?.images} />
              ) : (
                <InsertPhotoIcon fontSize="large" sx={{ margin: '4px', color: colors.grey500 }} />
              )}
              <Box>
                <Typography className="clr2" fontWeight={'400'}>
                  {value?.product_name?.length > 40 ? (
                    <Tooltip
                      placement="top"
                      sx={{ fontSize: '10px' }}
                      title={
                        <Typography variant="body1" sx={{ fontSize: 16 }}>
                          {value?.product_name}
                        </Typography>
                      }
                      arrow
                    >
                      {value?.product_name.substring(0, 40)}...
                    </Tooltip>
                  ) : (
                    value?.product_name
                  )}
                </Typography>

                {value?.item_id ? (
                  <Link
                    sx={{ cursor: 'pointer' }}
                    onClick={() => window.open(`https://www.walmart.com/ip/${value?.item_id}`, '_blank')}
                  >
                    <Typography sx={{ color: colors.primaryMain, fontWeight: 400 }}>{value?.item_id}</Typography>
                  </Link>
                ) : (
                  <>------</>
                )}
              </Box>
            </Box>
          );
        },
        ...(showSelectAdviser && {
          setCellProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: 40,
              minWidth: '380px',
              background: 'white',
              zIndex: 100
            }
          }),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: 40,
              minWidth: '380px',
              background: 'white',
              zIndex: 101
            }
          })
        })
      }
    },
    valueGroup == 'aditem' || valueGroup == 'campaign'
      ? {
          name: 'status',
          label: 'Status ',
          default: true,
          options: {
            display: false,
            filter: true,
            sort: true,
            viewColumns: false,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
              const customCss = { zIndex: 101, left: '380px', width: '90px' };
              return handleCustomTableHead({
                columnMeta,
                handleToggleColumn,
                sortOrder,
                columnName: 'status',
                columnLabel_1: 'Status',
                customCss
              });
            },
            customBodyRender: (value, rowData) => {
              const data = itemsDetails?.results ? itemsDetails?.results[rowData?.rowIndex] : [];
              return (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {statusLoader == 'status' && inlineLoader?.Status && inlineLoader?.Status === data?.id ? (
                    <Loader />
                  ) : valueGroup === 'adgroup' ? (
                    <Select
                      size="small"
                      value={value}
                      sx={{
                        '.MuiOutlinedInput-notchedOutline': {
                          border: 'none !important'
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          boxShadow: 'none !important'
                        }
                      }}
                      onChange={(e) => handleItemsStatusUpdate(data.id, e, 'status')}
                      disabled={!itemsUpdate}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            src={
                              selected === 'enabled' ? EnabledIcon : selected === 'disabled' ? DisabledIcon : DeletedIcon // Assuming the DeletedIcon represents "Deleted"
                            }
                            alt={selected}
                            width={22}
                            height={22}
                            style={{ marginRight: '8px' }}
                          />
                        </Box>
                      )}
                    >
                      <MenuItem value="enabled">
                        <img src={EnabledIcon} alt="EnabledIcon" width={22} height={22} style={{ marginRight: '8px' }} />
                        Enabled
                      </MenuItem>
                      <MenuItem value="disabled">
                        <img src={DisabledIcon} alt="EnabledIcon" width={22} height={22} style={{ marginRight: '8px' }} />
                        Disabled
                      </MenuItem>
                      <MenuItem value="deleted">
                        <img src={DeletedIcon} alt="Scheduled" width={22} height={22} style={{ marginRight: '8px' }} />
                        Deleted
                      </MenuItem>
                    </Select>
                  ) : (
                    <Typography>{value}</Typography>
                  )}
                </Box>
              );
            }
          }
        }
      : {
          name: 'status',
          label: 'Status ',
          default: true,
          options: {
            filter: true,
            sort: true,
            viewColumns: false,
            sticky: true,
            leftTotal: 380,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
              const customCss = { zIndex: 101, left: '380px', width: '90px' };
              return handleCustomTableHead({
                columnMeta,
                handleToggleColumn,
                sortOrder,
                columnName: 'status',
                columnLabel_1: 'Status',
                customCss
              });
            },
            customBodyRender: (value, rowData) => {
              const data = itemsDetails?.results ? itemsDetails?.results[rowData?.rowIndex] : [];
              return (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {statusLoader == 'status' && inlineLoader?.Status && inlineLoader?.Status === data?.id ? (
                    <Loader />
                  ) : valueGroup === 'adgroup' ? (
                    <Select
                      sx={{
                        '.MuiOutlinedInput-notchedOutline': {
                          border: 'none !important'
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          boxShadow: 'none !important'
                        }
                      }}
                      size="small"
                      value={value}
                      onChange={(e) => handleItemsStatusUpdate(data.id, e, 'status')}
                      disabled={!itemsUpdate}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            src={
                              selected === 'enabled' ? EnabledIcon : selected === 'disabled' ? DisabledIcon : DeletedIcon // Assuming the DeletedIcon represents "Deleted"
                            }
                            alt={selected}
                            width={22}
                            height={22}
                            style={{ marginRight: '8px' }}
                          />
                        </Box>
                      )}
                    >
                      <MenuItem value="enabled">
                        <img src={EnabledIcon} alt="EnabledIcon" width={22} height={22} style={{ marginRight: '8px' }} />
                        Enabled
                      </MenuItem>
                      <MenuItem value="disabled">
                        <img src={DisabledIcon} alt="EnabledIcon" width={22} height={22} style={{ marginRight: '8px' }} />
                        Disabled
                      </MenuItem>
                      <MenuItem value="deleted">
                        <img src={DeletedIcon} alt="Scheduled" width={22} height={22} style={{ marginRight: '8px' }} />
                        Deleted
                      </MenuItem>
                    </Select>
                  ) : (
                    <Typography>{value}</Typography>
                  )}
                </Box>
              );
            },
            ...(showSelectAdviser && {
              setCellProps: () => ({
                style: {
                  whiteSpace: 'nowrap',
                  position: 'sticky',
                  left: 380,
                  minWidth: '90px',
                  background: 'white',
                  zIndex: 100
                }
              }),
              setCellHeaderProps: () => ({
                style: {
                  whiteSpace: 'nowrap',
                  position: 'sticky',
                  left: 380,
                  minWidth: '90px',
                  background: 'white',
                  zIndex: 101
                }
              })
            })
          }
        },
    {
      name: 'suggested_bid',
      label: 'Suggested Bid',
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'suggested_bid',
            columnLabel_1: 'Suggested Bid'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = itemsDetails?.results?.[rowData?.rowIndex];
          return (
            <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
              {data?.targeting_type == 'auto' && value !== null ? value : '-'}
            </Typography>
          );
        }
      }
    },
    {
      name: 'bid_amount',
      label: 'Bid',
      options: {
        filter: true,
        sort: true,
        display: valueGroup === 'adgroup' ? true : false,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'bid_amount',
            columnLabel_1: 'Bid',
            isParentLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = itemsDetails?.results ? itemsDetails?.results[rowData?.rowIndex] : [];
          return (
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'flex-end'}
              onMouseEnter={() => setHoveredRow({ campaign: data?.id })}
              onMouseLeave={() => setHoveredRow(null)}
            >
              {edit.campaign == data?.id && itemsUpdate ? (
                <TextField
                  sx={{ minWidth: '100px' }}
                  defaultValue={value}
                  // disabled={loading}
                  autoFocus
                  // InputProps={{
                  //   endAdornment: <InputAdornment position="end"></InputAdornment>
                  // }}
                  disabled={loading}
                  // autoFocus
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{loading ? <Loader /> : ''}</InputAdornment>
                  }}
                  onBlur={(e) => {
                    if (e.target.value != value) {
                      handleSaveButtonBid(e.target.value, data?.id);
                    } else {
                      setEdit({ campaign: null, total_budget: null, daily_budget: null, status: null, end_date: null });
                    }
                  }}
                />
              ) : (
                <>
                  <Box
                    onClick={
                      itemsUpdate &&
                      valueGroup === 'adgroup' &&
                      GroupByValue &&
                      data.targeting_type == 'auto' &&
                      hoveredRow?.campaign === data?.id
                        ? () => {
                            setEdit({
                              ...edit,
                              campaign: data?.id,
                              total_budget: null,
                              daily_budget: null,
                              status: null,
                              end_date: null
                            });
                          }
                        : undefined
                    }
                    className=""
                  >
                    {GroupByValue && data?.targeting_type == 'auto' ? (
                      data?.campaign_type === 'sponsoredProducts' ? (
                        <Box lineHeight="7px" textAlign="right">
                          <BidPopover data={data} value={value} hoveredRow={hoveredRow} />
                        </Box>
                      ) : (
                        <Typography className="cursor-pointer" color="primary">
                          {value}
                        </Typography>
                      )
                    ) : (
                      '-'
                    )}
                  </Box>
                </>
              )}
            </Box>
          );
        }
      }
    },
    {
      name: 'campaign_name',
      label: 'Campaign Name',
      options: {
        filter: true,
        display: valueGroup === 'adgroup' || valueGroup === 'campaign' ? true : false,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'campaign_name',
            columnLabel_1: 'Campaign Name'
          });
        },
        customBodyRender: (value, tableMeta) => {
          const data = itemsDetails?.results ? itemsDetails?.results[tableMeta.rowIndex] : [];
          return (
            <Typography
              {...(itemsUpdate && {
                onMouseEnter: () => setHoveredRow({ campaignId: data?.id }),
                onMouseLeave: () => setHoveredRow(null)
              })}
              sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', justifyContent: 'space-between' }}
            >
              <Box className="link-dashna-ui">
                {value?.length > 30 ? (
                  <>
                    <Tooltip
                      placement="top"
                      sx={{ fontSize: '10px' }}
                      title={
                        <Typography variant="body1" sx={{ fontSize: 16 }}>
                          {value}
                        </Typography>
                      }
                      arrow
                    >
                      <Link
                        href={`/campaign/${commaStringToNumber(data?.campaign_id)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={'ffInter'}
                        underline="none"
                        sx={{ cursor: 'pointer' }}
                      >
                        {value.substring(0, 30)}...
                      </Link>
                    </Tooltip>
                  </>
                ) : (
                  <Link
                    href={`/campaign/${commaStringToNumber(data?.campaign_id)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={'ffInter'}
                    underline="none"
                    sx={{ cursor: 'pointer' }}
                  >
                    {value}
                  </Link>
                )}
              </Box>
              <Box>
                <IconButton
                  onClick={() => {
                    handleClose();
                    if (targetingType == 'sba') {
                      setCampaignBrandModal({ ...campaignBrandModal, open: true, id: data?.campaign });
                    }
                    if (targetingType == 'video') {
                      setCampaignVideoModal({ ...campaignVideoModal, open: true, id: data?.campaign });
                    } else {
                      setCampaignModal({ ...campaignModal, open: true, id: data?.campaign });
                    }
                  }}
                >
                  <Tooltip sx={{ fontSize: '10px' }} title={'Edit'} arrow>
                    {' '}
                    <img src={editIcon} alt="Icon" />
                  </Tooltip>
                </IconButton>
                <IconButton onClick={(e) => handleClick(e, data?.campaign, data)}>
                  <Tooltip sx={{ fontSize: '10px' }} title={'Action'} arrow>
                    {' '}
                    <MoreVertOutlinedIcon />
                  </Tooltip>
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button'
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setItemModal({ ...itemModal, open: true });
                    }}
                  >
                    <AddOutlinedIcon sx={{ mr: 0.5 }} /> Add Item
                  </MenuItem>
                  {isManual && (
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setKeywordModal({ ...keywordModal, open: true });
                      }}
                    >
                      <AddOutlinedIcon sx={{ mr: 0.5 }} /> Add Keyword
                    </MenuItem>
                  )}
                  {targetingType === 'sponsoredProducts' && (
                    <MenuItem onClick={handleAddAdGroup}>
                      <AddOutlinedIcon sx={{ mr: 0.5 }} /> Add Adgroup
                    </MenuItem>
                  )}
                </Menu>
              </Box>
            </Typography>
          );
        }
      }
    },
    {
      name: 'adgroup_name',
      label: 'Ad Group Name',
      options: {
        filter: true,
        sort: true,
        display: valueGroup === 'adgroup' ? true : false,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'adgroup_name',
            columnLabel_1: 'Ad Group Name'
          });
        }
      }
    },
    {
      name: 'profile_name',
      label: 'Profile',
      options: {
        filter: true,
        sort: true,
        width: 550,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'profile_name',
            columnLabel_1: 'Profile Name'
          });
        }
      }
    },

    {
      name: 'brand',
      label: 'Brand',
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'brand',
            columnLabel_1: 'Brand'
          });
        }
      }
    },

    {
      name: 'campaign_type',
      label: 'Campaign Type',
      options: {
        filter: true,
        sort: true,
        display: valueGroup === 'aditem' ? false : true,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'campaign_type',
            columnLabel_1: 'Campaign',
            columnLabel_2: 'Type'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = itemsDetails?.results?.[rowData?.rowIndex];
          var type = '';
          if (value == 'sponsoredProducts') {
            type =
              data?.targeting_type == CAMPAIGN.TARGETING_TYPE.MANUAL
                ? CAMPAIGN.CAMPAIGN_TYPE.SP_MANUAL
                : CAMPAIGN.CAMPAIGN_TYPE.SP_AUTO;
          } else if (value == 'sba') {
            type = CAMPAIGN.CAMPAIGN_TYPE.SB;
          } else if (value == 'video') {
            type = CAMPAIGN.CAMPAIGN_TYPE.SV;
          }

          return (
            <Box>
              <Chip sx={{ height: '20px' }} className={'ffInter fs12'} label={type} color="primary" variant="outlined" />
            </Box>
          );
        }
      }
    },
    {
      name: 'impressions',
      label: 'Impressions',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'impressions',
            columnLabel_1: 'Impressions'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = itemsDetails?.results ? itemsDetails?.results[rowData?.rowIndex] : [];

          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value?.toLocaleString('en-US')}
              <CompareValueWithColor
                value={data?.comparison_impressions}
                Percentage={data?.percentage_impressions}
                UpDown={data?.is_higher_impressions}
                keys="impressions"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'clicks',
      label: 'Clicks',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'clicks',
            columnLabel_1: 'Clicks'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = itemsDetails?.results ? itemsDetails?.results[rowData?.rowIndex] : [];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value}
              <CompareValueWithColor
                value={data?.comparison_clicks}
                Percentage={data?.percentage_clicks}
                UpDown={data?.is_higher_clicks}
                keys="clicks"
                currency={currency}
              />
            </>
          );
        }
      }
    },

    {
      name: 'ctr',
      label: 'CTR',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'ctr',
            columnLabel_1: 'CTR'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = itemsDetails?.results ? itemsDetails?.results[rowData?.rowIndex] : [];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null%' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_ctr}
                Percentage={data?.percentage_ctr}
                UpDown={data?.is_higher_ctr}
                keys="ctr"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'ad_spend',
      label: 'Spend',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'ad_spend',
            columnLabel_1: 'Spend'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = itemsDetails?.results ? itemsDetails?.results[rowData?.rowIndex] : [];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value}
              <CompareValueWithColor
                value={data?.comparison_ad_spend}
                Percentage={data?.percentage_ad_spend}
                UpDown={data?.is_higher_ad_spend}
                keys="ad_spend"
                currency={currency}
              />
            </>
          );
        }
      }
    },

    {
      name: 'attributed_sales',
      label: 'Ad Sales',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'attributed_sales',
            columnLabel_1: 'Ad Sales'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = itemsDetails?.results ? itemsDetails?.results[rowData?.rowIndex] : [];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value}
              <CompareValueWithColor
                value={data?.comparison_attributed_sales}
                Percentage={data?.percentage_attributed_sales}
                UpDown={data?.is_higher_attributed_sales}
                keys="attributed_sales"
                currency={currency}
              />
            </>
          );
        }
      }
    },

    {
      name: 'roas',
      label: 'RoAS',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'roas',
            columnLabel_1: 'RoAS'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = itemsDetails?.results ? itemsDetails?.results[rowData?.rowIndex] : [];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null%' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_roas}
                Percentage={data?.percentage_roas}
                UpDown={data?.is_higher_roas}
                keys="roas"
                currency={currency}
              />
            </>
          );
        }
      }
    },

    {
      name: 'acos',
      label: 'ACoS',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'acos',
            columnLabel_1: 'ACoS'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = itemsDetails?.results ? itemsDetails?.results[rowData?.rowIndex] : [];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null%' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_acos}
                Percentage={data?.percentage_acos}
                UpDown={data?.is_higher_acos}
                keys="acos"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'total_sales',
      label: 'Total Sales (Market Place)',
      options: {
        filter: true,
        sort: true,
        // customBodyRender: (value) => {
        //   return <>{value.charAt(0).toUpperCase() + value.slice(1)}</>;
        // },
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'total_sales',
            columnLabel_1: 'Total Sales'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = itemsDetails?.results ? itemsDetails?.results[rowData?.rowIndex] : [];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value?.length > 0 ? value : '-'}
              <CompareValueWithColor
                value={data?.comparison_total_sales}
                Percentage={data?.percentage_total_sales}
                UpDown={data?.is_higher_total_sales}
                keys="total_sales"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'troas',
      label: 'TRoAS',
      options: {
        filter: true,
        sort: true,
        // customBodyRender: (value) => {
        //   return <>{value.charAt(0).toUpperCase() + value.slice(1)}</>;
        // },
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'troas',
            columnLabel_1: 'TRoAS'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = itemsDetails?.results ? itemsDetails?.results[rowData?.rowIndex] : [];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value?.length > 0 ? value : '-'}
              <CompareValueWithColor
                value={data?.comparison_troas}
                Percentage={data?.percentage_troas}
                UpDown={data?.is_higher_troas}
                keys="troas"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'tacos',
      label: 'TACoS',
      options: {
        filter: true,
        sort: true,
        // customBodyRender: (value) => {
        //   return <>{value.charAt(0).toUpperCase() + value.slice(1)}</>;
        // },
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'tacos',
            columnLabel_1: 'TACoS'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = itemsDetails?.results ? itemsDetails?.results[rowData?.rowIndex] : [];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value?.length > 0 ? value : '-'}
              <CompareValueWithColor
                value={data?.comparison_tacos}
                Percentage={data?.percentage_tacos}
                UpDown={data?.is_higher_tacos}
                keys="tacos"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'average_cpc',
      label: 'Average CPC',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'average_cpc',
            columnLabel_1: 'Average',
            columnLabel_2: 'CPC',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = itemsDetails?.results ? itemsDetails?.results[rowData?.rowIndex] : [];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null%' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_average_cpc}
                Percentage={data?.percentage_average_cpc}
                UpDown={data?.is_higher_average_cpc}
                keys="average_cpc"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'cpa_units',
      label: 'CPA (Units)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'cpa_units',
            columnLabel_1: 'CPA'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = itemsDetails?.results ? itemsDetails?.results[rowData?.rowIndex] : [];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              <Typography>{value?.length > 0 ? value : '-'}</Typography>
              <CompareValueWithColor
                value={data?.comparison_cpa_units}
                Percentage={data?.percentage_cpa_units}
                UpDown={data?.is_higher_cpa_units}
                keys="cpa_units"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'units_sold',
      label: 'Units Sold',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'units_sold',
            columnLabel_1: 'Unit',
            columnLabel_2: 'Sold',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = itemsDetails?.results ? itemsDetails?.results[rowData?.rowIndex] : [];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null%' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_units_sold}
                Percentage={data?.percentage_units_sold}
                UpDown={data?.is_higher_units_sold}
                keys="units_sold"
                currency={currency}
              />
            </>
          );
        }
        // customBodyRender: (value) => { return "$" + (value ? value : "0")}
      }
    },
    {
      name: 'cvr_orders',
      label: 'CVR (Orders)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'cvr_orders',
            columnLabel_1: 'CVR',
            columnLabel_2: '(Orders)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = itemsDetails?.results ? itemsDetails?.results[rowData?.rowIndex] : [];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value && value !== '$null' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_cvr_orders}
                Percentage={data?.percentage_cvr_orders}
                UpDown={data?.is_higher_cvr_orders}
                keys="cvr_orders"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'cvr_units',
      label: 'CVR (Units)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'cvr_units',
            columnLabel_1: 'CVR',
            columnLabel_2: '(Units)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = itemsDetails?.results ? itemsDetails?.results[rowData?.rowIndex] : [];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value && value !== '$null' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_cvr_units}
                Percentage={data?.percentage_cvr_units}
                UpDown={data?.is_higher_cvr_units}
                keys="cvr_units"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'attributed_orders',
      label: 'Orders',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'attributed_orders',
            columnLabel_1: 'Orders'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = itemsDetails?.results ? itemsDetails?.results[rowData?.rowIndex] : [];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              <Typography>{value?.length > 0 ? value : '-'}</Typography>
              <CompareValueWithColor
                value={data?.comparison_attributed_orders}
                Percentage={data?.percentage_attributed_orders}
                UpDown={data?.is_higher_attributed_orders}
                keys="attributed_orders"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'cpa_orders',
      label: 'CPA (Orders)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'cpa_orders',
            columnLabel_1: 'CPA',
            columnLabel_2: '(Orders)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = itemsDetails?.results ? itemsDetails?.results[rowData?.rowIndex] : [];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              <Typography>{value?.length > 0 ? value : '-'}</Typography>
              <CompareValueWithColor
                value={data?.comparison_cpa_orders}
                Percentage={data?.percentage_cpa_orders}
                UpDown={data?.is_higher_cpa_orders}
                keys="cpa_orders"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'inventory_count',
      label: 'Inventory',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'inventory_count',
            columnLabel_1: 'Inventory',
            columnLabel_2: DateStateHealth?.SimpleDate,
            isLabelAlignRight: true
          });
        },
        // customHeadRender: ({ index }) => {
        //   return (
        //     <TableCell key={index} className="custom-sticky-header">
        //       Inventory <br /> {DateStateHealth?.SimpleDate}
        //     </TableCell>
        //   );
        // },
        customBodyRender: (value) => {
          return <Typography sx={{ display: 'flex', justifyContent: 'center' }}>{value == null ? '-' : value}</Typography>;
        }
      }
    },

    {
      name: 'publish_status',
      label: 'Published Status',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'publish_status',
            columnLabel_1: 'Published Status',
            columnLabel_2: DateStateHealth?.SimpleDate,
            isLabelAlignRight: true
          });
        },
        // customHeadRender: ({ index }) => {
        //   return (
        //     <TableCell key={index} className="custom-sticky-header">
        //       Published Status <br /> {DateStateHealth?.SimpleDate}{' '}
        //     </TableCell>
        //   );
        // },
        customBodyRender: (value) => {
          return <Typography sx={{ display: 'flex', justifyContent: 'center' }}>{value == null ? '-' : value}</Typography>;
        }
      }
    },

    {
      name: 'listing_quality_score',
      label: 'Quality Score',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'listing_quality_score',
            columnLabel_1: 'Quality Score',
            columnLabel_2: DateStateHealth?.SimpleDate,
            isLabelAlignRight: true
          });
        },
        // customHeadRender: ({ index }) => {
        //   return (
        //     <TableCell key={index} className="custom-sticky-header">
        //       Quality Score <br /> {DateStateHealth?.SimpleDate}{' '}
        //     </TableCell>
        //   );
        // },
        customBodyRender: (value) => {
          return <Typography sx={{ display: 'flex', justifyContent: 'center' }}>{value == null ? '-' : value}</Typography>;
        }
      }
    },

    {
      name: 'content_score',
      label: 'Content Score',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'content_score',
            columnLabel_1: 'Content Score',
            columnLabel_2: DateStateHealth?.SimpleDate,
            isLabelAlignRight: true
          });
        },
        // customHeadRender: ({ index }) => {
        //   return (
        //     <TableCell key={index} className="custom-sticky-header">
        //       Content Score <br /> {DateStateHealth?.SimpleDate}{' '}
        //     </TableCell>
        //   );
        // },
        customBodyRender: (value) => {
          return <Typography sx={{ display: 'flex', justifyContent: 'center' }}>{value == null ? '-' : value}</Typography>;
        }
      }
    },

    {
      name: 'rating_score',
      label: 'Rating Score',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'rating_score',
            columnLabel_1: 'Rating Score',
            columnLabel_2: DateStateHealth?.SimpleDate,
            isLabelAlignRight: true
          });
        },
        // customHeadRender: ({ index }) => {
        //   return (
        //     <TableCell key={index} className="custom-sticky-header">
        //       Rating Score <br /> {DateStateHealth?.SimpleDate}{' '}
        //     </TableCell>
        //   );
        // },
        customBodyRender: (value) => {
          return <Typography sx={{ display: 'flex', justifyContent: 'center' }}>{value == null ? '-' : value}</Typography>;
        }
      }
    },

    {
      name: 'offer_score',
      label: 'Offer Score',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'offer_score',
            columnLabel_1: 'Offer Score',
            columnLabel_2: DateStateHealth?.SimpleDate,
            isLabelAlignRight: true
          });
        },
        // customHeadRender: ({ index }) => {
        //   return (
        //     <TableCell key={index} className="custom-sticky-header">
        //       Offer Score <br /> {DateStateHealth?.SimpleDate}{' '}
        //     </TableCell>
        //   );
        // },
        customBodyRender: (value) => {
          return <Typography sx={{ display: 'flex', justifyContent: 'center' }}>{value == null ? '-' : value}</Typography>;
        }
      }
    },

    {
      name: 'page_views',
      label: 'Page Views',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'page_views',
            columnLabel_1: 'Page Views',
            columnLabel_2: DateStateHealth?.SimpleDate,
            isLabelAlignRight: true
          });
        },
        // customHeadRender: ({ index }) => {
        //   return (
        //     <TableCell key={index} className="custom-sticky-header">
        //       Page Views <br /> {DateStateHealth?.SimpleDate}{' '}
        //     </TableCell>
        //   );
        // },
        customBodyRender: (value) => {
          return <Typography sx={{ display: 'flex', justifyContent: 'center' }}>{value == null ? '-' : value}</Typography>;
        }
      }
    },

    {
      name: 'page_view_last_week',
      label: 'Page Views Last Week',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'page_view_last_week',
            columnLabel_1: 'Page Views',
            columnLabel_2: '(L7D)',
            isLabelAlignRight: true
          });
        },
        // customHeadRender: ({ index }) => {
        //   return (
        //     <TableCell key={index} className="custom-sticky-header">
        //       Page Views <br /> {DateStateHealth?.SimpleDate} -{DateStateHealth?.WeekMinus}{' '}
        //     </TableCell>
        //   );
        // },
        customBodyRender: (value) => {
          return <Typography sx={{ display: 'flex', justifyContent: 'center' }}>{value == null ? '-' : value}</Typography>;
        }
      }
    },

    {
      name: 'buy_box_pct',
      label: 'Buybox Pct',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'buy_box_pct',
            columnLabel_1: 'Buybox Pct',
            columnLabel_2: DateStateHealth?.SimpleDate,
            isLabelAlignRight: true
          });
        },
        // customHeadRender: ({ index }) => {
        //   return (
        //     <TableCell key={index} className="custom-sticky-header">
        //       Buybox Pct <br /> {DateStateHealth?.SimpleDate}{' '}
        //     </TableCell>
        //   );
        // },
        customBodyRender: (value) => {
          return (
            <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
              {value == null || value == 'null%' ? '-' : value}
            </Typography>
          );
        }
      }
    },
    {
      name: 'buy_box_pct_last_week',
      label: 'Buybox Pct Last Week',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'buy_box_pct_last_week',
            columnLabel_1: 'Buybox Pct',
            columnLabel_2: '(L7D)',
            isLabelAlignRight: true
          });
        },
        // customHeadRender: ({ index }) => {
        //   return (
        //     <TableCell key={index} className="custom-sticky-header">
        //       Buybox Pct <br /> {DateStateHealth?.SimpleDate}-{DateStateHealth?.WeekMinus}
        //     </TableCell>
        //   );
        // },
        customBodyRender: (value) => {
          return (
            <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
              {value == null || value == 'null%' ? '-' : value}
            </Typography>
          );
        }
      }
    },
    {
      name: 'availability_pct',
      label: 'Availability Pct',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'availability_pct',
            columnLabel_1: 'Availability Pct',
            columnLabel_2: DateStateHealth?.SimpleDate,
            isLabelAlignRight: true
          });
        },
        // customHeadRender: ({ index }) => {
        //   return (
        //     <TableCell key={index} className="custom-sticky-header">
        //       Availability Pct <br /> {DateStateHealth?.SimpleDate}{' '}
        //     </TableCell>
        //   );
        // },
        customBodyRender: (value) => {
          return (
            <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
              {value == null || value == 'null%' ? '-' : value}
            </Typography>
          );
        }
      }
    },
    {
      name: 'availability_pct_last_week',
      label: 'Availability Pct Last Week',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'availability_pct_last_week',
            columnLabel_1: 'Availability Pct',
            columnLabel_2: '(L7D)',
            isLabelAlignRight: true
          });
        },
        // customHeadRender: ({ index }) => {
        //   return (
        //     <TableCell key={index} className="custom-sticky-header">
        //       Availability Pct <br /> {DateStateHealth?.SimpleDate}-{DateStateHealth?.WeekMinus}
        //     </TableCell>
        //   );
        // },
        customBodyRender: (value) => {
          return (
            <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
              {value == null || value == 'null%' ? '-' : value}
            </Typography>
          );
        }
      }
    },

    {
      name: 'average_rating',
      label: 'Average Rating',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'average_rating',
            columnLabel_1: 'Average',
            columnLabel_2: 'Rating',
            isLabelAlignRight: true
          });
        }
      }
    },
    {
      name: 'page_view_based_availability_rate',
      label: 'Availability Rate (Pg Views)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'page_view_based_availability_rate',
            columnLabel_1: 'Availability Rate',
            columnLabel_2: '(Page Views)',
            isLabelAlignRight: true
          });
        }
      }
    },
    {
      name: 'page_view_based_buy_box_win_rate',
      label: 'Buy Box Win Rate (Pg Views)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'page_view_based_buy_box_win_rate',
            columnLabel_1: 'Buy Box Win Rate',
            columnLabel_2: '(Page Views)',
            isLabelAlignRight: true
          });
        }
      },
      setCellProps: () => ({
        style: {
          whiteSpace: 'nowrap',
          position: 'sticky',
          // left: 40,
          minWidth: '380px',
          background: 'white'
          // zIndex: 100
        }
      }),
      setCellHeaderProps: () => ({
        style: {
          width: '100px'
        }
      })
    },
    {
      name: 'instore_advertised_sales',
      label: 'In-Store Advertised Sales (Beta)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'instore_advertised_sales',
            columnLabel_1: 'In-Store Advertised',
            columnLabel_2: 'Sales (β)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = itemsDetails?.results ? itemsDetails?.results[rowData?.rowIndex] : [];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value && value !== '$null' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_instore_advertised_sales}
                Percentage={data?.percentage_instore_advertised_sales}
                UpDown={data?.is_higher_instore_advertised_sales}
                keys="instore_advertised_sales"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'instore_attributed_sales',
      label: 'In-Store Attributed Sales (Beta)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'instore_attributed_sales',
            columnLabel_1: 'In-Store Attributed',
            columnLabel_2: 'Sales (β)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = itemsDetails?.results ? itemsDetails?.results[rowData?.rowIndex] : [];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_instore_attributed_sales}
                Percentage={data?.percentage_instore_attributed_sales}
                UpDown={data?.is_higher_instore_attributed_sales}
                keys="instore_attributed_sales"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'instore_orders',
      label: 'In-Store Orders (Beta)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'instore_orders',
            columnLabel_1: 'In-Store',
            columnLabel_2: 'Orders (β)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = itemsDetails?.results ? itemsDetails?.results[rowData?.rowIndex] : [];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_instore_orders}
                Percentage={data?.percentage_instore_orders}
                UpDown={data?.is_higher_instore_orders}
                keys="instore_orders"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'instore_other_sales',
      label: 'In-Store Other Sales (Beta)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'instore_other_sales',
            columnLabel_1: 'In-Store Other',
            columnLabel_2: 'Sales (β)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = itemsDetails?.results ? itemsDetails?.results[rowData?.rowIndex] : [];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value && value !== '$null' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_instore_other_sales}
                Percentage={data?.percentage_instore_other_sales}
                UpDown={data?.is_higher_instore_other_sales}
                keys="instore_other_sales"
                currency={currency}
              />
            </>
          );
        }
      }
    },

    {
      name: 'instore_units_sold',
      label: 'In-Store Units Sold (Beta)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'instore_units_sold',
            columnLabel_1: 'In-Store Units',
            columnLabel_2: 'Sold (β)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = itemsDetails?.results ? itemsDetails?.results[rowData?.rowIndex] : [];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_instore_units_sold}
                Percentage={data?.percentage_instore_units_sold}
                UpDown={data?.is_higher_instore_units_sold}
                keys="instore_units_sold"
                currency={currency}
              />
            </>
          );
        }
      }
    }
  ];

  useEffect(() => {
    if (selectedArray) {
      if (selectedArray.length >= 2) {
        SetShowDropDown(true);
      } else {
        SetShowDropDown(false);
      }
    }
  }, [selectedArray]);

  const handleDownloadCsv = () => {
    getItemsList(0, rowPerPage, true);
    // getAdvertiserItemListWithPagination(
    //   page,
    //   rowPerPage,
    //   search,
    //   '',
    //   '',
    //   commonAdvertiser,
    //   PerformanceFilterData,
    //   filterForSearch,
    //   PerformanceFilterBidRanges,
    //   filterForSearchAddDate,
    //   true
    // );
  };

  const [appliedSortTing, setAppliedSortTing] = useState();

  const initialColumnOrder = columns.map((_, index) => index);
  const [columnOrder, setColumnOrder] = useState(initialColumnOrder);

  const options = {
    filter: false,
    print: false,
    viewColumns: false,
    selectableRows: true,
    selectToolbarPlacement: 'none',
    responsive: 'standard',
    fixedHeader: true,
    fixedSelectColumn: true,
    tableBodyHeight: tableHeight,
    page: page,
    count: itemsDetails?.count || 0,
    jumpToPage: false,
    download: false,
    pagination: true,
    serverSide: true,
    rowsPerPage: rowPerPage,
    rowsPerPageOptions: PageValue,
    draggableColumns: {
      enabled: false
    },
    columnOrder: columnOrder,
    onColumnOrderChange: (newColumnOrder) => {
      if (newColumnOrder[0] !== 0 || newColumnOrder[1] !== 1) {
        setColumnOrder([...columnOrder]);
      } else {
        setColumnOrder(newColumnOrder);
      }
    },
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      const selectedIds = rowsSelected.map((selectedIndex) => itemsDetails?.results?.[selectedIndex]?.id);
      const ItemIds = rowsSelected.map((selectedIndex) => itemsDetails?.results?.[selectedIndex]?.item_id);
      const ItemIdsType = rowsSelected.map((selectedIndex) => itemsDetails?.results?.[selectedIndex]?.targeting_type);
      const setOfType = [...new Set(ItemIdsType)];
      setCampaignType(setOfType);
      setSelectedArray(selectedIds);
      SetSelectedItemsId(ItemIds);
      return null;
    },
    search: false, // Disable the search toolbar
    customToolbar: null,

    textLabels: {
      body: {
        noMatch: showSelectAdviser ? (
          tableLoading ? (
            <Loader />
          ) : (
            'Sorry, there is no matching data to display'
          )
        ) : (
          'Please Select At List One Advertiser '
        )
      }
    },
    onChangeRowsPerPage: (row) => {
      setRowPerPage(row);
      getItemsList(0, row);
    },
    onChangePage: (page) => {
      setPage(page);
      getItemsList(page);
    },
    onSearchChange: (searchText) => {
      setSearch(searchText);
      handleSearch(searchText);
    },
    onSearchClose: () => {
      setSearch('');
      getAdvertiserItemListWithPagination(0, rowPerPage, '');
    },
    onColumnSortChange: (changedColumn, direction) => {
      setPage(0);
      setSearch('');

      const payload = {
        changedColumnSort: changedColumn,
        directionSort: direction
      };
      setAppliedSortTing(payload);
      // getItemsList(0, rowPerPage);
      // need changes for sorting-----------------
      const advertiserFilter = commonAdvertiser?.length > 0 ? commonAdvertiser?.join(',') : '';
      getAdvertiserItemListWithPagination(
        0,
        rowPerPage,
        '',
        changedColumn,
        direction,
        advertiserFilter,
        shouldApplyFilter?.current ? PerformanceFilterData : {},
        shouldApplyFilter?.current ? filterForSearch : {},
        shouldApplyFilter?.current ? PerformanceFilterBidRanges : {},
        shouldApplyFilter?.current ? filterForSearchAddDate : []
      );
    },
    customTableBodyFooterRender: (opts) => {
      const optsColumns = opts?.columns;
      const reorderedColumns = columnOrder.map((index) => optsColumns[index]);
      return (
        <TableFooter>
          <TableRow className="table-footer-total" sx={{ position: 'sticky', bottom: 0, background: 'white', zIndex: 999 }}>
            <TableCell
              sx={{
                whiteSpace: 'nowrap',
                position: 'sticky',
                left: 1,
                // minWidth: '180px',
                background: 'white',
                zIndex: 100
              }}
            ></TableCell>
            {reorderedColumns?.map((col, index) => {
              if (col && col.display === 'true' && newDataTotal) {
                let currency = newDataTotal[col?.name] && newDataTotal[col?.name].indexOf('$') > -1;
                return (
                  <TableCell
                    className={`${col?.sticky ? 'column-sticky' : 'column-sticky-1'} ${
                      alignRightColumn(col?.name) && 'table-align-right'
                    }`}
                    sx={
                      col?.sticky
                        ? {
                            whiteSpace: 'nowrap',
                            position: 'sticky',
                            left: col?.leftTotal,
                            fontSize: '14px',
                            // fontSize: col?.FontSize?.length > 0 ? col?.FontSize : '',
                            minwidth: col?.name === 'status' ? '90px' : '180px',
                            background: 'white',
                            zIndex: 100,
                            color: '#364152',
                            fontWeight: 'bold'
                          }
                        : {}
                    }
                    key={index}
                  >
                    {col?.name == 'details' ? (
                      'Total'
                    ) : (
                      <>
                        <span style={{ color: '#364152', fontWeight: 'bold' }}>{newDataTotal[col?.name] || ''}</span>
                        <CompareValueWithColor
                          value={newDataTotal[`comparison_${col?.name}`] && newDataTotal[`comparison_${col?.name}`]}
                          Percentage={
                            newDataTotal[`percentage_${col?.name}`] && parseFloat(newDataTotal[`percentage_${col?.name}`])
                          }
                          UpDown={
                            typeof newDataTotal[`is_higher_${col?.name}`] != 'undefined' &&
                            newDataTotal[`is_higher_${col?.name}`] == 'true'
                              ? true
                              : false
                          }
                          keys={col?.name}
                          isTotalRow={true}
                          currency={currency}
                        />
                      </>
                    )}
                  </TableCell>
                );
              }
            })}
          </TableRow>
        </TableFooter>
      );
    }
  };

  const handleSearch = useCallback(
    debounce((data) => {
      setPage(0);
      getAdvertiserItemListWithPagination(0, rowPerPage, data);
    }, 500),
    []
  );
  // adgroup, campaign, aditem, keyword
  const group_by = [
    { id: 1, name: 'Campaign', value: 'campaign' },
    { id: 2, name: 'Ad Group', value: 'adgroup' },
    { id: 3, name: 'Items', value: 'aditem' }
  ];

  const [ErrorShow, setErrorShow] = useState([]);
  const [finalErrorShow, setFinalShow] = useState(false);

  const handleInputChange = (performanceId, valueType, value, showError) => {
    setErrorShow((ErrorShow) => ({
      ...ErrorShow,
      [performanceId]: {
        ...ErrorShow[performanceId],
        error: showError
      }
    }));

    let a = {
      ...ErrorShow,
      [performanceId]: {
        ...ErrorShow[performanceId],
        error: showError
      }
    };
    let output = Object.values(a).map((item) => {
      if (typeof item === 'object' && item !== null && 'error' in item) {
        return item.error;
      } else {
        return item;
      }
    });
    let result = [...new Set(output)];

    let finalOutPut = result.length == 1 && result[0] == false ? false : true;
    setFinalShow(finalOutPut);

    setPerformanceRanges((prevRanges) => ({
      ...prevRanges,
      [performanceId]: {
        ...prevRanges[performanceId],
        [valueType]: value
      }
    }));
  };

  const handleBidInputChange = (val, valueType, value) => {
    setBidRanges((prevRanges) => ({
      ...prevRanges,
      [val]: {
        ...prevRanges[val],
        [valueType]: value
      }
    }));
  };

  const resetRelevantState = () => {
    setError({}); //Empty error state
    setBidAmount('');
    setSelectedOption('+');
    setBidAmountCurrentDollar('');
    setTextField1Value('');
    setTextField2Value('');
    setCheckbox1Checked(false);
    setCheckbox2Checked(false);
    setBidType('label');
  };

  const handleSetBidOpen = (value) => {
    if (value == 'setBid') {
      resetRelevantState();
      setIsMultipleBidConfirmationOpen(true);
    }
  };

  const handleConfirm = () => {
    const allError = {};
    if (bidType === 'label' && (!bidAmount || bidAmount <= 0)) {
      allError.bidAmount = true;
    }
    // if (bidType === 'current' && (!bidAmountCurrentDollar || bidAmountCurrentDollar <= 0)) {
    //   allError.bidAmountCurrentDollar = true;
    // }
    setError(allError);

    if (!Object.keys(allError).length) {
      if ((bidType == 'label' && bidAmount.length !== 0) || (bidType == 'current' && bidAmountCurrent.length !== 0)) {
        if (bidType === 'label') {
          const payload = {
            payload1: {
              aditem_id: selectedArray,
              bid: parseFloat(bidAmount)
            },
            defaultData: {
              page,
              rowPerPage,
              search,
              changedColumn: appliedSortTing?.changedColumnSort,
              direction: appliedSortTing?.directionSort,
              commonAdvertiserList: commonAdvertiser,
              PerformanceFilter: PerformanceFilterData,
              tableFilter: filterForSearch,
              groupBy,
              commonDate: commonDateRange,
              isComparingDates
            }
          };
          setLoadingStatus(true);
          const updatedKeywordDetails = updateKeywordDetails(itemsDetails, selectedArray, [
            { bid_amount: parseFloat(bidAmount) }
          ]);
          dispatch(updateMultipleBidItem(payload)).then((res) => {
            if (res.payload.status == 200) {
              const { data } = res.payload;
              const { totalcount: totalCount, successcount: successCount, error_message: statusChangeFailedId } = data.data;
              const finalCampaignDetails =
                totalCount !== successCount
                  ? resetFailedKeywordAttribute(updatedKeywordDetails, itemsDetails, statusChangeFailedId, 'bid_amount')
                  : updatedKeywordDetails;
              dispatch(setItemsDetails(finalCampaignDetails));
              SetShowDropDown(false);
              setLoadingStatus(false);
              // setReloading(!true);
              setSuccessFailModal(true);
              setSuccessFailData(res?.payload?.data?.data);
              setIsMultipleBidConfirmationOpen(false);
            }
          });
        } else {
          const payload = {
            payload1: {
              aditem_id: selectedArray,
              percentage: parseFloat(bidAmountCurrent),
              bid: parseFloat(bidAmountCurrentDollar) || 0,
              sign: selectedOption
            },
            defaultData: {
              page,
              rowPerPage,
              search,
              changedColumn: appliedSortTing?.changedColumnSort,
              direction: appliedSortTing?.directionSort,
              commonAdvertiserList: commonAdvertiser,
              PerformanceFilter: PerformanceFilterData,
              tableFilter: filterForSearch,
              groupBy,
              commonDate: commonDateRange,
              isComparingDates
            }
          };

          if (textField1Value && checkbox1Checked) {
            Object.assign(payload.payload1, { no_lower: Number(textField1Value) });
          }
          if (textField2Value && checkbox2Checked) {
            Object.assign(payload.payload1, { no_higher: Number(textField2Value) });
          }
          dispatch(updateMultipleItemBidPercentage(payload)).then((res) => {
            if (res.payload.status == 200) {
              setIsMultipleBidConfirmationOpen(false);
              // setReloading(!true);
              setSuccessFailModal(true);
              setSuccessFailData(res?.payload?.data?.data);
              SetShowDropDown(false);
            }
          });
        }
      }
    }
  };

  const handleBidTypeChange = (event) => {
    resetRelevantState();
    setBidType(event.target.value);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleCheckbox1Change = (event) => {
    setCheckbox1Checked(event.target.checked);
    if (event.target.checked) {
      setTextField1Value('');
    }
  };

  const handleCheckbox2Change = (event) => {
    setCheckbox2Checked(event.target.checked);
    if (event.target.checked) {
      setTextField2Value('');
    }
  };

  const menuItemsData = {
    label: 'Bulk Edit',
    items: [
      {
        label:
          valueGroup == 'adgroup' && campaignType?.length == 1 && campaignType[0] == 'auto' ? (
            <Typography>Set Bid</Typography>
          ) : (
            <CustomTooltipPlacement
              content="Set Bid"
              tooltipMessage={
                valueGroup !== 'adgroup'
                  ? 'Bids can only set when Group by AdGroup'
                  : 'Bids cannot be set on Items in Manual Campaigns'
              }
              color="gray"
            />
          ),
        value: valueGroup == 'adgroup' && campaignType?.length == 1 && campaignType[0] == 'auto' ? 'setBid' : null,
        callback: (event, item) => handleSetBidOpen(item.value)
      },
      {
        label: 'Change Status',
        items: [
          {
            label: 'Enabled',
            value: 'Enabled',
            callback: (event, item) => handleChange(item.value)
          },
          {
            label: 'Disabled',
            value: 'Disabled',
            callback: (event, item) => handleChange(item.value)
          },

          {
            label: 'Deleted',
            value: 'Deleted',
            callback: (event, item) => handleChange(item.value)
          }
        ]
      }
    ]
  };

  const HandleModalCloseStatus = () => {
    setIsMultipleStatusConfirmationOpen(false);
  };

  const hasValue = Boolean(addDate?.[0] && addDate?.[1]);

  return (
    <MainCard>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'space-between' }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <CustomFilters filterType={'aditem_filter'} defaultFilterKey={params?.id ? 'campaign' : null} />
          <FormControl sx={{ minWidth: '100px' }}>
            <InputLabel color="secondary">Attribution</InputLabel>
            <Select
              value={attribute || []}
              color="secondary"
              label="Attribution"
              fullWidth
              onChange={(e) => {
                setAttribute(e.target.value);
                setTableFilter({ ...tableFilter, attr_windows: e.target.value });
                setFilterValue({ ...filterValue, attr_windows: e.target.value });
              }}
              // onChange={(event) =>
              //   setAttribute(event.target.value)
              // }
              sx={{ width: '100px' }}
            >
              {attributeWindowList?.map((item) => {
                return (
                  <MenuItem key={item.value} value={item.value}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>{' '}
          <FormControl sx={{ minWidth: '130px' }}>
            <Autocomplete
              limitTags={2}
              id="checkboxes-tags-demo"
              name="Group by"
              className="custom-auto"
              options={group_by || []}
              value={group_by?.value}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option?.name}
              defaultValue={{ id: 2, name: 'Ad Group', value: 'adgroup' }}
              onChange={(e, value) => {
                setGroupBy(value);
              }}
              // filterSelectedOptions
              // disableCloseOnSelect
              renderOption={(props, option) => {
                return <li {...props}>{option?.name}</li>;
              }}
              renderInput={(params) => <TextField color="secondary" {...params} label="Group by" />}
            />
          </FormControl>
          {showDropdown && itemsUpdate && (
            <Box className="Custom-bulk-name">
              <NestedDropdown
                className="Custom-nested-dropdown"
                menuItemsData={menuItemsData}
                MenuProps={{ elevation: 1, className: 'Custom-menu-bulk' }}
                ButtonProps={{
                  variant: 'contained',
                  className: 'Custom-dropDown-bulk',
                  startIcon: <img src={MultiSelectIcon} alt="multiselect icon" style={{ width: '24px', height: '24px' }} />, // Adding the start icon here
                  endIcon: <img src={arrowDownIcon} alt="multiselect icon" style={{ width: '24px', height: '24px' }} /> // Remove any end icon including a dropdown arrow
                }}
                onClick={() => console.log('Clicked')}
              />
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          {/* <Button
            variant="contained"
            onClick={() => {
              handleClose();
              setItemModal({ ...itemModal, open: true });
            }}
          >
            <AddOutlinedIcon sx={{ mr: 0.5 }} /> Add Items
          </Button> */}

          <IconButton sx={{ pl: 1 }} onClick={handleColumnSelectorOpen}>
            <Tooltip arrow title="Select/Reorder Columns">
              {' '}
              <img src={viewcolumnsIcon} alt="filter icon" style={{ width: '24px', height: '24px' }} />{' '}
            </Tooltip>
          </IconButton>
          {itemsDownload && (
            <IconButton sx={{ pl: '2px' }} onClick={handleDownloadCsv}>
              <Tooltip arrow title="Download">
                <img src={DownloadIcon} alt="filter icon" style={{ width: '24px', height: '24px' }} />
              </Tooltip>
            </IconButton>
          )}
        </Box>
      </Box>
      <Grid container sx={{ pt: 3, pb: 3 }} spacing={2}>
        {filterDataList && filterDataList?.Common?.find((item) => item?.value === 'state') && (
          <Grid item xs={12} md={3}>
            <CustomAutocomplete
              multiple
              id="campaign_state"
              name="campaign_state"
              color="secondary"
              label="Campaign Status"
              options={campaigState || []}
              value={filterValue?.status || []}
              onChange={(e, value) => {
                handleAutoCompleteOnChange(value, 'status');
              }}
              optionLabelkey="name"
              optionIdkey="id"
              inputValue={isDropdownOpenCamSt ? searchText?.status : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, status: value });
                }
              }}
              onOpen={() => setIsDropdownOpenCamSt(true)}
              onClose={() => setIsDropdownOpenCamSt(false)}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('status')}
              placeholderCondition={filterValue?.status?.length > 0}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              limitTags={2}
            />
          </Grid>
        )}

        {(filterDataList && filterDataList?.Common?.find((item) => item?.value == 'campaign')) || (params && params?.id) ? (
          <Grid item xs={12} md={3}>
            <CustomAutocomplete
              multiple
              id="campaign_name"
              name="campaign_name"
              color="secondary"
              label="Campaign Name"
              options={campaignNameList || []}
              value={filterValue?.campaign__in || []}
              onChange={(e, value) => {
                handleAutoCompleteOnChange(value, 'campaign_search');
              }}
              optionLabelkey="name"
              optionIdkey="id"
              inputValue={isDropdownOpen ? searchText?.campaign_search : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, campaign_search: value });
                }
              }}
              onOpen={() => setIsDropdownOpen(true)}
              onClose={() => setIsDropdownOpen(false)}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('campaign_search')}
              placeholderCondition={filterValue?.campaign__in?.length > 0}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              limitTags={2}
            />
          </Grid>
        ) : null}
        {filterDataList && filterDataList?.Common?.find((item) => item?.value == 'adgroup_state') && (
          <Grid item xs={12} md={3}>
            <CustomAutocomplete
              multiple
              id="adgroup_state"
              name="adgroup_state"
              color="secondary"
              label="Adgroup Status"
              options={adGroup_state || []}
              value={filterValue?.adGroup_state || []}
              onChange={(e, value) => {
                handleAutoCompleteOnChange(value, 'ad_group_status');
              }}
              optionLabelkey="name"
              optionIdkey="id"
              inputValue={isDropdownOpenCamAdSt ? searchText?.ad_group_status : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, ad_group_status: value });
                }
              }}
              onOpen={() => setIsDropdownOpenCamAdSt(true)}
              onClose={() => setIsDropdownOpenCamAdSt(false)}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('ad_group_status')}
              placeholderCondition={filterValue?.adGroup_state?.length > 0}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              limitTags={2}
            />
          </Grid>
        )}

        {filterDataList && filterDataList?.Common?.find((item) => item?.value == 'campaign_type') && (
          <Grid item xs={12} md={3}>
            <CustomAutocomplete
              multiple
              id="campaign_type"
              name="campaign_type"
              color="secondary"
              label="Campaign Type"
              options={campaign_type || []}
              value={filterValue?.type || []}
              onChange={(e, value) => {
                handleAutoCompleteOnChange(value, 'campaign_type');
              }}
              optionLabelkey="name"
              optionIdkey="id"
              inputValue={isDropdownOpenCamType ? searchText?.campaign_type : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, campaign_type: value });
                }
              }}
              onOpen={() => setIsDropdownOpenCamType(true)}
              onClose={() => setIsDropdownOpenCamType(false)}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('campaign_type')}
              placeholderCondition={filterValue?.type?.length > 0}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              limitTags={2}
            />
          </Grid>
        )}
        {filterDataList && filterDataList?.Common?.find((item) => item?.value === 'adgroup_Filter') && (
          <Grid item xs={12} md={3}>
            <CustomAutocomplete
              multiple
              id="adgroup_name"
              name="adgroup_name"
              color="secondary"
              label="Ad Group"
              options={adGroupShortList || []}
              value={filterValue?.adgroup__in || []}
              onChange={(e, value) => {
                handleAutoCompleteOnChange(value, 'ad_group_search');
              }}
              optionLabelkey="name"
              optionIdkey="id"
              inputValue={isDropdownOpenAdgroup ? searchText?.ad_group_search : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, ad_group_search: value });
                }
              }}
              onOpen={() => setIsDropdownOpenAdgroup(true)}
              onClose={() => setIsDropdownOpenAdgroup(false)}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('ad_group_search')}
              placeholderCondition={filterValue?.adgroup__in?.length > 0}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              limitTags={2}
            />
          </Grid>
        )}

        {filterDataList && filterDataList?.Common?.find((item) => item?.value == 'item_status') && (
          <Grid item xs={12} md={3}>
            <CustomAutocomplete
              multiple
              id="item_status"
              name="item_status"
              color="secondary"
              label="Item Status"
              options={itemStatus || []}
              value={filterValue?.item_status || []}
              groupBy={(itemStatus) => itemStatus?.category || ''}
              onChange={(e, value) => {
                handleAutoCompleteOnChange(value, 'item_status');
              }}
              optionLabelkey="name"
              optionIdkey="id"
              inputValue={isDropdownOpenCamStItemSt ? searchText?.item_status : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, item_status: value });
                }
              }}
              onOpen={() => setIsDropdownOpenCamStItemSt(true)}
              onClose={() => setIsDropdownOpenCamStItemSt(false)}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('item_status')}
              placeholderCondition={filterValue?.item_status?.length > 0}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              limitTags={2}
            />
          </Grid>
        )}

        {filterDataList && filterDataList?.Common?.find((item) => item?.value == 'search') && (
          <Grid item xs={12} md={3}>
            <BasicPopover
              tableFilter={tableFilter}
              setTableFilter={setTableFilter}
              searchCampaign={searchCampaign}
              setSearchCampaign={setSearchCampaign}
              from={'Item Id'}
              isReset={shouldApplyFilter?.current}
            />
          </Grid>
        )}

        {filterDataList && filterDataList?.Common?.find((item) => item?.value == 'add_date') && (
          <Grid item xs={12} md={3}>
            <FormControl style={{ width: '100%', position: 'relative' }}>
              <InputLabel
                shrink={focused || hasValue}
                style={{
                  position: 'absolute',
                  top: focused || hasValue ? '2px' : '20px',
                  left: '-3px',
                  fontSize: focused || hasValue ? '14px' : '16px',
                  transition: '0.2s ease all',
                  pointerEvents: 'none',
                  background: 'white',
                  // padding: '0 4px',
                  zIndex: 6
                }}
              >
                {(focused || hasValue) && `Start Date ~ End Date`}
              </InputLabel>
              <DateRangePicker
                ranges={[]}
                value={shouldApplyFilter?.current && selectedDate ? selectedDate : null}
                onFocus={() => setFocused(true)}
                onOpen={() => setFocused(true)}
                onClose={() => setFocused(false)}
                onClean={() => {
                  setSelectedDate(null), setAddDate([]);
                }}
                onBlur={() => selectedDate && setFocused(false)}
                placeholder={`Start Date ~ End Date`}
                style={{ width: '100%', maxWidth: '100%' }}
                localeText={{ start: 'Start Date', end: 'End date' }}
                onChange={handleChangeDate}
              />
            </FormControl>
          </Grid>
        )}

        {filterDataList && filterDataList?.Common && (
          <>
            {filterDataList?.Common?.filter((item) => item?.value === 'bid')?.map((performanceItem) => (
              <Grid item xs={12} md={3} key={performanceItem.id}>
                <FilterPerformance
                  performanceItem={performanceItem}
                  isReset={shouldApplyFilter?.current}
                  handleInputChange={(performanceId, valueType, value) =>
                    handleBidInputChange(performanceId, valueType, Number(value, 10) || '')
                  }
                />
              </Grid>
            ))}
          </>
        )}
        {filterDataList && filterDataList?.Common?.find((item) => item?.value == 'brand') && (
          <Grid item xs={12} md={3}>
            <CustomAutocomplete
              multiple
              id="brandFilter"
              name="brandFilter"
              color="secondary"
              label="Brand"
              options={allBrandLists || []}
              value={filterValue?.brand || []}
              onChange={(e, value) => {
                handleAutoCompleteOnChange(value, 'brand');
              }}
              optionLabelkey="name"
              optionIdkey="id"
              inputValue={isDropdownOpenBrand ? searchText?.brand : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, brand: value });
                }
              }}
              onOpen={() => setIsDropdownOpenBrand(true)}
              onClose={() => setIsDropdownOpenBrand(false)}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('brand')}
              placeholderCondition={filterValue?.brand?.length > 0}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              limitTags={2}
            />
          </Grid>
        )}

        {filterDataList?.Performance && (
          <>
            {filterDataList?.Performance?.map((performanceItem) => (
              <Grid item xs={12} md={3} key={performanceItem.id}>
                <FilterPerformance
                  performanceItem={performanceItem}
                  isReset={shouldApplyFilter?.current}
                  handleInputChange={handleInputChange}
                />
              </Grid>
            ))}
          </>
        )}
        <Grid item xs={12} md={3}>
          <Button
            className="setMarginRight"
            variant="contained"
            color="secondary"
            disabled={finalErrorShow}
            onClick={handleSearchClick}
          >
            <SearchIcon sx={{ mr: 0.5 }} />
            Search
          </Button>
        </Grid>
        <Dialog open={isDeleteConfirmationOpen} onClose={handleCloseDeleteConfirmation}>
          <DialogTitle>Edit Items Details</DialogTitle>
          <DialogContent dividers>
            <DialogContentText>Are you sure you want to Edit this Items Details?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteConfirmation} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleDeleteReport} color="primary" variant="contained">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog fullWidth open={isMultipleBidConfirmationOpen} onClose={() => setIsMultipleBidConfirmationOpen(false)}>
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="div" sx={{ fontWeight: 700 }}>
              Set Bid
            </Typography>
            <Typography sx={{ fontWeight: 700 }} onClick={() => setIsMultipleBidConfirmationOpen(false)}>
              {' '}
              <CloseIcon />
            </Typography>
          </DialogTitle>
          <DialogContent dividers>
            <Box>
              <Typography variant="h5"> Item(Total:{selectedItemsId.length})</Typography>
              <Box
                sx={{
                  overflow: 'auto',
                  height: '80px',
                  width: '100%',
                  my: 2,
                  p: 1,

                  // boxShadow: " 0.5px 1px 1px 1px rgba(0,0,0,.05)",
                  '&::-webkit-scrollbar': {
                    width: '0.1em',
                    backgroundColor: '#C5C5C5'
                  },
                  '&::-webkit-scrollbar-track': {
                    '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#7A8183',
                    outline: '1px solid slategrey'
                  }
                }}
              >
                {selectedItemsId &&
                  selectedItemsId.map((name, index) => {
                    return <Typography key={index}>{name}</Typography>;
                  })}
              </Box>
            </Box>
            <FormControl fullWidth>
              <RadioGroup aria-label="bidType" name="bidType" value={bidType} onChange={handleBidTypeChange}>
                <FormControlLabel value="label" control={<Radio />} label="Set Bid to " />

                <FormControl sx={{ width: 'fit-content', m: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    label="Amount"
                    type="number"
                    value={bidAmount}
                    onChange={(e) => {
                      setBidAmount(e.target.value);
                      setError({ ...error, bidAmount: false });
                    }}
                    disabled={bidType !== 'label'}
                    error={error?.bidAmount}
                  />
                  <Box sx={{ color: 'red' }}>{error?.bidAmount && bidType == 'label' && 'Please add Amount'}</Box>
                </FormControl>

                <FormControlLabel value="current" control={<Radio />} label="Set Bid to Current" />

                <FormControl sx={{ width: 'fit-content', m: 1 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                    <OutlinedInput
                      id="outlined-adornment-amount-1"
                      disabled={bidType !== 'current'}
                      value={bidAmountCurrent}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value) && e.target.value.length <= 4) {
                          setBidAmountCurrent(e.target.value);
                        }
                      }}
                      error={bidAmountCurrent.length == 0}
                      endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    />
                    <Box sx={{ color: 'red' }}>{bidAmountCurrent.length == 0 && bidType == 'current' && 'Please add this'}</Box>

                    <FormControl sx={{ width: '33%' }} variant="outlined">
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={selectedOption}
                        disabled={bidType !== 'current'}
                        onChange={handleOptionChange}
                      >
                        <MenuItem value="+">+</MenuItem>
                        <MenuItem value="-">-</MenuItem>
                      </Select>
                    </FormControl>

                    <OutlinedInput
                      id="outlined-adornment-amount-1"
                      disabled={bidType !== 'current'}
                      value={bidAmountCurrentDollar}
                      onChange={(e) => {
                        setBidAmountCurrentDollar(e.target.value);
                        setError({ ...error, bidAmountCurrentDollar: false });
                      }}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      // error={bidAmountCurrentDollar.length == 0}
                    />
                    <Box sx={{ color: 'red' }}>
                      {/* {error?.bidAmountCurrentDollar && bidType == 'current' && 'Please add Amount'} */}
                    </Box>
                  </Box>

                  {bidType === 'current' && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                      <Box>
                        <FormControlLabel
                          control={<Checkbox checked={checkbox1Checked} onChange={handleCheckbox1Change} />}
                          label="No lower than"
                        />
                        <TextField
                          disabled={!checkbox1Checked}
                          label="No lower than"
                          value={textField1Value}
                          onChange={(e) => setTextField1Value(e.target.value)}
                        />
                      </Box>
                      <Box>
                        <FormControlLabel
                          control={<Checkbox checked={checkbox2Checked} onChange={handleCheckbox2Change} />}
                          label="No Higher than"
                        />
                        <TextField
                          disabled={!checkbox2Checked}
                          label="No Higher than"
                          value={textField2Value}
                          onChange={(e) => setTextField2Value(e.target.value)}
                        />
                      </Box>
                    </Box>
                  )}
                  <Box sx={{ my: 2 }}>
                    <Typography> Item bids lower than 0.2 are finally uploaded as 0.2.</Typography>
                  </Box>
                </FormControl>

                {/* Confirm Button */}
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsMultipleBidConfirmationOpen(false)} variant="outlined" color="primary">
              Cancel
            </Button>
            <Button color="primary" onClick={handleConfirm} variant="contained" disabled={loadingStatus}>
              {loadingStatus && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="10px" />}
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        {/* <Grid item xs={12} md={4} >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignContent: 'center' }}>
            <Button variant='contained' color='secondary' onClick={handleSearchClick}><SearchIcon sx={{ mr: 0.5 }} />Search</Button>
          </Box>
        </Grid> */}
      </Grid>

      {skeletonLoader && <TableSkeleton />}
      <Box
        sx={{ display: skeletonLoader ? 'none' : 'block' }}
        className={
          showSelectAdviser
            ? `${valueGroup == 'aditem' || valueGroup == 'campaign' ? 'custom-item-2-table' : 'custom-item-table'}`
            : ''
        }
      >
        <MUIDataTable
          actions={[
            {
              icon: 'save'
            }
          ]}
          data={tableLoading ? [] : showSelectAdviser ? newData : []}
          columns={getUpdatedColumns(columns, selectedColumns)}
          options={options}
          className="pnl-by-item"
        />
      </Box>
      {viewColumnsSelector && (
        <CustomColumnSelector
          open={viewColumnsSelector}
          handleClose={handleColumnSelectorClose}
          tableName="aditem_column"
          defaultSelected={selectedColumns}
          columns={getUpdatedColumns(columns, selectedColumns)}
          setColumnOrder={setColumnOrder}
          columnOrder={columnOrder}
        />
      )}
      {successFailModal && (
        <SuccessAndFail
          dialogOpen={successFailModal}
          SuccessFailData={SuccessFailData}
          handleOkButtonClick={() => setSuccessFailModal(false)}
          label="Item"
        />
      )}
      {campaignModal.open && (
        <Dialog
          open={campaignModal.open}
          keepMounted
          onClose={handledEditClose}
          maxWidth="md"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="div"> Edit SP Campaign</Typography>
              <IconButton onClick={handledEditClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <EditCampaignSetting
              campaignModal={campaignModal}
              onClose={handledEditClose}
              GetApi={() =>
                getAdvertiserItemListWithPagination(
                  page,
                  rowPerPage,
                  search,
                  appliedSortTing?.changedColumnSort,
                  appliedSortTing?.directionSort,
                  commonAdvertiser,
                  PerformanceFilterData,
                  filterForSearch,
                  PerformanceFilterBidRanges,
                  filterForSearchAddDate
                )
              }
            />
          </DialogContent>
        </Dialog>
      )}
      {itemModal.open && (
        <Dialog
          open={itemModal.open}
          keepMounted
          onClose={handleItemModalClose}
          maxWidth="md"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="div"> Add item</Typography>
              <IconButton onClick={handleItemModalClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <ProductAdGroupSetting
              GetApi={() =>
                getAdvertiserItemListWithPagination(
                  page,
                  rowPerPage,
                  search,
                  appliedSortTing?.changedColumnSort,
                  appliedSortTing?.directionSort,
                  commonAdvertiser,
                  PerformanceFilterData,
                  filterForSearch,
                  PerformanceFilterBidRanges,
                  filterForSearchAddDate
                )
              }
              itemModal={itemModal}
              onClose={handleItemModalClose}
            />
          </DialogContent>
        </Dialog>
      )}
      {keywordModal.open && (
        <Dialog
          open={keywordModal.open}
          keepMounted
          onClose={handleKeywordModalClose}
          maxWidth="md"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="div"> Add Keyword</Typography>
              <IconButton onClick={handleKeywordModalClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            {/* <EditCampaignSetting campaignModal={itemModal} onClose={handleItemModalClose} /> */}
            <TargetAdGroupSetting keywordModal={keywordModal} onClose={handleKeywordModalClose} addKeywordModel={false} />
          </DialogContent>
        </Dialog>
      )}
      {campaignBrandModal.open && (
        <Dialog
          open={campaignBrandModal.open}
          keepMounted
          onClose={handledEditBrandClose}
          maxWidth="md"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="div"> Edit SB Campaign</Typography>
              <IconButton onClick={handledEditBrandClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <EditCampaignBrandSetting campaignModal={campaignBrandModal} onClose={handledEditBrandClose} />
          </DialogContent>
        </Dialog>
      )}
      {campaignVideoModal.open && (
        <Dialog
          open={campaignVideoModal.open}
          keepMounted
          onClose={handleEditVideoClose}
          maxWidth="md"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="div"> Edit SV Campaign</Typography>
              <IconButton onClick={handleEditVideoClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers sx={{ width: '900px' }}>
            <EditCampaignVideoSetting campaignModal={campaignVideoModal} onClose={handleEditVideoClose} />
          </DialogContent>
        </Dialog>
      )}
      <Dialog fullWidth open={isMultipleStatusConfirmationOpen} onClose={HandleModalCloseStatus}>
        <DialogTitle sx={{ fontWeight: '700', fontSize: '14px', display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="div" sx={{ fontWeight: 700 }}>
            Confirm Status Change
          </Typography>
          <Typography sx={{ fontWeight: 700, cursor: 'pointer' }} onClick={HandleModalCloseStatus}>
            {' '}
            <CloseIcon />
          </Typography>
        </DialogTitle>

        <Box sx={{ display: 'flex', justifyContent: 'start' }}>
          <Typography sx={{ fontSize: '16px', color: 'black', pr: 2, display: 'flex', marginLeft: '23px' }}>
            The status of the following item(s) will be changed to{' '}
            <strong>&nbsp;{`"${StatusValueForCondition?.charAt(0).toUpperCase() + StatusValueForCondition.slice(1)}"`}</strong>
          </Typography>
        </Box>
        <DialogContent dividers>
          {/* <Label>End Date :</Label> */}

          <Box sx={{ width: '-webkit-fill-available' }}>
            <Typography variant="h5">Selected Items ({selectedItemsId?.length})</Typography>
            <Box
              sx={{
                overflow: 'auto',
                height: '80px',
                width: '100%',
                my: 2,
                p: 1,
                // boxShadow: " 0.5px 1px 1px 1px rgba(0,0,0,.05)",
                '&::-webkit-scrollbar': {
                  width: '0.1em',
                  backgroundColor: '#C5C5C5'
                },
                '&::-webkit-scrollbar-track': {
                  '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#7A8183',
                  outline: '1px solid slategrey'
                }
              }}
            >
              {selectedItemsId &&
                selectedItemsId?.map((name, index) => (
                  <Typography key={index}>
                    {index + 1}.{name}
                  </Typography>
                ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={HandleModalCloseStatus} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={handleChangeDropdownStatus} disabled={loadingStatus}>
            {loadingStatus && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="10px" />}
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </MainCard>
  );
};

export default Items;
