import { createAsyncThunk } from '@reduxjs/toolkit';
import { notificationFail, notificationSuccess } from 'store/slices/notificationSlice';
import { setUserData } from 'store/slices/authSlice';
import {
  setCommonLoader,
  setAllUserDetails,
  setCampaignDetails,
  setCampaignTotal,
  setUserDetails,
  setOnDemandData,
  setTopProductData,
  setPerformanceComparisonData,
  setSelectItemData,
  setAdgorupDetails,
  setSuggestedkeywordData,
  setExistingCampaignId,
  setExistingAdgroupId,
  setCampaignById,
  setAdgroupList,
  setExistingBrandId,
  setCampaignDetailViewData,
  setReviewLogData,
  setAdgroupDetails,
  setAdgroupDetailsTotal,
  setCampaignByIdData,
  setAdvertiserState,
  setUserManagementDetails,
  setLatestReportDateData,
  setApiUsageAnalyzeData,
  setProfileDetails,
  setVerifiedUserDetails,
  setSearchItems,
  setVideoLibraryList,
  setItemsDetails,
  setItemsDetailsTotal,
  setKeywordsDetails,
  setKeywordsDetailsTotal,
  setCampaignNameList,
  setUserSortInfo,
  setVerifyCampaignFile,
  setBulkOperations,
  setAllCampaignShortData,
  setSelectedFilterData,
  setSelectedCustomReport,
  setDashboardReport,
  setAdGroupShortList,
  setAdItemShortList,
  setKeywordShortList,
  setAllBrandLists,
  setBudgetManagerData,
  setStorAdData,
  setBudgetPerformanceData,
  setBudgetProfiles,
  setAdPerformanceData,
  setEngagementData,
  setSharedLinkData,
  setCreatorData,
  // setIsDashboardLinkVerified,
  setSelectedStoreData,
  setAllStoreData,
  setDashboardAdvertiserList,
  setSelectItemKeywordTracking,
  setSelectSuggestedKeywordTracking,
  setRankTrackerDetails,
  setRankTrackerDetailsForCSV,
  setSelectedRankTrackerDetails,
  setRuleVariables,
  setCreateRule,
  setRulesList,
  setRuleExists,
  setTableLoader,
  setSkeletonLoader,
  setBIDashboard
} from 'store/slices/userDetailsSlice';
import apiClient from 'utils/apiClient';
import {
  setCampaignReportData,
  setAdItemReportData,
  setKeywordReportData,
  setAdGroupReportData
} from 'store/slices/reportDataSlice';
import { DownloadCsvDataCommon } from 'helper/commonHelper';
import Messages from '../../utils/messages';

//Print Catched Failed Notification
const printNotificationFailMsg = async (error, dispatch) => {
  let errorMessage;
  if (error instanceof ReferenceError) {
    errorMessage = error.message; // Prints the specific error message
  } else if (error?.response?.data?.message) {
    errorMessage = error.response.data.message;
  } else {
    errorMessage = Messages.ERROR.LOGIN;
  }
  await dispatch(notificationFail(errorMessage));
};

export const getAdvertiserList = createAsyncThunk(
  'getAdvertiserList',
  async ({ page, rowPerPage, search, changedColumn, direction, is_csv, storeId }, { dispatch }) => {
    try {
      dispatch(setCommonLoader(true));
      let changedColumnSort = (direction == 'asc' ? '-' : '') + changedColumn;
      const response = await apiClient().get(
        `advertisers/list-create-advertiser/?permission_request_type=access&page_size=${rowPerPage}&page=${page + 1}` +
          (search
            ? `&search=${search}`
            : `` +
              (changedColumn ? `&ordering=${changedColumnSort}` : `` + (is_csv ? `&is_csv_download=${is_csv}` : '')) +
              (storeId ? `&store_id__in=${storeId}` : ''))
      );
      if (!is_csv) {
        const allAdvertiserList = {
          ...response?.data?.data,
          results: response?.data?.data?.results?.map((item) => {
            return { ...item, name: item?.nick_name };
          })
        };
        dispatch(setUserDetails(allAdvertiserList));
      } else {
        DownloadCsvDataCommon(response?.data, 'AdvertiserList');
      }
      dispatch(setCommonLoader(false));
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
      dispatch(setCommonLoader(false));
    }
  }
);

export const getDashboardAdvertiserList = createAsyncThunk('getDashboardAdvertiserList', async ({ secretKey }, { dispatch }) => {
  try {
    // dispatch(setCommonLoader(true));
    const response = await apiClient().get(`campaigns/advertiser-list-secret/?secret_key=${secretKey}`);

    const allAdvertiserList = {
      ...response?.data,
      data: response?.data?.data?.map((item) => {
        return { ...item, name: item?.nick_name };
      })
    };
    dispatch(setDashboardAdvertiserList(allAdvertiserList?.data));
    // dispatch(setCommonLoader(false));
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    // dispatch(setCommonLoader(false));
  }
});

export const getAlldvertiserList = createAsyncThunk('getAlldvertiserList', async (_, { dispatch }) => {
  try {
    const response = await apiClient().get(`advertisers/list-advertiser/`);
    const allAdvertiserList = response?.data?.data?.map((item) => {
      return { ...item, name: item?.nick_name };
    });
    dispatch(setAllUserDetails(allAdvertiserList));
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    // await printNotificationFailMsg(error, dispatch);
  }
});

export const postAdvertiserList = createAsyncThunk('postAdvertiserList', async ({ _request }, { dispatch }) => {
  try {
    _request = { ..._request, permission_request_type: 'create' };
    const response = await apiClient().post(`advertisers/list-create-advertiser/`, _request);
    dispatch(notificationSuccess(response?.data?.message));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const updateAdvertiser = createAsyncThunk('updateAdvertiser', async ({ _request, user_id }, { dispatch }) => {
  try {
    const response = await apiClient().put(`advertisers/ad-details/${user_id}/`, _request);
    dispatch(notificationSuccess(response?.data?.message));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const deleteAdvertiser = createAsyncThunk('deleteAdvertiser', async ({ advertise_id }, { dispatch }) => {
  try {
    dispatch(setCommonLoader(true));
    const response = await apiClient().delete(`advertisers/ad-details/${advertise_id}/`, {
      params: {
        permission_request_type: 'delete'
      }
    });
    dispatch(getAlldvertiserList({}));
    dispatch(notificationSuccess(response?.data?.message));
    dispatch(setCommonLoader(false));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
  }
});

export const postCampaignDetails = createAsyncThunk('postCampaignDetails', async (_request, { dispatch }) => {
  try {
    const response = await apiClient().post(`campaigns/create-product-campaign/`, _request);
    dispatch(setAdgorupDetails(response?.data?.data));
    dispatch(notificationSuccess(response?.data?.message));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    return error;
  }
});

export const postBrandCampaignDetails = createAsyncThunk('postBrandCampaignDetails', async (formData, { dispatch }) => {
  try {
    formData = { ...formData, permission_request_type: 'update' };
    const response = await apiClient().post(`campaigns/brand/`, formData);
    dispatch(setAdgorupDetails(response?.data?.data));
    dispatch(setExistingBrandId(response?.data?.data?.id));
    dispatch(notificationSuccess(response?.data?.message));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    return error;
  }
});

export const postAdgroupMedia = createAsyncThunk('postAdgroupMedia', async (_request, { dispatch }) => {
  try {
    const response = await apiClient().post(`campaigns/create-adgroup-media/`, _request);
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    return error;
  }
});

export const updateBrandCampaignDetails = createAsyncThunk(
  'updateBrandCampaignDetails',
  async ({ formData, brandId }, { dispatch }) => {
    try {
      formData = { ...formData, permission_request_type: 'update' };
      const response = await apiClient().put(`campaigns/brand/${brandId}/`, formData);
      dispatch(setAdgorupDetails(response?.data?.data));
      dispatch(notificationSuccess(response?.data?.message));
      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
      return error;
    }
  }
);

export const postTargetSettingDetails = createAsyncThunk('postTargetSettingDetails', async (_request, { dispatch }) => {
  try {
    _request = { ..._request, permission_request_type: 'create' };
    const response = await apiClient().post(`/campaigns/get-create-keyword/`, _request);
    dispatch(notificationSuccess(response?.data?.message));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    return error;
  }
});

export const getCampaignDetails = createAsyncThunk(
  'getCampaignDetails',
  async (
    {
      page,
      rowPerPage,
      search,
      changedColumn,
      direction,
      tableFilter,
      advertiserFilter,
      commonDateRange,
      PerformanceFilter,
      DailyBudget,
      is_csv,
      isComparingDates,
      is_inlineEdit,
      setIsCsvDownloadComplete
    },
    { dispatch }
  ) => {
    dispatch(setSkeletonLoader(true));
    try {
      if (!is_inlineEdit) {
        dispatch(setSkeletonLoader(true));
      }
      const buildFilterParams = (filterData) => {
        const senData = `&${filterData}=${PerformanceFilter[filterData].min},${PerformanceFilter[filterData].max}`;
        return senData;
      };
      let changedColumnSort = (direction == 'asc' ? '-' : '') + changedColumn;
      const response = await apiClient().get(
        `campaigns/campaign-list/?page_size=${rowPerPage}&page=${page + 1}` +
          (search ? `&search=${search}` : ``) +
          (is_csv ? `&is_csv_download=${is_csv}` : ``) +
          (changedColumn ? `&ordering=${changedColumnSort}` : ``) +
          (tableFilter.type ? `&campaign_type__in=${tableFilter.type}` : ``) +
          (tableFilter.campaign__in ? `&id__in=${tableFilter.campaign__in}` : ``) +
          (advertiserFilter ? `&advertiser__in=${advertiserFilter}` : ``) +
          (tableFilter.status ? `&status__in=${tableFilter.status}` : ``) +
          (tableFilter.targeting ? `&targeting_type__in=${tableFilter.targeting}` : ``) +
          (tableFilter?.campaign ? `&name__in=${tableFilter?.campaign}` : ``) +
          (tableFilter?.itemDropdownValue ? `&ItemIdLogic=${tableFilter?.itemDropdownValue == 'contains_or' ? 0 : 1}` : ``) +
          (tableFilter?.searchItemId ? `&ItemIds=${tableFilter?.searchItemId}` : ``) +
          (tableFilter?.attr_windows ? `&attr_windows=${tableFilter?.attr_windows}days` : ``) +
          `&start_date=${commonDateRange?.startDate}&end_date=${commonDateRange?.endDate}` +
          (isComparingDates
            ? `&cr_start_date=${commonDateRange?.compareStartDate}&cr_end_date=${commonDateRange?.compareEndDate}&is_comparison=true`
            : ``) +
          (PerformanceFilter
            ? Object?.keys(PerformanceFilter)
                .map((filter) => buildFilterParams(filter))
                .join('')
            : ``) +
          (DailyBudget?.daily_budget?.min?.length > 0 || DailyBudget?.daily_budget?.max?.length > 0
            ? `&daily_budget=${DailyBudget?.daily_budget?.min},${DailyBudget?.daily_budget?.max}`
            : ``)
      );

      if (!is_csv) {
        dispatch(setCampaignDetails(response?.data?.data?.table_data));
        dispatch(setCampaignTotal(response?.data?.data?.total_data));
        // dispatch(notificationSuccess(response?.data?.message));
      } else {
        DownloadCsvDataCommon(response?.data, 'campaign');
        setIsCsvDownloadComplete(true);
      }
      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
      dispatch(setSkeletonLoader(false));
    }
  }
);

export const getCampaignDetailsRules = createAsyncThunk(
  'getCampaignDetailsRules',
  async ({ page, rowPerPage, advertiserFilter, commonDateRange }, { dispatch }) => {
    try {
      dispatch(setCommonLoader(true));
      const response = await apiClient().get(
        `campaigns/campaign-list/?page_size=${rowPerPage}&page=${page + 1}` +
          (advertiserFilter ? `&advertiser__in=${advertiserFilter}` : ``) +
          `&start_date=${commonDateRange?.startDate}&end_date=${commonDateRange?.endDate}`
      );
      dispatch(setCampaignDetails(response?.data?.data?.table_data));
      dispatch(setCommonLoader(false));
      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
      dispatch(setCommonLoader(false));
    }
  }
);

export const ApplyRuleEngline = createAsyncThunk('ApplyRuleEngline', async ({ _request }, { dispatch }) => {
  try {
    dispatch(setCommonLoader(true));
    const response = await apiClient().post(`/campaigns/rule-engine/`, _request);
    dispatch(notificationSuccess(response?.data?.message));
    dispatch(setCommonLoader(false));
    return response;
  } catch (error) {
    console.log('error ', error);
    await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
  }
});

export const CreateRule = createAsyncThunk('CreateRule', async ({ _request, navigate }, { dispatch }) => {
  try {
    _request['rule_json'] = JSON.parse(JSON.stringify(_request?.rule_json));
    dispatch(setCommonLoader(true));
    const response = await apiClient().post(`/campaigns/create-rule/`, _request);
    dispatch(setCreateRule(response?.data?.data));
    dispatch(notificationSuccess(response?.data?.message));
    dispatch(setCommonLoader(false));
    if (response?.data?.data) {
      navigate('/ppc-rules');
    }
    return response;
  } catch (error) {
    console.log('error ', error);
    await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
  }
});

export const CheckExistingRule = createAsyncThunk('CheckExistingRule', async ({ _request }, { dispatch }) => {
  try {
    dispatch(setCommonLoader(true));
    const response = await apiClient().post(`/campaigns/check-existing-rule/`, _request);
    dispatch(setRuleExists(response?.data?.message));
    dispatch(setCommonLoader(false));
    return response;
  } catch (error) {
    console.log('error ', error);
    await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
  }
});

export const getRuleVariables = createAsyncThunk('getRuleVariables', async ({ moduleName }, { dispatch }) => {
  try {
    dispatch(setCommonLoader(true));
    const response = await apiClient().get(`campaigns/get-rule-data/?module_name=${moduleName}`);
    dispatch(setRuleVariables(response?.data?.data));
    dispatch(setCommonLoader(false));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
  }
});

export const getRulesList = createAsyncThunk('getRulesList', async ({ page, rowPerPage, profileIds }, { dispatch }) => {
  try {
    dispatch(setTableLoader(true));
    const response = await apiClient().get(
      `campaigns/rules-list/?page_size=${rowPerPage}&page=${page + 1}&profile_ids=${profileIds}`
    );
    dispatch(setRulesList(response?.data));
    // setTimeout(() => {
    dispatch(setTableLoader(false));
    // }, 1000);
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    dispatch(setTableLoader(false));
  }
});

export const updateRule = createAsyncThunk('updateRule', async ({ _request, RuleId }, { dispatch }) => {
  try {
    dispatch(setCommonLoader(true));
    const response = await apiClient().put(`/campaigns/update-rule/${RuleId}/`, _request?.updateRequest);
    dispatch(getRulesList(_request?.getRuleRequest));
    dispatch(setCommonLoader(false));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
  }
});

export const DeleteRule = createAsyncThunk('DeleteRule', async ({ ruleIds }, { dispatch }) => {
  try {
    dispatch(setCommonLoader(true));
    const _request = { ids: ruleIds };
    // Adjusted to send the request data properly for a DELETE request
    const response = await apiClient().delete(`campaigns/delete-rules/`, { data: _request });
    dispatch(notificationSuccess(response?.data?.message));
    dispatch(setCommonLoader(false));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
  }
});

export const GetBIDashboard = createAsyncThunk('GetBIDashboard', async ({ _request }, { dispatch }) => {
  try {
    dispatch(setCommonLoader(true));
    const response = await apiClient().post(`/advertisers/iframe-bi-dashboard/`, _request);
    if (response?.data?.data) {
      console.log('response?.data ', response?.data);
      dispatch(setBIDashboard(response?.data?.data?.iframe_url));
    }
    dispatch(setCommonLoader(false));
    return response;
  } catch (error) {
    console.log('error ', error);
    await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
  }
});

export const getSelectFromItems = createAsyncThunk('getSelectFromItems', async (_, { dispatch }) => {
  try {
    const response = await apiClient().get(`/marketplace/list-items/`);
    dispatch(setSelectItemData(response?.data?.data));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const getSelectFromItemsFromId = createAsyncThunk('getSelectFromItemsFromId', async ({ advertiserId }, { dispatch }) => {
  try {
    // dispatch(setCommonLoader(true));
    const response = await apiClient().get(`/marketplace/list-items/?advertiser_id=${advertiserId}`);
    dispatch(setSelectItemData(response?.data?.data));
    // dispatch(setCommonLoader(false));
    return response;
  } catch (error) {
    dispatch(setCommonLoader(false));
    await printNotificationFailMsg(error, dispatch);
  }
});

export const getSuggestedKeyword = createAsyncThunk('getSuggestedKeyword', async ({ adgroupId }, { dispatch }) => {
  try {
    // dispatch(setCommonLoader(true));
    const response = await apiClient().get(`/campaigns/get-suggested-keywords/?adgroup_id=${adgroupId}`);
    dispatch(setSuggestedkeywordData(response?.data?.data));
    // dispatch(setCommonLoader(false));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
  }
});

export const postCreateCampaign = createAsyncThunk('postCreateCampaign', async ({ _request }, { dispatch }) => {
  try {
    _request = { ..._request, permission_request_type: 'create' };
    dispatch(setCommonLoader(true));
    const response = await apiClient().post(`/campaigns/get-create-campaign/`, _request);
    dispatch(setCommonLoader(false));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
  }
});

export const getCampaignById = createAsyncThunk('getCampaignById', async ({ id }, { dispatch }) => {
  try {
    dispatch(setCommonLoader(true));
    const response = await apiClient().get(`/campaigns/get-create-campaign/${id}/?permission_request_type=access`);
    dispatch(setCampaignById(response?.data?.data));
    dispatch(setCommonLoader(false));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
  }
});

export const getAdgorupCampaignList = createAsyncThunk('getAdgorupCampaignList', async ({ id }, { dispatch }) => {
  try {
    // dispatch(setCommonLoader(true));
    const response = await apiClient().get(`/campaigns/list-adgroup-campaign/?campaign_id=${id}`);
    dispatch(setAdgroupList(response?.data?.data));
    // dispatch(setCommonLoader(false));
    return response;
  } catch (error) {
    dispatch(setCommonLoader(false));
    await printNotificationFailMsg(error, dispatch);
  }
});

export const updateCampaign = createAsyncThunk('updateCampaign', async ({ _request, existingCampaignId }, { dispatch }) => {
  try {
    _request = { ..._request, permission_request_type: 'update' };
    dispatch(setCommonLoader(true));
    const response = await apiClient().put(`/campaigns/get-create-campaign/${existingCampaignId}/`, _request);
    dispatch(setExistingCampaignId(response?.data?.data?.id));
    dispatch(setCommonLoader(false));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
  }
});

export const updateCampaignModal = createAsyncThunk('updateCampaign', async ({ _request, existingCampaignId }, { dispatch }) => {
  try {
    _request = { ..._request, permission_request_type: 'update' };
    const response = await apiClient().put(`/campaigns/get-create-campaign/${existingCampaignId}/`, _request);
    dispatch(notificationSuccess(response?.data?.message));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
    return error?.response;
  }
});

export const postCreateAdgroup = createAsyncThunk('postCreateAdgroup', async ({ _adgroupRequest }, { dispatch }) => {
  try {
    _adgroupRequest = { ..._adgroupRequest, permission_request_type: 'create' };
    const response = await apiClient().post(`/campaigns/get-create-adgroup/`, _adgroupRequest);
    dispatch(setExistingCampaignId(response?.data?.data?.campaign));
    dispatch(setExistingAdgroupId(response?.data?.data?.id));

    dispatch(setAdgorupDetails(response?.data?.data));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const updateAdgroup = createAsyncThunk('updateAdgroup', async ({ _adgroupRequest, existingAdgroupId }, { dispatch }) => {
  try {
    _adgroupRequest = { ..._adgroupRequest, permission_request_type: 'update' };
    const response = await apiClient().put(`/campaigns/retrieve-update-delete-adgroup/${existingAdgroupId}/`, _adgroupRequest);
    dispatch(setExistingCampaignId(response?.data?.data?.campaign));
    dispatch(setExistingAdgroupId(response?.data?.data?.id));

    dispatch(setAdgorupDetails(response?.data?.data));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const postCreateAditems = createAsyncThunk('postCreateAditems', async ({ _aditemRequest }, { dispatch }) => {
  try {
    _aditemRequest = { ..._aditemRequest, permission_request_type: 'create' };
    const response = await apiClient().post(`/campaigns/aditems/`, _aditemRequest);
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const getEnterKeywordList = createAsyncThunk('getEnterKeywordList', async ({ item_id, advertiserId }, { dispatch }) => {
  try {
    const response = await apiClient().get(`/marketplace/list-items/?itemids=${item_id}&advertiser_id=${advertiserId}`);
    dispatch(setCampaignById(response?.data?.data));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const postMultipleAditem = createAsyncThunk('postMultipleAditem', async ({ _request }, { dispatch }) => {
  try {
    const response = await apiClient().post(`/campaigns/create-multiple-aditem/`, _request);
    let array = response?.data?.data?.errorMessages?.map((item) => {
      const tempArray = item?.split(' ');
      return tempArray?.[tempArray?.length - 1];
    });
    if (response?.data?.data?.errorMessages?.length > 0) {
      dispatch(notificationFail('Cannot upload item. item or base item not owned by you, Input id: ' + array?.toString()));
    } else {
      dispatch(notificationSuccess(response?.data?.message));
    }
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const postMultipleKeyword = createAsyncThunk('postMultipleKeyword', async ({ _request }, { dispatch }) => {
  try {
    const response = await apiClient().post(`/campaigns/create-multiple-keyword/`, _request);
    dispatch(notificationSuccess(response?.data?.message));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const getCampaignDetailsById = createAsyncThunk('getCampaignDetailsById', async ({ id }, { dispatch }) => {
  try {
    const response = await apiClient().get(`/campaigns/campaign-details/${id}/`);
    dispatch(setCampaignDetailViewData(response?.data));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const getCampaignReportData = createAsyncThunk(
  'getCampaignReportData',
  async ({ advertiser_id, date_range, attribute_window, reportApiPath }, { dispatch }) => {
    try {
      const response = await apiClient().get(
        `campaigns/${reportApiPath}/?advertiser_id=${advertiser_id}&date=${date_range}&attribute_window=${attribute_window}days`
      );

      if (reportApiPath === 'campaign-performance-report') {
        dispatch(setCampaignReportData(response?.data?.data?.results));
      } else if (reportApiPath === 'aditem-report-list') {
        dispatch(setAdItemReportData(response?.data?.data?.results));
      } else if (reportApiPath === 'keyword-report-list') {
        dispatch(setKeywordReportData(response?.data?.data?.results));
      } else if (reportApiPath === 'adgroup-report-list') {
        dispatch(setAdGroupReportData(response?.data?.data?.results));
      }

      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
    }
  }
);

export const updateAdgroupTableItem = createAsyncThunk(
  'updateAdgroupTableItem',
  async ({ _adgroupRequest, existingAdgroupId }, { dispatch }) => {
    try {
      _adgroupRequest = { ..._adgroupRequest, permission_request_type: 'update' };
      const response = await apiClient().put(`/campaigns/retrieve-update-delete-adgroup/${existingAdgroupId}/`, _adgroupRequest);
      dispatch(notificationSuccess(response?.data?.message));
      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
    }
  }
);

export const updateAdItemsTableItem = createAsyncThunk(
  'updateAdItemsTableItem',
  async ({ _adItemsRequest, existingAdItemId }, { dispatch }) => {
    try {
      _adItemsRequest = { ..._adItemsRequest, permission_request_type: 'update' };
      dispatch(setCommonLoader(true));
      const response = await apiClient().put(`/campaigns/aditems/${existingAdItemId}/`, _adItemsRequest);
      dispatch(notificationSuccess(response?.data?.message));
      dispatch(setCommonLoader(false));
      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
      dispatch(setCommonLoader(false));
    }
  }
);

export const updateKeywordItemsTableItem = createAsyncThunk(
  'updateKeywordItemsTableItem',
  async ({ _keywordItemsRequest, existingKeyItemId }, { dispatch }) => {
    try {
      _keywordItemsRequest = { ..._keywordItemsRequest, permission_request_type: 'update' };
      const response = await apiClient().put(`/campaigns/update-delete-keyword/${existingKeyItemId}/`, _keywordItemsRequest);
      dispatch(notificationSuccess(response?.data?.message));
      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
    }
  }
);

export const postCampaignReview = createAsyncThunk('postCampaignReview', async ({ _request }, { dispatch }) => {
  try {
    const response = await apiClient().post(`/campaigns/create-campaign-review/`, _request);
    dispatch(notificationSuccess(response?.data?.message));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const putCancelReview = createAsyncThunk('putCancelReview', async ({ _request }, { dispatch }) => {
  try {
    const response = await apiClient().put(`/campaigns/cancel-review-status/`, _request);
    dispatch(notificationSuccess(response?.data?.message));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const getReviewLog = createAsyncThunk('getReviewLog', async ({ id }, { dispatch }) => {
  try {
    const response = await apiClient().get(`/campaigns/list-campaign-review/?campaign_id=${id}`);
    dispatch(setReviewLogData(response?.data?.data));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const getAdgroupDetails = createAsyncThunk(
  'getAdgroupDetails',
  async (
    {
      page,
      rowPerPage,
      changedColumn,
      direction,
      commonAdvertiserList,
      tableFilter,
      PerformanceFilter,
      commonDate,
      is_csv,
      isComparingDates,
      setIsCsvDownloadComplete
    },
    { dispatch }
  ) => {
    try {
      dispatch(setSkeletonLoader(true));
      const buildFilterParams = (filterData) => {
        const senData = `&${filterData}=${PerformanceFilter[filterData].min},${PerformanceFilter[filterData].max}`;
        return senData;
      };

      let changedColumnSort = (direction == 'asc' ? '-' : '') + changedColumn;
      const response = await apiClient().get(
        `campaigns/adgroup-list/?page_size=${rowPerPage}&page=${page + 1}` +
          (changedColumn ? `&ordering=${changedColumnSort}` : ``) +
          (is_csv ? `&is_csv_download=${is_csv}` : ``) +
          (commonAdvertiserList?.length ? `&campaign__advertiser__in=${commonAdvertiserList}` : ``) +
          (tableFilter?.campaign__in ? `&campaign__in=${tableFilter?.campaign__in}` : ``) +
          (tableFilter?.campaign_status ? `&campaign__status__in=${tableFilter?.campaign_status}` : ``) +
          (tableFilter?.campaign ? `&search=${tableFilter?.campaign}` : ``) +
          (tableFilter?.targeting ? `&campaign__targeting_type__in=${tableFilter?.targeting}` : ``) +
          (tableFilter?.type ? `&campaign__campaign_type__in=${tableFilter?.type}` : ``) +
          (tableFilter?.status ? `&campaign__status__in=${tableFilter?.status}` : ``) +
          (tableFilter?.adGroup_state ? `&status__in=${tableFilter?.adGroup_state}` : ``) +
          (tableFilter?.ad_group ? `&id__in=${tableFilter?.ad_group}` : ``) +
          (tableFilter?.attr_windows ? `&attr_windows=${tableFilter?.attr_windows}days` : ``) +
          (commonDate?.startDate && commonDate?.endDate
            ? `&start_date=${commonDate?.startDate}&end_date=${commonDate?.endDate}`
            : ``) +
          (isComparingDates
            ? `&cr_start_date=${commonDate?.compareStartDate}&cr_end_date=${commonDate?.compareEndDate}&is_comparison=true`
            : ``) +
          (PerformanceFilter
            ? Object?.keys(PerformanceFilter)
                .map((filter) => buildFilterParams(filter))
                .join('')
            : ``)
      );
      if (is_csv) {
        console.log('response.data ', response.data);
        DownloadCsvDataCommon(response.data, 'AdGroup');
        setIsCsvDownloadComplete(true);
      } else {
        dispatch(setAdgroupDetails(response?.data?.data?.table_data));
        dispatch(setAdgroupDetailsTotal(response?.data?.data?.total_data));
        // dispatch(notificationSuccess(response?.data?.message));
      }
      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
      dispatch(setSkeletonLoader(false));
    }
  }
);

export const getAdvertiserById = createAsyncThunk('getAdvertiserById', async ({ id, campaign_name }, { dispatch }) => {
  try {
    dispatch(setCommonLoader(true));
    const response = await apiClient().get(
      `/campaigns/list-campaign-advertiser/?advertiser_id=${id}&campaign_type=sponsoredProducts&campaign_name=${campaign_name}`
    );
    dispatch(setCommonLoader(false));
    dispatch(setCampaignByIdData(response?.data?.data));
    return response;
  } catch (error) {
    dispatch(setCommonLoader(false));
    await printNotificationFailMsg(error, dispatch);
  }
});

export const getAdvertiserState = createAsyncThunk('getAdvertiserState', async ({ _request }, { dispatch }) => {
  try {
    const response = await apiClient().post(`/campaigns/advertiser-stats/`, _request);
    dispatch(setAdvertiserState(response?.data?.data));
    dispatch(notificationSuccess(response?.data?.message));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const getUsermanagementList = createAsyncThunk(
  'getUsermanagementList',
  async ({ page, rowPerPage, changedColumn, direction, tableFilter }, { dispatch }) => {
    const isSuperAdmin = JSON.parse(localStorage.getItem('isSuperAdmin'));
    try {
      let url = isSuperAdmin ? 'sellcord-user-list' : 'list-user';
      let changedColumnSort = (direction == 'asc' ? '-' : '') + changedColumn;
      dispatch(setCommonLoader(true));
      const response = await apiClient().get(
        `/accounts/${url}/?page_size=${rowPerPage}&page=${page + 1}` +
          (changedColumn ? `&ordering=${changedColumnSort}` : ``) +
          (tableFilter?.email?.length > 0 ? `&email__icontains=${tableFilter?.email}` : ``) +
          (tableFilter?.status?.length > 0 ? `&is_active=${tableFilter?.status == 1 ? true : false}` : ``) +
          (tableFilter?.user_type?.length > 0 ? `&user_type__in=${tableFilter?.user_type}` : ``)
      );
      dispatch(setCommonLoader(false));
      dispatch(setUserManagementDetails(response?.data));
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
      dispatch(setCommonLoader(false));
    }
  }
);

export const getVerifiedUserList = createAsyncThunk(
  'getVerifiedUserList',
  async ({ page, rowPerPage, search, changedColumn, direction }, { dispatch }) => {
    const isSuperAdmin = JSON.parse(localStorage.getItem('isSuperAdmin'));
    try {
      let url = isSuperAdmin ? 'sellcord-invited-user-list' : 'list-invite-user';
      let changedColumnSort = (direction == 'asc' ? '-' : '') + changedColumn;
      const response = await apiClient().get(
        `/accounts/${url}/?page_size=${rowPerPage}&page=${page + 1}` +
          (search ? `&search=${search}` : `` + (changedColumn ? `&ordering=${changedColumnSort}` : ``))
      );
      dispatch(setVerifiedUserDetails(response?.data));
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
    }
  }
);

export const postUserData = createAsyncThunk('postUserData', async ({ _request }, { dispatch }) => {
  const superAdmin = JSON.parse(localStorage.getItem('isSuperAdmin'));
  const url = superAdmin ? 'create-sellcord-user' : 'user-register';
  try {
    const response = await apiClient().post(`/accounts/${url}/`, _request);
    dispatch(notificationSuccess(response?.data?.message));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const updateUserData = createAsyncThunk('updateUserData', async ({ _request, user_id }, { dispatch }) => {
  try {
    const response = await apiClient().put(`/accounts/retrieve-update-user/${user_id}/`, _request);
    dispatch(notificationSuccess(response?.data?.message));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const deleteUser = createAsyncThunk('deleteUser', async ({ user_id }, { dispatch }) => {
  try {
    const response = await apiClient().delete(`/accounts/delete-user/${user_id}/`);
    dispatch(notificationSuccess('User Deleted Successfully'));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});
export const getLatestReportDateData = createAsyncThunk('getLatestReportDateData', async ({ id }, { dispatch }) => {
  try {
    const response = await apiClient().get(`/campaigns/get-latest-report-date/?advertiser_id=${id}`);
    dispatch(setLatestReportDateData(response?.data?.data));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const getApiUsageAnalyzeData = createAsyncThunk('getApiUsageAnalyzeData', async (_, { dispatch }) => {
  try {
    const response = await apiClient().get(`/campaigns/get-api-usage-analyze/`);
    dispatch(setApiUsageAnalyzeData(response?.data?.data));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const updateProfie = createAsyncThunk('updateProfie', async ({ _request }, { dispatch }) => {
  try {
    const response = await apiClient().post(`/accounts/reset-password/`, _request);
    dispatch(notificationSuccess(response?.data?.message));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const getCampaignNameExist = createAsyncThunk('getCampaignNameExist', async ({ campaign_name }, { dispatch }) => {
  try {
    const response = await apiClient().get(`campaigns/get-campaign-name/?campaign_name=${campaign_name}`);
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const getProfileDetails = createAsyncThunk('getProfileDetails', async (_, { dispatch }) => {
  try {
    const response = await apiClient().get(`accounts/retrieve-update-profile/`);
    dispatch(setProfileDetails(response?.data?.data));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const updateProfileDetails = createAsyncThunk(
  'updateProfileDetails',
  async ({ formData, setBeError, setLoading, navigate }, { dispatch }) => {
    try {
      const response = await apiClient().put(`accounts/retrieve-update-profile/`, formData);
      dispatch(setProfileDetails(response?.data?.data));
      dispatch(notificationSuccess(response?.data?.message));
      localStorage.setItem('user_profile', JSON.stringify(response?.data?.data?.profile));
      setBeError('');

      const restdata = {
        ...JSON.parse(localStorage.getItem('user_data')),
        display_module_fe: 'welcome-to-dashna'
      };
      localStorage.setItem('user_data', JSON.stringify(restdata));
      dispatch(setUserData(restdata));
      navigate('/welcome-to-dashna');
      return response;
    } catch (error) {
      setLoading(false);
      setBeError(error?.response?.data?.message || Messages.ERROR.LOGIN);
      await printNotificationFailMsg(error, dispatch);
    }
  }
);

export const postInviteUser = createAsyncThunk('postInviteUser', async ({ _request }, { dispatch }) => {
  try {
    const response = await apiClient().post(`accounts/send-verify-email/`, _request);
    dispatch(notificationSuccess(response?.data?.message));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    return error;
  }
});

export const postSearchItems = createAsyncThunk('postSearchItems', async ({ _request }, { dispatch }) => {
  try {
    const response = await apiClient().post(`/campaigns/search-items/`, _request);
    dispatch(setSearchItems(response?.data?.data));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const postUploadMedia = createAsyncThunk('postUploadMedia', async ({ formData }, { dispatch }) => {
  try {
    const response = await apiClient().post(`/campaigns/create-media/`, formData);
    dispatch(setSearchItems(response?.data?.data));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const getVideLiabraryList = createAsyncThunk(
  'getVideLiabraryList',
  async ({ page, rowPerPage, search, changedColumn, direction, commonAdvertiserList, tableFilter, is_csv }, { dispatch }) => {
    try {
      let changedColumnSort = (direction == 'asc' ? '-' : '') + changedColumn;
      const response = await apiClient().get(
        `/campaigns/list-media/?page_size=${rowPerPage}&page=${page + 1}` +
          (search
            ? `&search=${search}`
            : `` + (changedColumn ? `&ordering=${changedColumnSort}` : ``) + (is_csv ? `&is_csv_download=${is_csv}` : ``)) +
          (commonAdvertiserList?.length
            ? `&advertiser__in=${commonAdvertiserList}` +
              (tableFilter?.media_status ? `&status__in=${tableFilter.media_status}` : ``) +
              (tableFilter?.creator ? `&user__in=${tableFilter.creator}` : ``)
            : ``)
      );
      if (!is_csv) {
        dispatch(setVideoLibraryList(response?.data));
        dispatch(notificationSuccess(response?.data?.message));
      } else {
        DownloadCsvDataCommon(response?.data, 'Video');
      }
      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
    }
  }
);

export const getItemsDetails = createAsyncThunk(
  'getItemsDetails',
  async (
    {
      page,
      rowPerPage,
      changedColumn,
      direction,
      commonAdvertiserList,
      PerformanceFilter,
      tableFilter,
      bidRanges,
      addDate,
      groupBy,
      commonDate,
      is_csv,
      isComparingDates,
      setIsCsvDownloadComplete
    },
    { dispatch }
  ) => {
    const inputString = tableFilter?.item_status || '';
    const values = inputString.split(',');

    const stateKeywords = ['enabled', 'disabled', 'deleted'];
    const statusKeywords = ['pending', 'approved', 'rejected'];

    const stateValues = values.filter((value) => stateKeywords.includes(value));
    const statusValues = values.filter((value) => statusKeywords.includes(value));

    const stateQuery = `${stateValues.join(',')}`;
    const statusQuery = `${statusValues.join(',')}`;

    try {
      dispatch(setSkeletonLoader(true));
      const buildFilterParams = (filterVal) => {
        const senData = `&${filterVal}=${PerformanceFilter[filterVal].min},${PerformanceFilter[filterVal].max}`;
        return senData;
      };
      if (changedColumn === 'details') {
        changedColumn = 'item_id';
      }

      let changedColumnSort = (direction == 'asc' ? '-' : '') + changedColumn;

      const response = await apiClient().get(
        `campaigns/aditem-list/?page_size=${rowPerPage}&page=${page + 1}` +
          (changedColumn ? `&ordering=${changedColumnSort}` : ``) +
          (commonAdvertiserList?.length ? `&campaign__advertiser__in=${commonAdvertiserList}` : ``) +
          (is_csv ? `&is_csv_download=${is_csv}` : ``) +
          // (tableFilter?.type === CAMPAIGN.NAVIGATION_TYPE.ADGROUP
          //   ? `&adgroup__in=${tableFilter?.adgroup__in}`
          //   : groupBy?.value
          //   ? `&group_by=${groupBy?.value}`
          //   : ``) +
          (groupBy?.value ? `&group_by=${groupBy?.value}` : ``) +
          (tableFilter?.campaign__in ? `&campaign__in=${tableFilter?.campaign__in}` : ``) +
          (tableFilter?.campaign_state ? `&campaign__status__in=${tableFilter?.campaign_state}` : ``) +
          (tableFilter?.status ? `&campaign__status__in=${tableFilter?.status}` : ``) +
          (tableFilter?.campaign_type ? `&campaign__campaign_type__in=${tableFilter?.campaign_type}` : ``) +
          (tableFilter?.type ? `&campaign__campaign_type__in=${tableFilter?.type}` : ``) +
          (tableFilter.targeting ? `&campaign__targeting_type__in=${tableFilter.targeting}` : ``) +
          (tableFilter?.adGroup_state ? `&adgroup__status__in=${tableFilter?.adGroup_state}` : ``) +
          (tableFilter?.campaign ? `&item_id__in=${tableFilter?.campaign}` : ``) +
          (tableFilter?.adgroup__in ? `&adgroup__in=${tableFilter?.adgroup__in}` : ``) +
          (tableFilter?.brand ? `&brand__in=${tableFilter?.brand}` : ``) +
          (tableFilter?.item_status && stateQuery ? `&status__in=${stateQuery}` : ``) +
          (tableFilter?.item_status && statusQuery ? `&reviewStatus__in=${statusQuery}` : ``) +
          (tableFilter?.attr_windows ? `&attr_windows=${tableFilter?.attr_windows}days` : ``) +
          (bidRanges?.bid ? `&bid_amount_min=${bidRanges?.bid?.min}&bid_amount_max=${bidRanges?.bid?.max}` : ``) +
          (addDate?.length > 0 ? `&created_at__date__range=${addDate?.[0]},${addDate?.[1]}` : ``) +
          (commonDate?.startDate && commonDate?.endDate
            ? `&start_date=${commonDate?.startDate}&end_date=${commonDate?.endDate}`
            : ``) +
          (isComparingDates
            ? `&cr_start_date=${commonDate?.compareStartDate}&cr_end_date=${commonDate?.compareEndDate}&is_comparison=true`
            : ``) +
          (PerformanceFilter ? Object?.keys(PerformanceFilter).map((filter) => buildFilterParams(filter)) : ``)
      );

      if (!is_csv) {
        dispatch(setItemsDetails(response?.data?.data?.table_data));
        dispatch(setItemsDetailsTotal(response?.data?.data?.total_data));
        // dispatch(notificationSuccess(response?.data?.message));
      } else {
        DownloadCsvDataCommon(response?.data, 'Item');
        setIsCsvDownloadComplete(true);
      }

      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
      dispatch(setSkeletonLoader(false));
    }
  }
);

export const getKeywordsDetails = createAsyncThunk(
  'getKeywordsDetails',
  async (
    {
      page,
      rowPerPage,
      search,
      changedColumn,
      direction,
      commonAdvertiserList,
      PerformanceData,
      tableFilter,
      groupBy,
      commonDate,
      is_csv,
      isComparingDates,
      setIsCsvDownloadComplete
    },
    { dispatch }
  ) => {
    try {
      dispatch(setSkeletonLoader(true));
      const buildFilterParams = (filterVal) => {
        const senData = `&${filterVal}=${PerformanceData[filterVal].min},${PerformanceData[filterVal].max}`;
        return senData;
      };
      let showKeywordSorting = false;
      if (groupBy?.id !== 2 && changedColumn == 'suggested_bid') {
        showKeywordSorting = true;
      } else {
        showKeywordSorting = false;
      }
      let changedColumnSort = (direction == 'asc' ? '-' : '') + changedColumn;
      const inputString = tableFilter?.keyword_status || '';
      const values = inputString.split(',');

      const stateKeywords = ['enabled', 'paused', 'archived'];
      const statusKeywords = ['pending', 'approved', 'rejected'];

      const stateValues = values.filter((value) => stateKeywords.includes(value));
      const statusValues = values.filter((value) => statusKeywords.includes(value));

      const stateQuery = `${stateValues.join(',')}`;
      const statusQuery = `${statusValues.join(',')}`;

      let shorting = false;
      if (
        groupBy?.value == 'aditem' &&
        [
          'instore_attributed_sales',
          'instore_orders',
          'instore_other_sales',
          'instore_units_sold',
          'instore_advertised_sales'
        ].includes(changedColumn)
      ) {
        shorting = true;
      } else {
        shorting = false;
      }

      const response = await apiClient().get(
        `campaigns/keyword-list/?page_size=${rowPerPage}&page=${page + 1}` +
          (search
            ? `&search=${search}`
            : `` + (showKeywordSorting ? `` : shorting ? `` : changedColumn ? `&ordering=${changedColumnSort}` : ``)) +
          (tableFilter.campaign_type ? `&campaign__campaign_type__in=${tableFilter.campaign_type}` : ``) +
          (tableFilter.type ? `&campaign__campaign_type__in=${tableFilter.type}` : ``) +
          (tableFilter.targeting ? `&campaign__targeting_type__in=${tableFilter.targeting}` : ``) +
          (is_csv ? `&is_csv_download=${is_csv}` : ``) +
          (commonAdvertiserList?.length ? `&campaign__advertiser__in=${commonAdvertiserList}` : ``) +
          // (tableFilter?.type === CAMPAIGN.NAVIGATION_TYPE.ADGROUP && tableFilter?.adgroup__in
          //   ? `&adgroup__in=${tableFilter?.adgroup__in}`
          //   : ``) +
          (tableFilter?.adgroup__in ? `&adgroup__in=${tableFilter?.adgroup__in}` : ``) +
          (tableFilter?.campaign__in ? `&campaign__in=${tableFilter?.campaign__in}` : ``) +
          (tableFilter?.campaign ? `&name__in=${tableFilter?.campaign}` : ``) +
          (tableFilter?.campaign__state ? `&campaign__status__in=${tableFilter?.campaign__state}` : ``) +
          (tableFilter?.status ? `&campaign__status__in=${tableFilter?.status}` : ``) +
          (tableFilter?.adGroup_state ? `&adgroup__status__in=${tableFilter?.adGroup_state}` : ``) +
          (tableFilter?.ad_group ? `&adgroup__in=${tableFilter?.ad_group}` : ``) +
          (tableFilter?.match_type ? `&matchtype__in=${tableFilter?.match_type}` : ``) +
          (tableFilter?.keyword_status && stateQuery ? `&state__in=${stateQuery}` : ``) +
          (tableFilter?.keyword_status && statusQuery ? `&status__in=${statusQuery}` : ``) +
          (tableFilter?.attr_windows ? `&attr_windows=${tableFilter?.attr_windows}days` : ``) +
          (groupBy?.value ? `&group_by=${groupBy?.value}` : ``) +
          (commonDate?.startDate && commonDate?.endDate
            ? `&start_date=${commonDate?.startDate}&end_date=${commonDate?.endDate}`
            : ``) +
          (isComparingDates
            ? `&cr_start_date=${commonDate?.compareStartDate}&cr_end_date=${commonDate?.compareEndDate}&is_comparison=true`
            : ``) +
          (PerformanceData
            ? Object?.keys(PerformanceData)
                .map((filter) => buildFilterParams(filter))
                .join('')
            : ``)
      );
      if (!is_csv) {
        // dispatch(notificationSuccess(response?.message));
        dispatch(setKeywordsDetails(response?.data?.data?.table_data));
        dispatch(setKeywordsDetailsTotal(response?.data?.data?.total_data));
      } else {
        console.log('response.data ', response.data);
        DownloadCsvDataCommon(response.data, 'Keyword');
        setIsCsvDownloadComplete(true);
      }
      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
      dispatch(setSkeletonLoader(false));
    }
  }
);

export const putUpdateMedia = createAsyncThunk('putUpdateMedia', async ({ _request }, { dispatch }) => {
  try {
    const response = await apiClient().put(`/campaigns/update-adgroup-media/`, _request);
    dispatch(setSearchItems(response?.data?.data));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});
export const getCampaignNameList = createAsyncThunk('getCampaignNameList', async ({ id }, { dispatch }) => {
  try {
    const response = await apiClient().get(`campaigns/campaign-short-info/?` + (id?.length > 0 ? `advertiser__in=${id}` : ``));
    dispatch(setCampaignNameList(response?.data));
    dispatch(notificationSuccess(response?.message));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const postBulkOperation = createAsyncThunk('postUploadMedia', async ({ formData }, { dispatch }) => {
  try {
    const response = await apiClient().post(`/campaigns/bulk-campaign-creation/`, formData);
    dispatch(notificationSuccess(response?.data?.message || Messages.ERROR.LOGIN));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const postVerifyCampaignFile = createAsyncThunk('postVerifyCampaignFile', async ({ formData }, { dispatch }) => {
  try {
    const response = await apiClient().post(`/campaigns/verify-campaign-file/`, formData);
    dispatch(setVerifyCampaignFile(response?.data?.data));
    if (response?.data?.data?.display_data) {
      dispatch(notificationSuccess(response?.data?.message || Messages.ERROR.LOGIN));
    }
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const getBulkOperation = createAsyncThunk('getAdvertiserList', async (_, { dispatch }) => {
  try {
    const response = await apiClient().get(`/campaigns/template-url/`, { responseType: 'arraybuffer' });
    dispatch(notificationSuccess('Starting download'));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const getUserSortInfo = createAsyncThunk('getUserSortInfo', async (_, { dispatch }) => {
  try {
    const response = await apiClient().get(`/accounts/user-short-info/`);
    dispatch(setUserSortInfo(response?.data));
    dispatch(notificationSuccess(response?.message));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const deleteMedia = createAsyncThunk('deleteMedia', async ({ id }, { dispatch }) => {
  try {
    const response = await apiClient().delete(`/campaigns/update-delete-media/${id}/?permission_request_type=delete`);
    dispatch(notificationSuccess(response?.data?.message));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const getBulkoperationsList = createAsyncThunk(
  'getBulkoperationsList',
  async ({ page, rowPerPage, changedColumn, direction }, { dispatch }) => {
    try {
      let changedColumnSort = (direction == 'asc' ? '-' : '') + changedColumn;
      let url = `campaigns/bulk-operation-list/?page_size=${rowPerPage}&page=${page + 1}`;
      if (direction && changedColumn) {
        url += `&ordering=${changedColumnSort}`;
      }
      const response = await apiClient().get(url);
      dispatch(setBulkOperations(response?.data));
      dispatch(notificationSuccess(response?.message));
      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
    }
  }
);

export const getBulkoperationsIdList = createAsyncThunk(
  'getBulkoperationsIdList',
  async ({ bulkId, orderType, orderColumn, orderKeyName }, { dispatch }) => {
    let changedColumnSort = (orderType == 'asc' ? '-' : '') + orderColumn;
    try {
      let url = `campaigns/retrieve-bulk-operation/${bulkId}/`;
      if (orderType || orderColumn) {
        url += `?ordering=${changedColumnSort}&ordering_key=${orderKeyName}`;
      }
      const response = await apiClient().get(url);
      dispatch(setVerifyCampaignFile(response?.data?.data));
      dispatch(notificationSuccess(response?.message));
      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
    }
  }
);

export const getCampaignAllDetails = createAsyncThunk('getCampaignAllDetails', async (_request, { dispatch }) => {
  const { advrid, impressions, type } = _request || {};
  try {
    dispatch(setCommonLoader(true));
    let url = `campaigns/campaign-short-info/?campaign_type__in=${type}`;
    if (advrid) {
      url += `&advertiser__in=${advrid}`;
    }
    if (impressions) {
      url += `&impressions=${impressions}`;
    }

    const response = await apiClient().get(url);
    dispatch(setAllCampaignShortData(response?.data));
    dispatch(setCommonLoader(false));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
  }
});

export const DownloadExistingData = createAsyncThunk('getCampaignAllDetails', async (_request, { dispatch }) => {
  try {
    const allIds = _request?.Ids.join(',');
    const url = `campaigns/bulk-campaign-edit/?campaign_ids=${allIds}`;
    const response = await apiClient().get(url, { responseType: 'arraybuffer' });
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const postSelectedFilterDataCampaign = createAsyncThunk(
  'postSelectedFilterDataCampaign',
  async (_request, { dispatch }) => {
    try {
      const response = await apiClient().post(`campaigns/create-filter/`, _request);
      dispatch(notificationSuccess(response?.data?.message));
      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
      return error;
    }
  }
);

export const getSelectedFilterList = createAsyncThunk('getSelectedFilterList', async (_, { dispatch }) => {
  try {
    let url = `campaigns/retrieve-filter/`;
    const response = await apiClient().get(url);
    dispatch(setSelectedFilterData(response?.data?.data));
    dispatch(notificationSuccess(response?.message));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const getDateWiseData = createAsyncThunk('getDateWiseData', async ({ _request }, { dispatch }) => {
  try {
    let URL = `/campaigns/dashboard-data/?start_date=${_request?.start_date}&end_date=${_request?.end_date}`;
    if (_request?.advertiser_id?.length > 0) {
      URL += `&advertiser__in=${_request?.advertiser_id.join(',')}`;
    }
    if (_request?.campaign__in?.length > 0) {
      URL += `&campaign__in=${_request?.campaign__in}`;
    }
    if (_request?.ad_group?.length > 0) {
      URL += `&adgroup__in=${_request?.ad_group}`;
    }
    if (_request?.ad_item?.length > 0) {
      URL += `&item__in=${_request?.ad_item}`;
    }
    if (_request?.keyword__in?.length > 0) {
      URL += `&keyword__in=${_request?.keyword__in}`;
    }
    const response = await apiClient().get(URL);
    dispatch(notificationSuccess(response?.data?.message));
    dispatch(setDashboardReport(response?.data?.data));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const getAdGroupList = createAsyncThunk('getAdGroupList', async ({ id }, { dispatch }) => {
  try {
    const response = await apiClient().get(
      `campaigns/adgroup-short-info/?` + (id?.length > 0 ? `campaign__advertiser__in=${id}` : ``)
    );
    dispatch(setAdGroupShortList(response?.data));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const getAdItemList = createAsyncThunk('getAdItemList', async ({ id }, { dispatch }) => {
  try {
    const response = await apiClient().get(
      `campaigns/aditem-short-info/?` + (id?.length > 0 ? `campaign__advertiser__in=${id}` : ``)
    );
    dispatch(setAdItemShortList(response?.data));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const getKeywordShortList = createAsyncThunk('getKeywordShortList', async ({ id }, { dispatch }) => {
  try {
    const response = await apiClient().get(
      `campaigns/keyword-short-info/?` + (id?.length > 0 ? `campaign__advertiser__in=${id}` : ``)
    );
    dispatch(setKeywordShortList(response?.data));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
  z;
});

export const postSaveReportForCustom = createAsyncThunk('postSaveReportForCustom', async (_request, { dispatch }) => {
  try {
    _request = { ..._request, permission_request_type: 'access' };
    const response = await apiClient().post(`campaigns/custom-report-save-retrieve/`, _request);
    dispatch(notificationSuccess(response?.data?.message));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    return error;
  }
});

export const getSaveReportForCustom = createAsyncThunk('getSaveReportForCustom', async (_, { dispatch }) => {
  try {
    let url = `campaigns/custom-report-save-retrieve/?permission_request_type=access`;
    const response = await apiClient().get(url);
    dispatch(setSelectedCustomReport(response?.data?.data));
    dispatch(notificationSuccess(response?.message));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const updateFilterForSaveReport = createAsyncThunk('updateFilterForSaveReport', async (payload11, { dispatch }) => {
  try {
    let requestparams = payload11._request;
    requestparams = { ...requestparams, permission_request_type: 'access' };
    const response = await apiClient().put(`campaigns/custom-report-update-delete/${payload11.id}/`, requestparams);
    dispatch(notificationSuccess(response?.data?.message));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const deleteUserReport = createAsyncThunk('deleteUserReport', async ({ id }, { dispatch }) => {
  try {
    const response = await apiClient().delete(`campaigns/custom-report-update-delete/${id}?permission_request_type=delete`);
    dispatch(notificationSuccess(response?.data?.message));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const getBrandDatas = createAsyncThunk('getBrandDatas', async (_, { dispatch }) => {
  try {
    const response = await apiClient().get(`/marketplace/list-items-brand-name/`);
    dispatch(setAllBrandLists(response?.data?.data));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const FilterStatusEnableAndPause = createAsyncThunk('FilterStatusEnableAndPause', async (payload11, { dispatch }) => {
  try {
    const response = await apiClient().put(`campaigns/campaign-status-update/`, payload11?.payload);
    dispatch(notificationSuccess(response?.data?.message));
    // dispatch(getCampaignDetails(payload11?.listingObj));
    // dispatch(setCommonLoader(true));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
  }
});

export const updateKeywordStatus = createAsyncThunk('updateKeywordStatus', async (payload11, { dispatch }) => {
  try {
    payload11.newPayload = { ...payload11.newPayload, permission_request_type: 'update' };

    const response = await apiClient().put(`campaigns/update-delete-keyword/${payload11.ids}/`, payload11.newPayload);
    dispatch(notificationSuccess(response?.data?.message));

    let oldResults = JSON.parse(JSON.stringify(payload11?.oldData?.results));

    if (oldResults) {
      const indexToUpdate = oldResults.findIndex((obj) => obj.id === payload11.ids);

      if (indexToUpdate !== -1) {
        const newPayload = payload11.newPayload;

        for (const key in newPayload) {
          oldResults[indexToUpdate][key] = newPayload[key];
        }
      } else {
        console.log('No object found with the given id.');
      }
    }

    dispatch(setKeywordsDetails({ ...payload11?.oldData, results: oldResults }));

    return response;
  } catch (error) {
    console.log('error ', error);
    await printNotificationFailMsg(error, dispatch);
    // dispatch(setCommonLoader(false));
  }
});

export const FilterMultipleStatusEnableAndPauseKeyword = createAsyncThunk(
  'FilterStatusEnableAndPause',
  async (payload11, { dispatch }) => {
    try {
      const response = await apiClient().put(`campaigns/keyword-bulk-update/`, payload11.payload);
      dispatch(notificationSuccess(response?.data?.message));
      payload11.setSuccessFailModal(true);
      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
      dispatch(setCommonLoader(false));
    }
  }
);

export const updateItemStatus = createAsyncThunk('updateItemStatus', async (payload11, { dispatch }) => {
  try {
    payload11.newPayload = { ...payload11.newPayload, permission_request_type: 'update' };
    const response = await apiClient().put(`campaigns/aditems/${payload11.ids}/`, payload11.newPayload);
    dispatch(notificationSuccess(response?.data?.message));
    let oldResults = JSON.parse(JSON.stringify(payload11?.oldData?.results));

    if (oldResults) {
      const indexToUpdate = oldResults.findIndex((obj) => obj.id === payload11.ids);

      if (indexToUpdate !== -1) {
        const newPayload = payload11.newPayload;

        for (const key in newPayload) {
          oldResults[indexToUpdate][key] = newPayload[key];
        }
      } else {
        console.log('No object found with the given id.');
      }
    }
    dispatch(setItemsDetails({ ...payload11?.oldData, results: oldResults }));
    // dispatch(getItemsDetails(payload11?.defaultData));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const updateMultipleBidKeyword = createAsyncThunk('updateMultipleBidKeyword', async (payload, { dispatch }) => {
  try {
    const response = await apiClient().put(`campaigns/keyword-bulk-update/`, payload.payload1);
    dispatch(notificationSuccess(response?.data?.message));
    // await dispatch(getKeywordsDetails(payload?.defaultData));
    payload.setSuccessFailModal(true);
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const updateMultipleBidKeywordMultiple = createAsyncThunk('updateMultipleBidKeyword', async (payload, { dispatch }) => {
  try {
    const response = await apiClient().put(`campaigns/keyword-percentage-bid/`, payload.payload1);
    dispatch(notificationSuccess(response?.data?.message));

    dispatch(getKeywordsDetails(payload?.defaultData));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const updateAdGroupModal = createAsyncThunk('updateAdGroupModal', async ({ _request, existGroupId }, { dispatch }) => {
  try {
    _request = { ..._request, permission_request_type: 'update' };
    const response = await apiClient().put(`/campaigns/retrieve-update-delete-adgroup/${existGroupId}/`, _request);
    dispatch(notificationSuccess(response?.data?.message));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    return error?.response;
  }
});

export const updateMultipleBudgetCampaign = createAsyncThunk('updateMultipleBidKeyword', async (payload, { dispatch }) => {
  try {
    const response = await apiClient().put(`campaigns/campaign-budget-update/`, payload.payload1);
    dispatch(notificationSuccess(response?.data?.message));
    // dispatch(getCampaignDetails(payload?.listingObj));
    // dispatch(setCommonLoader(true));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
  }
});

export const updateMultipleEndCampaign = createAsyncThunk('updateMultipleEndCampaign', async (payload, { dispatch }) => {
  try {
    // console.log('updateItems');
    const response = await apiClient().put(`campaigns/campaign-end-date-update/`, payload.payload1);
    dispatch(notificationSuccess(response?.data?.message));
    // dispatch(getCampaignDetails(payload?.listingObj));
    // dispatch(setCommonLoader(true));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
  }
});

export const updateMultiplePlatformCampaign = createAsyncThunk(
  'updateMultiplePlatformCampaign',
  async (payload, { dispatch }) => {
    try {
      // console.log('updateItems');
      const response = await apiClient().put(`campaigns/campaign-platform-update/`, payload.payload1);
      dispatch(notificationSuccess(response?.data?.message));

      // dispatch(getCampaignDetails(payload?.listingObj));
      // dispatch(setCommonLoader(true));
      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
      dispatch(setCommonLoader(false));
    }
  }
);

export const SendDashboardReport = createAsyncThunk('SendDashboardReport', async (payload, { dispatch }) => {
  try {
    console.log('updateItems');
    const response = await apiClient().post(`campaigns/dashboard-report-excel/`, payload, { responseType: 'arraybuffer' });
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const SendDashboardReportPDF = createAsyncThunk('SendDashboardReport', async (payload, { dispatch }) => {
  try {
    const response = await apiClient().post(`campaigns/dashboard-report-pdf/`, payload, { responseType: 'arraybuffer' });
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const SendAllReport = createAsyncThunk('SendAllReport', async (payload, { dispatch }) => {
  try {
    const response = await apiClient().post(`campaigns/report-data-to-excel/`, payload, { responseType: 'arraybuffer' });
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const CampaignPlacementAutoManual = createAsyncThunk('CampaignPlacementAutoManual', async (payload11, { dispatch }) => {
  try {
    // console.log('updateItems');
    const response = await apiClient().put(`campaigns/campaign-placement-update/`, payload11.payload);
    dispatch(notificationSuccess(response?.data?.message));

    // dispatch(getCampaignDetails(payload11?.listingObj));
    // dispatch(setCommonLoader(true));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
  }
});

export const postMultipleKeywordID = createAsyncThunk('postMultipleKeyword', async ({ _request }, { dispatch }) => {
  try {
    const response = await apiClient().post(`/campaigns/campaign-bulk-keyword/`, _request);
    dispatch(notificationSuccess(response?.data?.message));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const postMultipleAdItemNewApi = createAsyncThunk('postMultipleAditem', async ({ _request }, { dispatch }) => {
  try {
    const response = await apiClient().post(`/campaigns/campaign-bulk-aditem/`, _request);
    let array = response?.data?.data?.errorMessages?.map((l) => l?.name);
    if (response?.data?.data?.errorMessages?.length > 0) {
      dispatch(notificationFail('Cannot upload item. item or base item not owned by you, Input id: ' + array?.toString()));
    } else {
      dispatch(notificationSuccess(response?.data?.message));
    }
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const SendCampaignReportDownload = createAsyncThunk('SendDashboardReport', async (payload, { dispatch }) => {
  try {
    const response = await apiClient().post(`campaigns/dashboard-report-excel/`, payload, { responseType: 'arraybuffer' });
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

//API for get chart data in budget manager ad profile
export const getBudgetPerformance = createAsyncThunk('getBudgetPerformance', async ({ advertiserId }, { dispatch }) => {
  try {
    dispatch(setCommonLoader(true));
    const response = await apiClient().get(`campaigns/get-budget-performance/?advertiser_id=${advertiserId}`);
    dispatch(setCommonLoader(false));
    dispatch(setBudgetPerformanceData(response?.data?.data));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
  }
});

//API for get profiles in budget manager add profile
export const getBudgetProfiles = createAsyncThunk('getBudgetProfiles', async (_, { dispatch }) => {
  try {
    dispatch(setCommonLoader(true));
    const response = await apiClient().get(`campaigns/budget-profile-list/`);
    dispatch(setCommonLoader(false));
    const allAdvertiserList = {
      ...response?.data,
      data: response?.data?.data?.map((item) => {
        return { ...item, name: item?.nick_name };
      })
    };
    dispatch(setBudgetProfiles(allAdvertiserList?.data));
    return response;
  } catch (error) {
    // await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
  }
});

//API for create budget for profile
export const CreateProfileBudget = createAsyncThunk('CreateProfileBudget', async (payload, { dispatch }) => {
  try {
    dispatch(setCommonLoader(true));
    const response = await apiClient().post(`/campaigns/profile-budget-create/`, payload);
    dispatch(notificationSuccess(response?.data?.message));
    dispatch(setCommonLoader(false));
    if (response?.data) {
      window.location.href = '/budget-tracker';
    }
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
  }
});

export const GetOnDemandData = createAsyncThunk(
  'GetOnDemandData',
  async ({ page, rowPerPage, changedColumn, direction, tableFilter, advertiserFilter }, { dispatch }) => {
    try {
      let changedColumnSort = (direction == 'asc' ? '-' : '') + changedColumn;
      dispatch(setCommonLoader(true));
      const response = await apiClient().get(
        `campaigns/list-on-demand-report/?page_size=${rowPerPage}&page=${page + 1}&advertiser__in=${advertiserFilter}` +
          (changedColumn ? `&ordering=${changedColumnSort}` : ``) +
          (tableFilter?.report_type?.length > 0 ? `&report_type__in=${tableFilter?.report_type}` : ``) +
          (tableFilter?.status?.length > 0 ? `&job_status__in=${tableFilter?.status}` : ``)
      );

      dispatch(setOnDemandData(response?.data));
      dispatch(notificationSuccess(response?.data?.message));
      dispatch(setCommonLoader(false));
      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
      dispatch(setCommonLoader(false));
    }
  }
);

export const CreateOnDemandData = createAsyncThunk('CreateOnDemandData', async (payload, { dispatch }) => {
  try {
    const response = await apiClient().post(`/campaigns/create-on-demand-report/`, payload);
    dispatch(notificationSuccess(response?.data?.message));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const updateMultipleBidItem = createAsyncThunk('updateMultipleItem', async (payload, { dispatch }) => {
  try {
    payload.payload1 = { ...payload.payload1, permission_request_type: 'update' };
    const response = await apiClient().put(`campaigns/aditems-bulk-update/`, payload.payload1);
    dispatch(notificationSuccess(response?.data?.message));
    // dispatch(getItemsDetails(payload?.defaultData));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const updateMultipleItemBidPercentage = createAsyncThunk(
  'updateMultipleItemBidPercentage',
  async (payload, { dispatch }) => {
    try {
      payload.payload1 = { ...payload.payload1, permission_request_type: 'update' };
      const response = await apiClient().put(`campaigns/aditems-bid-percentage/`, payload.payload1);
      dispatch(notificationSuccess(response?.data?.message));

      dispatch(getItemsDetails(payload?.defaultData));
      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
    }
  }
);

export const updateMultipleStatusItemMultiple = createAsyncThunk(
  'updateMultipleStatusItemMultiple',
  async (payload11, { dispatch }) => {
    try {
      payload11.payload = { ...payload11.payload, permission_request_type: 'update' };
      const response = await apiClient().put(`campaigns/aditems-bulk-update/`, payload11.payload);
      dispatch(notificationSuccess(response?.data?.message));

      // dispatch(getItemsDetails(payload11?.defaultData));
      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
      dispatch(setCommonLoader(false));
    }
  }
);

export const getSuggestedItems = createAsyncThunk('getSelectFromItemsFromId', async ({ advertiserId }, { dispatch }) => {
  try {
    // dispatch(setCommonLoader(true));
    const response = await apiClient().get(`/campaigns/get-suggested-items/?advertiser_id=${advertiserId}`);
    dispatch(setSelectItemData(response?.data?.data));
    // dispatch(setCommonLoader(false));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
  }
});

export const GetBudgetManagerData = createAsyncThunk(
  'GetBudgetManagerData',
  async ({ page, rowPerPage, changedColumn, direction }, { dispatch }) => {
    try {
      let changedColumnSort = (direction == 'asc' ? '-' : '') + changedColumn;
      dispatch(setCommonLoader(true));
      const response = await apiClient().get(
        `campaigns/get-profile-budget/?page_size=${rowPerPage}&page=${page + 1}` +
          (changedColumn ? `&ordering=${changedColumnSort}` : ``)
      );

      dispatch(setBudgetManagerData(response?.data));
      dispatch(notificationSuccess(response?.data?.message));
      dispatch(setCommonLoader(false));
      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
      dispatch(setCommonLoader(false));
    }
  }
);

export const deleteBudgetManagerData = createAsyncThunk('deleteBudgetManagerData', async ({ deleteData }, { dispatch }) => {
  try {
    dispatch(setCommonLoader(true));
    const response = await apiClient().delete(`campaigns/budget-update-delete/${deleteData}/?permission_request_type=delete`);
    dispatch(notificationSuccess(response?.data?.message));
    dispatch(setCommonLoader(false));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
  }
});

export const UpdateBudget = createAsyncThunk('updateCampaign', async ({ _request, existingBudgetId }, { dispatch }) => {
  try {
    _request = { ..._request, permission_request_type: 'update' };
    const response = await apiClient().put(`/campaigns/budget-update-delete/${existingBudgetId}/`, _request);
    dispatch(notificationSuccess(response?.data?.message));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const DownloadCustomReportData = createAsyncThunk('DownloadCustomReportData', async (_request, { dispatch }) => {
  try {
    console.log('CustomReuest', _request);
    dispatch(setCommonLoader(true));

    const response = await apiClient().post(`campaigns/download-custom-report/`, _request, { responseType: 'arraybuffer' });
    DownloadCsvDataCommon(response.data, 'CustomReport');
    dispatch(setCommonLoader(false));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
  }
});

export const GetSharedDashboardData = createAsyncThunk(
  'GetSharedDashboardData',
  async ({ page, rowPerPage, changedColumn, direction, tableFilter }, { dispatch }) => {
    try {
      let changedColumnSort = (direction == 'asc' ? '-' : '') + changedColumn;
      dispatch(setCommonLoader(true));

      const response = await apiClient().get(
        `campaigns/get-create-share-link/?page_size=${rowPerPage}&page=${page + 1}` +
          (changedColumn ? `&ordering=${changedColumnSort}` : ``) +
          (tableFilter.status ? `&status__in=${tableFilter.status}` : ``) +
          (tableFilter.creator ? `&user__in=${tableFilter.creator}` : ``) +
          (tableFilter.profile ? `&profile_ids__in=${tableFilter.profile}` : ``) +
          `&permission_request_type=access`
      );
      const allAdvertiserList = {
        ...response?.data,
        results: response?.data?.results.map((item) => ({
          ...item,
          profile_ids: item.profile_ids.map((profile) => ({
            ...profile,
            name: profile.nick_name // Replace 'nick_name' with 'name'
          }))
        }))
      };
      dispatch(setSharedLinkData(allAdvertiserList));
      dispatch(notificationSuccess(response?.data?.message));
      dispatch(setCommonLoader(false));
      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
      dispatch(setCommonLoader(false));
    }
  }
);

export const createDashboardLink = createAsyncThunk('createDashboardLink', async (_request, { dispatch }) => {
  try {
    dispatch(setCommonLoader(true));

    const navigationData = _request?.navigation;

    delete _request?.navigation;

    _request = { ..._request, permission_request_type: 'create' };
    const response = await apiClient().post(`/campaigns/get-create-share-link/`, _request);
    if (!response?.error) {
      dispatch(notificationSuccess(response?.data?.message));
      navigationData('/shared-dashboards');
    }
    dispatch(setCommonLoader(false));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
  }
});

export const deleteSharedLinkData = createAsyncThunk('deleteSharedLinkData', async ({ deleteData, request }, { dispatch }) => {
  if (request?.is_bulk) {
    const Request_Bulk = {
      is_deleted: true,
      link_ids: deleteData,
      permission_request_type: 'delete'
    };
    try {
      dispatch(setCommonLoader(true));
      const response = await apiClient().put(`campaigns/share-link-bulk-update/`, Request_Bulk);
      dispatch(notificationSuccess(response?.data?.message));
      dispatch(setCommonLoader(false));
      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
      dispatch(setCommonLoader(false));
    }
  } else {
    try {
      dispatch(setCommonLoader(true));
      const response = await apiClient().delete(
        `campaigns/share-link-update-delete/${deleteData}/?permission_request_type=delete`
      );
      dispatch(notificationSuccess(response?.data?.message));
      dispatch(setCommonLoader(false));
      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
      dispatch(setCommonLoader(false));
    }
  }
});

export const EditSharedLinkDate = createAsyncThunk('EditSharedLinkDate', async (value, { dispatch }) => {
  if (value?.is_bulk) {
    const request = {
      expiration_date: value.optionToSelect == 'none' ? null : value.selectedDate,
      link_ids: value?.id,
      permission_request_type: 'update'
    };

    try {
      dispatch(setCommonLoader(true));
      const response = await apiClient().put(`campaigns/share-link-bulk-update/`, request);
      dispatch(notificationSuccess(response?.data?.message));
      dispatch(setCommonLoader(false));
      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
      dispatch(setCommonLoader(false));
    }
  } else {
    const request = {
      expiration_date: value?.optionToSelect == 'none' ? null : value?.selectedDate,
      permission_request_type: 'update'
    };
    try {
      dispatch(setCommonLoader(true));
      const response = await apiClient().put(`campaigns/share-link-update-delete/${value?.id}/`, request);
      dispatch(notificationSuccess(response?.data?.message));
      dispatch(setCommonLoader(false));
      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
      dispatch(setCommonLoader(false));
    }
  }
});

export const EditSharedLinkDescription = createAsyncThunk('EditSharedLinkDate', async (value, { dispatch }) => {
  if (value?.is_bulk) {
    const request = {
      description: value.description,
      link_ids: value?.id,
      permission_request_type: 'update'
    };

    try {
      dispatch(setCommonLoader(true));
      const response = await apiClient().put(`campaigns/share-link-bulk-update/`, request);
      dispatch(notificationSuccess(response?.data?.message));
      dispatch(setCommonLoader(false));
      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
      dispatch(setCommonLoader(false));
    }
  } else {
    const request = {
      description: value.description,
      permission_request_type: 'update'
    };
    try {
      dispatch(setCommonLoader(true));
      const response = await apiClient().put(`campaigns/share-link-update-delete/${value?.id}/`, request);
      dispatch(notificationSuccess(response?.data?.message));
      dispatch(setCommonLoader(false));
      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
      dispatch(setCommonLoader(false));
    }
  }
});

export const EditSharedLinkProfile = createAsyncThunk('EditSharedLinkDate', async ({ Ids, mainId }, { dispatch }) => {
  const request = {
    profile_ids: Ids,
    permission_request_type: 'update'
  };
  try {
    dispatch(setCommonLoader(true));
    const response = await apiClient().put(`campaigns/share-link-update-delete/${mainId}/`, request);
    dispatch(notificationSuccess(response?.data?.message));
    dispatch(setCommonLoader(false));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
  }
});

export const GetCreatorData = createAsyncThunk('GetCreatorData', async (request, { dispatch }) => {
  console.log('request ', request);
  try {
    dispatch(setCommonLoader(true));
    const response = await apiClient().get(`campaigns/share-link-creator-list/`);
    dispatch(setCreatorData(response?.data?.data));
    dispatch(setCommonLoader(false));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
  }
});

export const GetTopProductData = createAsyncThunk('GetTopProductData', async (_request, { dispatch }) => {
  let request = {
    advertiser_id: _request?.advertiserFilter,
    start_date: _request?.commonDateRange?.startDate,
    end_date: _request?.commonDateRange?.endDate
  };

  if (_request?.secretKey) {
    request = {
      ...request,
      secret_key: _request?.secretKey
    };
  }

  try {
    const response = await apiClient().post(
      `campaigns/top-product-stats/?page=${_request?.page + 1}` +
        (_request?.changedColumn ? `&ordering=-${_request?.changedColumn}` : ``),
      request
    );

    dispatch(setTopProductData(response?.data?.data));
    // dispatch(notificationSuccess(response?.data?.message));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const GetPerformanceComparisonData = createAsyncThunk('GetPerformanceComparisonData', async (_request, { dispatch }) => {
  let request = {
    advertiser_id: _request?.advertiserFilter,
    type: _request?.dropdownValue
  };

  if (_request?.secretKey) {
    request = {
      ...request,
      secret_key: _request?.secretKey
    };
  }

  try {
    const response = await apiClient().post(`campaigns/performance-comparison-stats/`, request);

    dispatch(setPerformanceComparisonData(response?.data?.data));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const GetAdPerformancechartDetails = createAsyncThunk('GetAdPerformancechartDetails', async (_request, { dispatch }) => {
  let request = {
    advertiser_id: _request?.advertiserFilter,
    start_date: _request?.commonDateRange?.startDate,
    end_date: _request?.commonDateRange?.endDate,
    type: _request?.chartType,
    is_comparison: _request?.isComparingDates
  };

  if (_request?.isComparingDates) {
    request = {
      ...request,
      cr_start_date: _request?.commonDateRange?.compareStartDate,
      cr_end_date: _request?.commonDateRange?.compareEndDate
    };
  }

  if (_request?.secretKey) {
    request = {
      ...request,
      secret_key: _request?.secretKey
    };
  }

  try {
    const response = await apiClient().post(`campaigns/performance-card-stats/`, request);

    let totalData = response?.data?.data?.total_data;

    let ommitNames = ['num_ads_clicks', 'advertised_sku_sales_14days'];
    totalData = totalData?.filter((l) => !ommitNames.includes(l?.name));

    const newResponse = {
      ...response?.data?.data,
      total_data: totalData
    };

    dispatch(setAdPerformanceData(newResponse));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const GetEngagementDetails = createAsyncThunk('GetEngagementDetails', async (_request, { dispatch }) => {
  let request = {
    advertiser_id: _request?.advertiserFilter,
    start_date: _request?.commonDateRange?.startDate,
    end_date: _request?.commonDateRange?.endDate,
    type: _request?.chartType,
    is_comparison: _request?.isComparingDates
  };

  if (_request?.isComparingDates) {
    request = {
      ...request,
      cr_start_date: _request?.commonDateRange?.compareStartDate,
      cr_end_date: _request?.commonDateRange?.compareEndDate
    };
  }

  if (_request?.secretKey) {
    request = {
      ...request,
      secret_key: _request?.secretKey
    };
  }

  try {
    const response = await apiClient().post(`campaigns/engagement-card-stats/`, request);

    let totalData = response?.data?.data?.total_data;

    let ommitNames = ['units_sold_14days', 'attributed_orders_14days'];
    totalData = totalData?.filter((l) => !ommitNames.includes(l?.name));

    const newResponse = {
      ...response?.data?.data,
      total_data: totalData
    };

    dispatch(setEngagementData(newResponse));
    // dispatch(notificationSuccess(response?.data?.message));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const VerifyShareDashboardLink = createAsyncThunk('VerifyShareDashboardLink', async ({ secretKey }, { dispatch }) => {
  try {
    // dispatch(setCommonLoader(true));
    const response = await apiClient().get(`campaigns/share-link-verify/?secret_key=${secretKey}`);
    // dispatch(setIsDashboardLinkVerified(response?.data));
    // dispatch(setCommonLoader(false));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

//Create New Walmart Store
export const createWalmartStore = createAsyncThunk('createWalmartStore', async (request, { dispatch }) => {
  const {
    client_id,
    client_secret,
    seller_type,
    nick_name,
    seller_id,
    supplier_id,
    distributor_id,
    partner_id,
    display_module_fe,
    navigate,
    addNewStore,
    addNewStoreExternal,
    secretKey,
    otherProps,
    setGoNextToAddAdvertiser,
    setLoading,
    setBeError
  } = request;
  try {
    const response = await apiClient().post(`advertisers/create-store/`, {
      nick_name: nick_name,
      ...(client_id && { client_id: client_id }),
      ...(client_secret && { client_secret: client_secret }),
      ...(addNewStoreExternal && { secret_key: secretKey }),
      seller_type: seller_type,
      seller_id: seller_id,
      supplier_id: supplier_id,
      partner_id: partner_id,
      distributor_id: distributor_id,
      display_module_fe: display_module_fe
    });
    const storeId = response?.data?.data?.store_id;

    if (!addNewStoreExternal) {
      const request = {
        storeId
      };
      dispatch(GetSelectedStoreData(request)); //Call API for fetching selected store details
    }

    setBeError('');
    dispatch(notificationSuccess(response?.data?.message));
    setLoading(false);

    //Update Store Id also in the Store as well as localstorage
    const restdata = { ...JSON.parse(localStorage.getItem('user_data')), display_module_fe: 'add-advertiser', store_id: storeId };
    dispatch(setUserData(restdata));
    localStorage.setItem('user_data', JSON.stringify(restdata));

    if (addNewStore || addNewStoreExternal) {
      //While Adding New Store From Store Page
      setGoNextToAddAdvertiser(true);
      dispatch(GetAdvertisersStoreData(otherProps?.pageProps));
    } else {
      //While First time Verification Process
      navigate('/add-advertiser');
    }
  } catch (error) {
    setLoading(false);
    setBeError(error?.response?.data?.message || Messages.ERROR.DEFAULT);
  }
});

//Create Walmart Advertiser
export const createWalmartAdvertiser = createAsyncThunk('createWalmartAdvertiser', async (request, { dispatch }) => {
  const {
    nick_name,
    advertiser_id,
    store_id,
    addNewStore,
    addNewStoreExternal,
    secretKey,
    setIsOpenModalAddStore,
    navigate,
    setLoading,
    setBeError
  } = request;
  try {
    const requestParams = {
      nick_name: nick_name,
      advertiser_id: advertiser_id,
      store_id: store_id,
      ...(addNewStoreExternal && { secret_key: secretKey }),
      permission_request_type: 'create'
    };
    const response = await apiClient().post(`advertisers/list-create-advertiser/`, requestParams);
    setBeError('');
    dispatch(notificationSuccess(response?.data?.message));
    const restdata = { ...JSON.parse(localStorage.getItem('user_data')), display_module_fe: '', is_first_time_login: false };
    localStorage.setItem('user_data', JSON.stringify(restdata));
    dispatch(setUserData(restdata));

    if (!addNewStoreExternal) {
      dispatch(getAlldvertiserList({}));
    }

    setLoading(false);

    if (addNewStore) {
      //While Adding New Store From Store Page
      setIsOpenModalAddStore(false);
    } else {
      //While First time Verification Process
      navigate('/');
    }
  } catch (error) {
    setLoading(false);
    setBeError(error?.response?.data?.message || Messages.ERROR.DEFAULT);
  }
});

//Update Walmart Advertiser
export const updateWalmartAdvertiser = createAsyncThunk('updateWalmartAdvertiser', async (request, { dispatch }) => {
  const { nick_name, advertiser_id, store_id, editStore, setIsOpenModalEditStore, navigate, setLoading, setBeError } = request;
  try {
    const requestParams = {
      nick_name: nick_name,
      advertiser_id: advertiser_id,
      store_id: store_id,
      permission_request_type: 'update'
    };
    const response = await apiClient().put(`advertisers/ad-details/${advertiser_id}/`, requestParams);
    setBeError('');
    dispatch(notificationSuccess(response?.data?.message || response?.message));

    const restdata = { ...JSON.parse(localStorage.getItem('user_data')), display_module_fe: '', is_first_time_login: false };
    localStorage.setItem('user_data', JSON.stringify(restdata));
    dispatch(setUserData(restdata));

    setLoading(false);

    if (editStore) {
      dispatch(getAlldvertiserList({})); //Refresh List because we take advertiser details from this
      //While Update Store From Store Page
      setIsOpenModalEditStore(false);
    } else {
      //While First time Verification Process
      navigate('/');
    }
  } catch (error) {
    setLoading(false);
    setBeError(error?.response?.data?.message || Messages.ERROR.DEFAULT);
  }
});

//Get Stores with Pagination
export const GetAdvertisersStoreData = createAsyncThunk(
  'GetAdvertisersStoreData',
  async ({ page, rowPerPage, changedColumn, direction, tableFilter }, { dispatch }) => {
    try {
      let changedColumnSort = (direction == 'asc' ? '-' : '') + changedColumn;
      dispatch(setCommonLoader(true));
      const response = await apiClient().get(
        `advertisers/store-list/?page_size=${rowPerPage}&page=${page + 1}` +
          (tableFilter?.partner_name ? `&partner_name__in=${tableFilter?.partner_name}` : ``) +
          (tableFilter?.partner_display_name ? `&partner_display_name__in=${tableFilter?.partner_display_name}` : ``) +
          (tableFilter?.nick_name ? `&nick_name__in=${tableFilter.nick_name}` : ``) +
          (tableFilter?.seller_type ? `&seller_type__in=${tableFilter.seller_type}` : ``) +
          (tableFilter?.partner_id ? `&partner_id__in=${tableFilter.partner_id}` : ``) +
          (changedColumn ? `&ordering=${changedColumnSort}` : ``)
      );

      dispatch(setStorAdData(response?.data?.data));
      // dispatch(notificationSuccess(response?.data?.message));
      dispatch(setCommonLoader(false));
      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
      dispatch(setCommonLoader(false));
    }
  }
);

//Get Selected Store Data
export const GetSelectedStoreData = createAsyncThunk('GetSelectedStoreData', async ({ storeId }, { dispatch }) => {
  try {
    dispatch(setCommonLoader(true));
    const response = await apiClient().get(`advertisers/update-store/${storeId}/`);

    dispatch(setSelectedStoreData(response?.data?.data));
    dispatch(setCommonLoader(false));
    // return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
  }
});

//Update Selected Store
export const updateWalmartStore = createAsyncThunk('updateWalmartStore', async (request, { dispatch }) => {
  const {
    storeId,
    client_id,
    client_secret,
    seller_type,
    nick_name,
    seller_id,
    supplier_id,
    distributor_id,
    partner_id,
    display_module_fe,
    setIsOpenModalEditStore,
    addNewStore,
    addNewStoreExternal,
    editStore,
    firstTime,
    otherProps,
    setGoNextToAddAdvertiser,
    setLoading,
    setBeError
  } = request;
  try {
    const response = await apiClient().put(`advertisers/update-store/${storeId}/`, {
      nick_name: nick_name,
      ...(client_id && { client_id }),
      ...(client_secret && { client_secret }),
      seller_type: seller_type,
      ...(seller_type === '3p' && { seller_id }),
      ...(seller_type === '1p' && { supplier_id, distributor_id }),
      partner_id: partner_id,
      display_module_fe: display_module_fe
    });

    setBeError('');
    dispatch(notificationSuccess(response?.data?.message || response?.message));
    setLoading(false);

    //Update Store Id also in the Store as well as localstorage
    const restdata = { ...JSON.parse(localStorage.getItem('user_data')), display_module_fe: 'add-advertiser', store_id: storeId };
    dispatch(setUserData(restdata));
    localStorage.setItem('user_data', JSON.stringify(restdata));

    if (addNewStore || editStore || firstTime || addNewStoreExternal) {
      setGoNextToAddAdvertiser(true);
      dispatch(GetAdvertisersStoreData(otherProps?.pageProps));
    } else {
      setIsOpenModalEditStore(false);
    }
  } catch (error) {
    setLoading(false);
    setBeError(error?.response?.data?.message || Messages.ERROR.DEFAULT);
  }
});

//Delete Selected Store
export const deleteStore = createAsyncThunk('deleteStore', async ({ storeId }, { dispatch }) => {
  try {
    dispatch(setCommonLoader(true));
    const response = await apiClient().delete(`advertisers/delete-store/${storeId}/`);
    if (response?.status === 204) {
      dispatch(notificationSuccess('Store Deleted Successfully'));
      dispatch(setSelectedStoreData({}));
    }
    dispatch(setCommonLoader(false));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
    dispatch(setCommonLoader(false));
  }
});

//Fetching All Store
export const AllStoresData = createAsyncThunk('AllStoresData', async (_, { dispatch }) => {
  try {
    const response = await apiClient().get(`advertisers/all-stores/`);
    dispatch(setAllStoreData(response?.data?.data));
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const getItemDetailsForKeywordTracking = createAsyncThunk(
  'getItemDetailsForKeywordTracking',
  async ({ id, setLoader }, { dispatch }) => {
    try {
      // dispatch(setCommonLoader(true));
      setLoader(true);
      const response = await apiClient().get(`/marketplace/marketplace-items-list/?advertiser_id=${id}`);
      dispatch(setSelectItemKeywordTracking(response?.data?.data));
      setLoader(false);

      return response;
    } catch (error) {
      setLoader(false);
      await printNotificationFailMsg(error, dispatch);
    }
  }
);

export const getSuggestedDetailsForKeywordTracking = createAsyncThunk(
  'getSuggestedDetailsForKeywordTracking',
  async ({ id, itemId, setLoaderTable }, { dispatch }) => {
    try {
      setLoaderTable(true);
      const response = await apiClient().get(`/marketplace/search-keyword-advertiser/?advertiser_id=${id}&item_id=${itemId}`);
      dispatch(setSelectSuggestedKeywordTracking(response?.data?.data));
      console.log('response?.data?.data ', response?.data?.data);

      setLoaderTable(false);
      return response;
    } catch (error) {
      setLoaderTable(false);
      await printNotificationFailMsg(error, dispatch);
    }
  }
);

export const CreateKeywordRunTracker = createAsyncThunk(
  'CreateKeywordRunTracker',
  async ({ request, OnClose, refreshListOnceUploaded }, { dispatch }) => {
    try {
      dispatch(setCommonLoader(true));
      const response = await apiClient().post(`/advertisers/create-keyword-item-master/`, request);
      dispatch(notificationSuccess(response?.data?.message));
      refreshListOnceUploaded();
      OnClose();
      dispatch(setCommonLoader(false));
      return response;
    } catch (error) {
      dispatch(setCommonLoader(false));
      await printNotificationFailMsg(error, dispatch);
    }
  }
);

export const updateLastSelectedAdvertiser = createAsyncThunk('updateLastSelectedAdvertiser', async (payload, { dispatch }) => {
  try {
    const response = await apiClient().put(`accounts/last-accessed-advertiser/`, payload);
    return response;
  } catch (error) {
    await printNotificationFailMsg(error, dispatch);
  }
});

export const getRankTrackerDetails = createAsyncThunk(
  'getRankTrackerDetails',
  async (
    { page, rowPerPage, changedColumn, direction, tableFilter, PerformanceFilter, commonAdvertiserList, rankType, is_csv },
    { dispatch }
  ) => {
    try {
      const buildFilterParams = (filterVal) => {
        const senData = `&${filterVal}=${PerformanceFilter[filterVal].min || ''},${PerformanceFilter[filterVal].max || ''}`;
        return senData;
      };

      dispatch(setCommonLoader(true));
      let changedColumnSort = (direction == 'asc' ? '-' : '') + changedColumn;
      const response = await apiClient().get(
        `advertisers/get-item-keyword-listing/?page_size=${rowPerPage}&page=${page + 1}&rank_type=${rankType}` +
          (commonAdvertiserList?.length ? `&advertiser_id__in=${commonAdvertiserList.join(',')}` : ``) +
          (is_csv ? `&is_csv_download=${is_csv}` : ``) +
          (changedColumn ? `&ordering=${changedColumnSort}` : ``) +
          (tableFilter?.item__in ? `&item__in=${tableFilter?.item__in}` : ``) +
          (tableFilter?.search_term__in ? `&search_term__in=${tableFilter?.search_term__in}` : ``) +
          (PerformanceFilter ? Object?.keys(PerformanceFilter).map((filter) => buildFilterParams(filter)) : ``)
      );
      if (!is_csv) {
        dispatch(setRankTrackerDetails(response?.data));
        dispatch(setCommonLoader(false));
        // dispatch(notificationSuccess(response?.data?.message));
      } else {
        dispatch(setRankTrackerDetailsForCSV(response?.data));
        dispatch(setCommonLoader(false));
      }

      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
      dispatch(setCommonLoader(false));
    }
  }
);

export const getItemKeywordChart = createAsyncThunk('getItemKeywordChart', async (request, { dispatch }) => {
  try {
    dispatch(setCommonLoader(true));
    const response = await apiClient().post(`advertisers/get-item-keyword-chart/`, request);
    dispatch(setSelectedRankTrackerDetails(response?.data));
    dispatch(setCommonLoader(false));
    return response;
  } catch (error) {
    console.log(error?.response?.data?.message || Messages.ERROR.LOGIN);
  }
});

//Delete  Item Keyword in Rank Tracker
export const deleteItemKeywordRankTracker = createAsyncThunk(
  'deleteItemKeywordRankTracker',
  async ({ rankTrackerID }, { dispatch }) => {
    try {
      dispatch(setCommonLoader(true));
      const response = await apiClient().delete(`advertisers/delete-keyword/${rankTrackerID}/`);
      if (response?.status === 204) {
        dispatch(notificationSuccess('Item Deleted Successfully'));
      }
      dispatch(setCommonLoader(false));
      return response;
    } catch (error) {
      await printNotificationFailMsg(error, dispatch);
      dispatch(setCommonLoader(false));
    }
  }
);

export const VerifyStoreLinkExternal = createAsyncThunk('VerifyStoreLinkExternal', async (request) => {
  const { sKey, setResponce } = request;
  try {
    const response = await apiClient().get(`advertisers/verify-store-share-link/?secret_key=${sKey}`);
    setResponce(response?.data?.error);
    return response;
  } catch (error) {
    setResponce(error?.response?.data?.error);
    console.log(error?.response?.data?.message || Messages.ERROR.LOGIN);
  }
});

//Generate Access and Refresh token when we have callbackurl after walmart login
export const createStoreWithOAuth = createAsyncThunk('createStoreWithOAuth', async (request, { dispatch }) => {
  const {
    authorization_code,
    nick_name,
    seller_type,
    seller_id,
    supplier_id,
    distributor_id,
    secretKey,
    addNewStoreExternal,
    partner_id,
    partner_type,
    setResponce
  } = request;
  try {
    const response = await apiClient().post(`advertisers/create-store-with-oauth/`, {
      authorization_code: authorization_code,
      nick_name: nick_name,
      seller_type: seller_type,
      ...(seller_type === '1p' && { supplier_id: supplier_id }),
      ...(seller_type === '1p' && { distributor_id: distributor_id }),
      ...(seller_type === '3p' && { seller_id: seller_id }),
      ...(addNewStoreExternal && { secret_key: secretKey }),
      partner_id: partner_id,
      partner_type: partner_type
    });

    const storeId = response?.data?.data?.id;

    if (!addNewStoreExternal) {
      const request = {
        storeId
      };
      dispatch(GetSelectedStoreData(request)); //Call API for fetching selected store details
    }

    //Update Store Id also in the Store as well as localstorage
    const restdata = { ...JSON.parse(localStorage.getItem('user_data')), store_id: storeId };
    dispatch(setUserData(restdata));
    localStorage.setItem('user_data', JSON.stringify(restdata));

    const resp = {
      error: response?.data?.error,
      message: response?.data?.message
    };
    setResponce(resp);
    return response;
  } catch (error) {
    const resp = {
      error: error?.response?.data?.error,
      message: error?.response?.data?.message
    };
    setResponce(resp);
    console.log(error?.response?.data?.message || Messages.ERROR.LOGIN);
  }
});

export const updateStoreWithOAuth = createAsyncThunk('updateStoreWithOAuth', async (request) => {
  const {
    authorization_code,
    nick_name,
    seller_type,
    seller_id,
    supplier_id,
    distributor_id,
    partner_id,
    partner_type,
    setResponce,
    is_client_id_and_secret,
    storeId
  } = request;
  try {
    const response = await apiClient().put(`advertisers/update-store-with-oauth/${storeId}/`, {
      authorization_code: authorization_code,
      nick_name: nick_name,
      seller_type: seller_type,
      ...(seller_type === '1p' && { supplier_id: supplier_id }),
      ...(seller_type === '1p' && { distributor_id: distributor_id }),
      ...(seller_type === '3p' && { seller_id: seller_id }),
      partner_id: partner_id,
      partner_type: partner_type,
      is_client_id_and_secret: is_client_id_and_secret
    });
    const resp = {
      error: response?.data?.error,
      message: response?.data?.message
    };
    setResponce(resp);
    return response;
  } catch (error) {
    const resp = {
      error: error?.response?.data?.error,
      message: error?.response?.data?.message
    };
    setResponce(resp);
    console.log(error?.response?.data?.message || Messages.ERROR.LOGIN);
  }
});

//Update OAuth using the External share link
export const updateStoreOAuthExternalLink = createAsyncThunk('updateStoreOAuth', async (request) => {
  const { authorization_code, tenant_id, store_id, setResponceOAuthUpdate } = request;
  try {
    const response = await apiClient().post(`advertisers/update-store-with-oauth-external-link/`, {
      authorization_code: authorization_code,
      tenant_id: tenant_id,
      store_id: store_id
    });
    const resp = {
      error: response?.data?.error,
      message: response?.data?.message
    };
    setResponceOAuthUpdate(resp);
    return response;
  } catch (error) {
    const resp = {
      error: error?.response?.data?.error,
      message: error?.response?.data?.message
    };
    setResponceOAuthUpdate(resp);
    console.log(error?.response?.data?.message || Messages.ERROR.LOGIN);
  }
});
