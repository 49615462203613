import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import reducer from './reducer';
import sentryMiddleware from './sentryMiddleware';
// ==============================|| REDUX - MAIN STORE ||============================== //

const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sentryMiddleware)
});
const persister = 'Free';

const useAppDispatch = () => useDispatch();
const useAppSelector = useSelector;

export { store, persister, useAppDispatch, useAppSelector };
