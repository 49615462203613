import { TextField } from '@mui/material';
import OutlinedDiv from './Outline';
import { Box } from '@mui/system';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { debounce } from 'lodash';
import colors from 'assets/scss/_themes-vars.module.scss';
import { CURRENT_RANK_MINIMUM_VALUE } from 'store/constant';

const FilterPerformance = (props) => {
  const [showErrorCurrentRank, setShowErrorCurrentRank] = useState(false);
  const { performanceItem, handleInputChange, isReset } = props;

  const [min, setMin] = useState();
  const [max, setMax] = useState();

  const handleKeyPress = (e) => {
    if (e.key === '-' || e.key === 'e') {
      e.preventDefault();
    }
  };

  useMemo(() => {
    if (!isReset) {
      setMin(null);
      setMax(null);
    }
  }, [isReset]);

  const checkCurrentRankMinValue = (minVal) => {
    const input = performanceItem?.value;
    return input === 'current_rank' && minVal > CURRENT_RANK_MINIMUM_VALUE;
  };

  useEffect(() => {
    const status = checkCurrentRankMinValue(min);
    setShowErrorCurrentRank(status);
  }, [min]);

  // Debounced handleInputChange
  const debouncedHandleInputChange = useCallback(
    debounce((field, type, value, error) => {
      handleInputChange(field, type, value, error);
    }, 500), // Adjust debounce duration
    [handleInputChange]
  );

  const handleMinChange = (e) => {
    const value = e.target.value;
    setMin(value);
    debouncedHandleInputChange(
      performanceItem.value,
      'min',
      value || '',
      (value?.length > 0 && max?.length > 0 && Number(value) > Number(max)) || checkCurrentRankMinValue(value)
    );
  };

  const handleMaxChange = (e) => {
    const value = e.target.value;
    setMax(value);
    debouncedHandleInputChange(
      performanceItem.value,
      'max',
      value || '',
      min?.length > 0 && value?.length > 0 && Number(min) > Number(value)
    );
  };

  return (
    <div key={performanceItem?.id} className="performance-wrapper">
      <OutlinedDiv
        showErrorCurrentRank={showErrorCurrentRank}
        showError={min?.length > 0 && max?.length > 0 && Number(min) > Number(max)}
        label={performanceItem?.name}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            type="number"
            sx={{
              '& fieldset': { border: 'none' },
              '& input::placeholder': {
                color: colors?.grey500,
                opacity: 1 // For Firefox
              },
              '& input::-ms-input-placeholder': {
                color: colors?.grey500 // For Edge
              }
            }}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*' // Allow only digits
            }}
            value={min || ''}
            id="minPrice"
            placeholder="Min"
            size="small"
            onKeyPress={handleKeyPress}
            disableFocusRipple
            onChange={handleMinChange}
            style={{ width: '45%', border: 'none', borderRadius: '5px', padding: '0px', outline: 'none', fontSize: '12px' }}
          />
          ~
          <TextField
            type="number"
            id="maxPrice"
            value={max || ''}
            sx={{
              '& fieldset': { border: 'none' },
              '& input::placeholder': {
                color: colors?.grey500,
                opacity: 1 // For Firefox
              },
              '& input::-ms-input-placeholder': {
                color: colors?.grey500 // For Edge
              },
              padding: '2.5px 4px 2.5px 8px !important'
            }}
            size="small"
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*' // Allow only digits
            }}
            disableFocusRipple
            onKeyPress={handleKeyPress}
            onChange={handleMaxChange}
            placeholder="Max"
            style={{ width: '45%', border: 'none', borderRadius: '5px', padding: '2px', outline: 'none', fontSize: '12px' }}
          />
        </Box>
      </OutlinedDiv>
    </div>
  );
};

export default FilterPerformance;
