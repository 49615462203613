import { useState, useEffect } from 'react';
import routes from './routes';
import { useAppSelector } from 'store';

//Function that remove particular url route from given routes array
const removeMenuRoutes = (items, urlToRemove) => {
  if (Array.isArray(items)) {
    return items
      .map((item) => {
        if (item.children) {
          // Recursively call removeMenuRoutes on the children
          item.children = removeMenuRoutes(item.children, urlToRemove);
        }
        return item.url != urlToRemove ? item : null;
      })
      .filter(Boolean); // Filter out any null values
  }
  return items;
};

export default function useMenuItems() {
  const [menuItems, setMenuItems] = useState({});
  const { userData } = useAppSelector((state) => state.authorization);

  function removeEmptyChildren(obj) {
    if (Array.isArray(obj.children)) {
      // Filter out any children that have an empty children array
      obj.children = obj.children
        .filter((child) => !(Array.isArray(child.children) && child.children.length === 0))
        .map((child) => removeEmptyChildren(child)); // Recursively process each child
    }
    return obj;
  }

  function cleanItems(data) {
    if (Array.isArray(data.items)) {
      data.items = data.items.map((item) => removeEmptyChildren(item));
    }
    return data;
  }

  useEffect(() => {
    if (!userData) return;
    //Permission checking
    const bulkOperation = userData?.permission?.bulk_operation || {};
    const bulkOperationAccess = bulkOperation?.access;
    const user = userData?.permission?.user || {};
    const userAccess = user?.access;
    const advertiser = userData?.permission?.advertiser || {};
    const advertiserAccess = advertiser?.access;
    const store = userData?.permission?.store || {};
    const storeAccess = store?.access;
    const ppcRules = userData?.permission?.ppc_rules || {};
    const ppcRulesAccess = ppcRules?.access;
    const budgetManager = userData?.permission?.budget_manager || {};
    const budgetManagerAccess = budgetManager?.access;
    const sharedDashboard = userData?.permission?.shared_dashboard || {};
    const sharedDashboardAccess = sharedDashboard?.access;

    const allMenuItems = {
      items: [routes?.routes, routes?.settingsRoutes]
    };

    let newMenuItems = {
      ...allMenuItems
    };

    //Remove Route link based on permission
    if (!bulkOperationAccess) {
      newMenuItems = {
        ...newMenuItems,
        items: removeMenuRoutes(allMenuItems.items, '/bulk-operations')
      };
    }
    if (!userAccess) {
      newMenuItems = {
        ...newMenuItems,
        items: removeMenuRoutes(allMenuItems.items, '/user-management')
      };
    }
    if (!advertiserAccess) {
      newMenuItems = {
        ...newMenuItems,
        items: removeMenuRoutes(allMenuItems.items, '/advertiser')
      };
    }
    if (!storeAccess) {
      newMenuItems = {
        ...newMenuItems,
        items: removeMenuRoutes(allMenuItems.items, '/store')
      };
    }
    if (!ppcRulesAccess) {
      newMenuItems = {
        ...newMenuItems,
        items: removeMenuRoutes(allMenuItems.items, '/ppc-rules')
      };
    }
    if (!budgetManagerAccess) {
      newMenuItems = {
        ...newMenuItems,
        items: removeMenuRoutes(allMenuItems.items, '/budget-manager')
      };
    }
    if (!sharedDashboardAccess) {
      newMenuItems = {
        ...newMenuItems,
        items: removeMenuRoutes(allMenuItems.items, '/shared-dashboards')
      };
    }

    //Remove Entire menu if it has no sub menus
    const filterData = cleanItems(newMenuItems);

    setMenuItems(filterData);
  }, [userData]);

  return menuItems;
}
