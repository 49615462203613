import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { setLoading } from 'store/slices/dataLoadingSlice';
import { setAllFilterData, setCustomReportData, setCustomReportDataDownload } from 'store/slices/reportDataSlice';
import { notificationFail } from 'store/slices/notificationSlice';
import moment from 'moment';

//Print Catched Failed Notification
const printNotificationFailMsg = async (error, dispatch) => {
  let errorMessage;
  if (error instanceof ReferenceError) {
    errorMessage = error.message; // Prints the specific error message
  } else if (error?.response?.data?.message) {
    errorMessage = error.response.data.message;
  } else {
    errorMessage = Messages.ERROR.LOGIN;
  }
  await dispatch(notificationFail(errorMessage));
};

export const getCustomReortData = createAsyncThunk('getCustomReortData', async (_request, { dispatch }) => {
  try {
    // console.log("_requestfilter_by ", _request?.mainData?.filter_by);
    dispatch(setLoading(true));
    // const url = `campaigns/custom-report?start_date=${_request?.mainData?.startData}&end_date=${_request?.mainData?.endData}&split=${_request?.mainData?.split}`;
    const url = `campaigns/custom-report/`;
    const mainRequest = {
      start_date: _request?.mainData?.startData,
      end_date: _request?.mainData?.endData,
      split: _request?.mainData?.split,
      report_type: _request?.mainData?.report_type,
      is_csv: true
    };

    if (_request?.mainData?.filter_by && Object.keys(_request?.mainData?.filter_by).length > 0) {
      mainRequest.filter_by = _request?.mainData?.filter_by;
    }
    if (_request?.mainData?.advertiser_data && _request?.mainData?.advertiser_data.length > 0) {
      mainRequest.advertiser__in = _request?.mainData?.advertiser_data;
    }

    const response = await apiClient().post(url, mainRequest);
    dispatch(setLoading(false));

    if (response?.data?.data) {
      dispatch(setCustomReportData(response?.data?.data));
      dispatch(setCustomReportDataDownload(response?.data?.data?.csv_data));
    } else {
      await printNotificationFailMsg(Messages.ERROR.DEFAULT, dispatch);
    }
  } catch (error) {
    dispatch(setLoading(false));
    await printNotificationFailMsg(error, dispatch);
  }
});

export const getAllDataDimensions = createAsyncThunk('getAllDataDimensions', async (_request, { dispatch }) => {
  try {
    dispatch(setLoading(true));
    const filterData = [_request?.fetchId];
    const sDate = moment(_request?.start_date).format('YYYY-MM-DD');
    const eDate = moment(_request?.end_date).format('YYYY-MM-DD');
    const url = `campaigns/custom-report-filter/`;
    const response = await apiClient().post(url, { start_date: sDate, end_date: eDate, filters: filterData });
    dispatch(setLoading(false));
    if (response?.data) {
      dispatch(setAllFilterData(response?.data?.data));
    } else {
      dispatch(setAllFilterData(response?.data?.data));
      await printNotificationFailMsg(Messages.ERROR.DEFAULT, dispatch);
    }
  } catch (error) {
    dispatch(setLoading(false));
    await printNotificationFailMsg(error, dispatch);
  }
});
