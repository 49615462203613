import React, { useMemo } from 'react';
import MUIDataTable from 'mui-datatables';
import MainCard from 'ui-component/cards/MainCard';
import DisabledIcon from '../../assets/images/dashna/Disabled-cirlce.svg';
import EnabledIcon from '../../assets/images/dashna/enabled.svg';
import viewcolumnsIcon from 'assets/images/dashna/viewcolumns.svg';
import DownloadIcon from 'assets/images/dashna/Arrow.svg';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  Menu,
  TableFooter,
  TableRow,
  TableCell,
  Link
} from '@mui/material';
import { Add } from '@mui/icons-material';
// import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';
import { useAppDispatch } from 'store';
import { useEffect, useRef } from 'react';
import { useAppSelector } from 'store';
// import { useCallback } from 'react';
// import { debounce } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import {
  getAdGroupList,
  getAdgroupDetails,
  // getAlldvertiserList,
  getCampaignNameList,
  getSelectedFilterList,
  updateAdGroupModal
} from 'store/thunk/userThunk';
import { setAdgroupDetails } from 'store/slices/userDetailsSlice';
import CustomAutocomplete from '../../ui-component/multi-select/CustomAutocomplete';
import { useNavigate, useParams } from 'react-router';
import { setExistingAdgroupId, setSkeletonLoader } from 'store/slices/userDetailsSlice';
import { Loader } from 'rsuite';
// import SearchIcon from '@mui/icons-material/Search';
import { CAMPAIGN } from 'constant/campaignConstant';
import { alignRightColumn, formateValue, getUpdatedColumns, commaStringToNumber } from 'helper/commonHelper';
import CustomFilters from 'ui-component/Filters';
import FilterPerformance from 'views/filterPerformance';
import BasicPopover from 'ui-component/popover';
import { PageValue, adGroup_state, attributeWindowList, sortByList } from 'store/constant';
import searchIcon from 'assets/images/dashna/search.svg';
import editIcon from 'assets/images/dashna/edit.svg';
import actionIcon from 'assets/images/dashna/Actions.svg';
import CustomTableHead from 'ui-component/customTableHead';
import TableSkeleton from 'ui-component/skeleton/TableSkeleton';

//====== Colors ======
import colors from 'assets/scss/_themes-vars.module.scss';

import CustomColumnSelector from 'ui-component/CustomColumnSelector';
import EditCampaignVideoSetting from 'views/campaign/editVideo';
import EditCampaignBrandSetting from 'views/campaign/editBrand/editCampaignSetting';
import TargetAdGroupSetting from 'views/campaign/editProduct/editKeywordItems';
import ProductAdGroupSetting from 'views/campaign/editProduct/editAdgroupItems';
import EditCampaignSetting from 'views/campaign/editProduct/editCampaignSetting';
import CompareValueWithColor from 'ui-component/compareWithValue';
import plusSimpleIcon from 'assets/images/dashna/plusSimple.svg';

const Adgroup = () => {
  const dispatch = useAppDispatch();
  const selectedProfiles = localStorage.getItem('selectedProfiles');

  const {
    adgroupDetails,
    adgroupDetailsTotal,
    commonAdvertiser,
    campaignNameList,
    commonDateRange,
    filteredData,
    adGroupShortList,
    skeletonLoader,
    isComparingDates
  } = useAppSelector((state) => state?.userDetailsSlice);
  const { userData } = useAppSelector((state) => state.authorization);

  //Prevent Un-necessary loading when no profile changed
  const previousAdvertiser = useRef(commonAdvertiser);
  const isFirstRender = useRef(true);
  const shouldApplyFilter = useRef(true);

  const params = useParams();
  const navigate = useNavigate();

  const [rowPerPage, setRowPerPage] = useState(25);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [isCsvDownloadComplete, setIsCsvDownloadComplete] = useState(false);
  const [filterValue, setFilterValue] = useState({
    campaign__in: [],
    adGroup_state: [],
    campaign_status: [],
    campaign_type: [],
    ad_group: [],
    attr_windows: []
  });
  const [tableFilter, setTableFilter] = useState({
    campaign__in: '',
    adGroup_state: '',
    campaign_status: '',
    campaign_type: '',
    ad_group: '',
    attr_windows: ''
  });
  const [filterForSearch, setFilterForSearch] = useState({});
  //For Select All Checkbox
  const [isSelectAll, setIsSelectAll] = useState({
    campaign_search: [],
    campaign_type: [],
    status: [],
    ad_group_search: [],
    ad_group_status: []
  });
  const [selectedSortBy, setSelectedSortBy] = useState('');
  const [filterDataList, setFilterDataList] = useState({});
  const [searchCampaign, setSearchCampaign] = useState('');
  const [performanceRanges, setPerformanceRanges] = useState({}); //when we change performance input
  const [PerformanceFilterData, setPerformanceFilterData] = useState(); //When we click on Search button
  const [loading, setLoading] = useState(false);
  // const [hoveredRow, setHoveredRow] = useState(null);
  const [attribute, setAttribute] = useState('14');
  const [edit, setEdit] = useState({ name: null, status: null });
  const [searchText, setSearchText] = useState({
    campaign_search: null,
    campaign_type: null,
    status: null,
    ad_group_search: null,
    ad_group_status: null
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenCamSt, setIsDropdownOpenCamSt] = useState(false);
  const [isDropdownOpenCamAdSt, setIsDropdownOpenCamAdSt] = useState(false);
  const [isDropdownOpenCamType, setIsDropdownOpenCamType] = useState(false);
  const [isDropdownOpenAdgroup, setIsDropdownOpenAdgroup] = useState(false);
  const [ErrorShow, setErrorShow] = useState([]);
  const [finalErrorShow, setFinalShow] = useState(false);
  const [newData, setNewData] = useState();
  const [newDataTotal, setNewDataTotal] = useState();
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [savedDataForEdit, setSaveDataForEdit] = useState({});
  const [viewColumnsSelector, setViewColumnsSelector] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  // aDDed code for modal
  const [selectedAdgroup, setSelectAdgroup] = useState({ advertiser: null, campaign: null });
  const [targetingType, setTargetingType] = useState('');
  const [isManual, setIsManual] = useState('');
  const [campaignModal, setCampaignModal] = useState({ open: false, id: null });
  const [campaignBrandModal, setCampaignBrandModal] = useState({ open: false, id: null });
  const [campaignVideoModal, setCampaignVideoModal] = useState({ open: false, id: null });
  const [itemModal, setItemModal] = useState({ open: false, id: null, advertiser_id: null });
  const [keywordModal, setKeywordModal] = useState({ open: false, id: null, name: '' });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tableHeight, setTableHeight] = useState('550px');
  const [inlineLoader, setInlineLoader] = useState(null);
  const [showSelectAdviser, setShowAdviser] = useState(false);
  const [appliedSortTing, setAppliedSortTing] = useState();
  // const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  //for resize table based on screen size
  useEffect(() => {
    const handleResize = () => {
      const calculatedHeight = window.innerHeight * 0.8;
      setTableHeight(`${calculatedHeight}px`);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  //Permission checking
  const adgroup = userData?.permission?.adgroup || {};
  const adgroupCreate = adgroup?.create;
  const adgroupUpdate = adgroup?.update;
  const adgroupDownload = adgroup?.download;

  const ITEM_HEIGHT = 50;

  const handleKeywordModalClose = () => {
    setKeywordModal({ open: false, id: null });
  };

  const handleItemModalClose = () => {
    setItemModal({ open: false, id: null, name: null });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handledEditClose = () => {
    setCampaignModal({ open: false, id: null });
  };

  const handledEditBrandClose = () => {
    setCampaignBrandModal({ open: false, id: null });
  };

  const handleEditVideoClose = () => {
    setCampaignVideoModal({ open: false, id: null });
  };

  const open = Boolean(anchorEl);
  const handleClick = (event, value, name) => {
    setSelectAdgroup({ advertiser: name?.advertiser_id, campaign: value });
    setAnchorEl(event.currentTarget);
    setTargetingType(name?.campaign_type);
    name?.targeting_type == CAMPAIGN.TARGETING_TYPE.MANUAL ? setIsManual(true) : setIsManual(false);
    setCampaignModal({ ...campaignModal, id: value });
    setItemModal({
      ...itemModal,
      id: value,
      name: name?.campaign_name,
      advertiser_id: name?.advertiser_id,
      targeting_type: name?.targeting_type
    });
    setCampaignBrandModal({ ...campaignBrandModal, id: value });
    setCampaignVideoModal({
      ...campaignVideoModal,
      id: value,
      advertiser_id: name?.advertiser_id,
      adgroup: name?.adgroup_number
    });
    setKeywordModal({ ...keywordModal, id: value, name: name?.campaign_name });
  };

  const handleCloseConfirmation = () => {
    // setHoveredRow(null);
    setEdit({ name: null, status: null });
    setConfirmationOpen(false);
  };

  const handleAddAdGroup = () => {
    navigate('/create-adgroup', { state: { advertise: selectedAdgroup.advertiser, id: selectedAdgroup.campaign } });
  };

  const setInitialPageFilters = () => {
    setFilterValue({});
    setTableFilter({});
    setFilterForSearch({});
    setPerformanceFilterData({});
    setPerformanceRanges({});
    setSearchText({}); //Reset Type Text in Autocomplete Dropdown Filter
    setSearchCampaign(''); //Reset Search Item Id
  };

  //Reset filters when we change Profile
  useMemo(() => {
    if (!shouldApplyFilter?.current) {
      setInitialPageFilters();
    }
  }, [shouldApplyFilter?.current]);

  const campaign_status = [
    { id: 1, name: 'Enabled', value: 'enabled' },
    { id: 2, name: 'Paused', value: 'paused' },
    { id: 3, name: 'Proposal', value: 'proposal' }
  ];

  const campaign_type = [
    { id: 1, name: CAMPAIGN.CAMPAIGN_TYPE.SB, value: 'sba' },
    { id: 2, name: CAMPAIGN.CAMPAIGN_TYPE.SP_AUTO, value: 'sponsoredProducts', type: CAMPAIGN.TARGETING_TYPE.AUTO },
    { id: 3, name: CAMPAIGN.CAMPAIGN_TYPE.SP_MANUAL, value: 'sponsoredProducts', type: CAMPAIGN.TARGETING_TYPE.MANUAL },
    { id: 4, name: CAMPAIGN.CAMPAIGN_TYPE.SV, value: 'video' }
  ];

  /* ================================ Autocomplete Filters Start ================================*/

  //Filter Campaign, Campaign Status and Campaign Type Dropdown
  const handleAutoCompleteOnChange = (newValue, filterKey) => {
    let paramKey = '';
    if (filterKey === 'campaign_search' || filterKey === 'ad_group_search') {
      paramKey = 'id';
    } else if (filterKey === 'campaign_type' || filterKey === 'status' || filterKey === 'ad_group_status') {
      paramKey = 'value';
    }

    const idArray = newValue?.map((item) => item?.[paramKey]);
    const uniqueIdArray = [...new Set(idArray)];
    let idString = uniqueIdArray?.join(',') || '';

    let baseArray = [];
    let tableStateFilterKey = '';

    if (filterKey === 'campaign_search') {
      baseArray = [...campaignNameList];
      tableStateFilterKey = 'campaign__in';
    } else if (filterKey === 'campaign_type') {
      baseArray = [...campaign_type];
      tableStateFilterKey = 'type';
    } else if (filterKey === 'status') {
      baseArray = [...campaign_status];
      tableStateFilterKey = 'status';
    } else if (filterKey === 'ad_group_search') {
      baseArray = [...adGroupShortList];
      tableStateFilterKey = 'ad_group';
    } else if (filterKey === 'ad_group_status') {
      baseArray = [...adGroup_state];
      tableStateFilterKey = 'adGroup_state';
    }

    let targetString = '';
    if (filterKey === 'campaign_type') {
      const targetArray = newValue?.map((item) => item?.type);
      targetString = targetArray.filter((item) => item !== undefined).join(',');
    }

    //Check if Select All checkbox is slected
    if (newValue.find((option) => option.all)) {
      const searchTerm = searchText?.[filterKey]?.toLowerCase() || ''; //Change as per selected filter
      const filteredCampaigns = searchTerm
        ? baseArray.filter((item) => item?.name?.toLowerCase().includes(searchTerm))
        : baseArray;
      const allIdsCam = filteredCampaigns.map((obj) => obj.id);

      setIsSelectAll((prevState) => ({
        ...prevState,
        [filterKey]: filteredCampaigns
      }));
      idString =
        newValue.length === 1 || newValue.length <= filteredCampaigns.length
          ? [...new Set(filteredCampaigns.map((item) => item?.[paramKey]))].join(',')
          : '';
      setTableFilter((prevState) => ({
        ...prevState,
        ...(filterKey === 'campaign_type' && { targeting: '' }),
        [tableStateFilterKey]: idString
      }));
      setFilterValue((prevState) => ({
        ...prevState,
        [tableStateFilterKey]: newValue.length - 1 === allIdsCam.length ? [] : filteredCampaigns
      }));
    } else {
      setTableFilter((prevState) => ({
        ...prevState,
        ...(filterKey === 'campaign_type' && { targeting: targetString }),
        [tableStateFilterKey]: idString
      }));
      setFilterValue((prevState) => ({
        ...prevState,
        [tableStateFilterKey]: newValue
      }));
    }
  };

  //For setting Select All checkbox for Filter Campaign, Campaign Status and Campaign Type Dropdown
  const handleAutoCompleteSelectAll = (filterKey) => {
    let baseArray = [];
    let tableStateFilterKey = '';

    if (filterKey === 'campaign_search') {
      baseArray = [...campaignNameList];
      tableStateFilterKey = 'campaign__in';
    } else if (filterKey === 'campaign_type') {
      baseArray = [...campaign_type];
      tableStateFilterKey = 'type';
    } else if (filterKey === 'status') {
      baseArray = [...campaign_status];
      tableStateFilterKey = 'status';
    } else if (filterKey === 'ad_group_search') {
      baseArray = [...adGroupShortList];
      tableStateFilterKey = 'ad_group';
    } else if (filterKey === 'ad_group_status') {
      baseArray = [...adGroup_state];
      tableStateFilterKey = 'adGroup_state';
    }

    const status =
      filterValue?.[tableStateFilterKey]?.length && isSelectAll?.[filterKey]?.length
        ? !!(filterValue?.[tableStateFilterKey]?.length == baseArray?.length) ||
          !!(filterValue?.[tableStateFilterKey]?.length == isSelectAll?.[filterKey]?.length)
        : false;

    return status;
  };

  /* ================================ Autocomplete Filters End ================================*/

  const applyTableFilter = () => {
    let tempTableFilter = { ...tableFilter };
    if (params?.campaignID) {
      tempTableFilter = { ...tempTableFilter, campaign__in: params?.campaignID };
    }
    setTableFilter(tempTableFilter);
    // return tempTableFilter;
  };

  useEffect(() => {
    if (filteredData && filteredData?.length > 0) {
      if (filteredData[0]?.adgroup_column) {
        let tempColumns = [...columns];
        filteredData[0]?.adgroup_column?.map((col) => {
          const columnsIndex = tempColumns?.findIndex((column) => column?.name === col?.name);
          if (columnsIndex !== -1) {
            tempColumns[columnsIndex].options.display = col?.display;
          }
        });
        setSelectedColumns(tempColumns);
      }

      setFilterDataList(filteredData[0]?.adgroup_filter);

      let TemptableFilter = { ...tableFilter };
      let TempFilterValue = { ...filterValue };
      if (filteredData[0]?.adgroup_filter?.Common?.findIndex((item) => item?.value == 'campaign') === -1) {
        TemptableFilter.campaign__in = '';
        TempFilterValue.campaign__in = [];
      }

      if (filteredData[0]?.adgroup_filter?.Common?.findIndex((item) => item?.value == 'state') === -1) {
        TemptableFilter.status = '';
        TempFilterValue.status = [];
      }

      if (filteredData[0]?.adgroup_filter?.Common?.findIndex((item) => item?.value == 'campaign_type') === -1) {
        TemptableFilter.type = '';
        TemptableFilter.targeting = '';
        TempFilterValue.type = [];
      }

      if (filteredData[0]?.adgroup_filter?.Common?.findIndex((item) => item?.value == 'adgroup_name') === -1) {
        TemptableFilter.ad_group = '';
        TempFilterValue.ad_group = [];
      }
      if (filteredData[0]?.adgroup_filter?.Common?.findIndex((item) => item?.value == 'adgroup_state') === -1) {
        TemptableFilter.adGroup_state = '';
        TempFilterValue.adGroup_state = [];
      }
      if (filteredData[0]?.adgroup_filter?.Common?.findIndex((item) => item?.value == 'attr_window') === -1) {
        TemptableFilter.attr_windows = '';
        TempFilterValue.attr_windows = [];
      }
      if (filteredData[0]?.adgroup_filter?.Common?.findIndex((item) => item?.value == 'adgroup') === -1) {
        TemptableFilter.campaign = '';
        TempFilterValue.campaign = [];
      }
      setTableFilter(TemptableFilter);
      setFilterValue(TempFilterValue);

      if (Object.keys(performanceRanges).length > 0) {
        Object.keys(performanceRanges).map((appliedFilter) => {
          let tempPerformanceRanges = { ...performanceRanges };
          if (filteredData[0]?.campaign_filter?.Performance.findIndex((obj) => obj.value === appliedFilter) === -1) {
            delete tempPerformanceRanges[appliedFilter];
          }
          setPerformanceRanges(tempPerformanceRanges);
        });
      }
    }
  }, [filteredData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // dispatch(getAdGroupList([]));
    if (commonAdvertiser?.length == 0) {
      dispatch(setExistingAdgroupId(null));
    }
    applyTableFilter();
  }, []);

  const formateData = (keyArr) => {
    const formattedData = {};
    keyArr.forEach(([key, value]) => {
      formattedData[key] = formateValue(key, value);
    });
    return formattedData;
  };

  useEffect(() => {
    const finalData = adgroupDetails?.results?.map((item) => {
      return formateData(Object.entries(item));
    });
    if (adgroupDetailsTotal) {
      const TotalRowData = formateData(Object.entries(adgroupDetailsTotal));

      setNewDataTotal(TotalRowData);
    }
    setNewData(finalData);

    if (adgroupDetails?.results?.length == 0) {
      dispatch(setSkeletonLoader(false));
    }
    let tempColumns = [...columns];
    if (filteredData[0]?.adgroup_column) {
      filteredData[0]?.adgroup_column?.map((col) => {
        const columnsIndex = tempColumns?.findIndex((column) => column?.name === col?.name);
        if (columnsIndex !== -1) {
          tempColumns[columnsIndex].options.display = col?.display;
        }
      });
    }
    setSelectedColumns(tempColumns);
  }, [adgroupDetails]);

  useEffect(() => {
    if (newData?.length > 0) {
      dispatch(setSkeletonLoader(false));
    }
  }, [newData]);

  //Remove Skeleton loader when csv downloaded
  useEffect(() => {
    if (isCsvDownloadComplete) {
      setIsCsvDownloadComplete(false);
      dispatch(setSkeletonLoader(false));
    }
  }, [isCsvDownloadComplete]);

  useEffect(() => {
    const campaignID = params?.campaignID;
    if (campaignNameList?.length > 0 && campaignID && commonAdvertiser?.length > 0) {
      const find = campaignNameList?.findIndex((item) => item?.id == campaignID);
      if (find === -1) {
        setFilterValue({ campaign__in: [] });
      } else {
        setFilterValue({ campaign__in: [campaignNameList[find]] });
      }
    } else {
      setFilterValue({ campaign__in: [] });
    }
  }, [campaignNameList]);

  useEffect(() => {
    dispatch(getSelectedFilterList());
  }, []);

  const getAdgroupListWithfilters = (newpage, pageRow) => {
    if (commonAdvertiser?.length > 0) {
      let tempTableFilter = {};
      if (params?.campaignID) {
        shouldApplyFilter.current = true;
        tempTableFilter = { ...filterForSearch, campaign__in: params?.campaignID };
        setTableFilter(tempTableFilter);
      }
      setShowAdviser(true);
      getAdgroupListWithPagination(
        newpage !== 'undefined' || newpage !== null ? newpage : page,
        pageRow || rowPerPage,
        search,
        appliedSortTing?.changedColumnSort,
        appliedSortTing?.directionSort,
        commonAdvertiser,
        shouldApplyFilter?.current && params?.campaignID ? tempTableFilter : shouldApplyFilter?.current ? filterForSearch : {},
        shouldApplyFilter?.current ? PerformanceFilterData : {}
      );
    } else {
      setShowAdviser(false);
    }
  };

  // for stop the skeleton loader when no advertiser id is selected
  useEffect(() => {
    if (!selectedProfiles) {
      dispatch(setSkeletonLoader(false)); // Stop loader if no profile selected
    } else {
      setNewData([]);
      dispatch(setSkeletonLoader(true));
    }
  }, [selectedProfiles, dispatch]);

  //Fetch Advertiser Profiles
  useEffect(() => {
    if (JSON.stringify(previousAdvertiser.current) !== JSON.stringify(commonAdvertiser) || isFirstRender?.current) {
      let id = commonAdvertiser;
      dispatch(getCampaignNameList({ id }));
      if (id.length > 0) {
        dispatch(getAdGroupList({ id }));
      }

      setInitialPageFilters();
      shouldApplyFilter.current = false;
      getAdgroupListWithfilters(0, rowPerPage);
      previousAdvertiser.current = commonAdvertiser;
      isFirstRender.current = false;
    }
  }, [commonAdvertiser]);

  useEffect(() => {
    shouldApplyFilter.current = true;
    getAdgroupListWithfilters(0, rowPerPage);
  }, [commonDateRange]);

  const getAdgroupListWithPagination = (
    page,
    rowPerPage,
    search = '',
    changedColumn = appliedSortTing?.changedColumnSort,
    direction = appliedSortTing?.directionSort,
    commonAdvertiserList,
    tableFilter = { campaign__in: '', adGroup_state: '', campaign_status: '', campaign_type: '', ad_group: '', attr_windows: '' },
    PerformanceFilter,
    is_csv = false
  ) => {
    !is_csv && setTableLoading(true);
    const commonDate = commonDateRange;
    if ((commonDate?.startDate && commonDate?.endDate) || params?.campaignID) {
      dispatch(
        getAdgroupDetails({
          page,
          rowPerPage,
          search,
          changedColumn,
          direction,
          commonAdvertiserList,
          tableFilter,
          PerformanceFilter,
          commonDate,
          is_csv,
          isComparingDates,
          setIsCsvDownloadComplete
        })
      ).then(() => {
        setEdit({ name: null });
        setLoading(false);
        setTableLoading(false);
      });
    } else {
      dispatch(setSkeletonLoader(false));
    }
  };

  const handleDownloadCsv = () => {
    const advertiserFilter = commonAdvertiser?.length > 0 ? commonAdvertiser?.join(',') : '';

    getAdgroupListWithPagination(
      page,
      rowPerPage,
      search,
      '',
      '',
      advertiserFilter,
      filterForSearch,
      PerformanceFilterData,
      true
    );
  };

  //Replace value while inline update
  const updateAdgroupDetails = (details, id, updates) => {
    return {
      ...details,
      results: details.results.map((adgroup) =>
        adgroup.id === id
          ? {
              ...adgroup,
              ...updates.reduce((acc, update) => ({ ...acc, ...update }), {}) // Merges the updates
            }
          : adgroup
      )
    };
  };

  const handleSaveButton = (value, id, type) => {
    const _request = {
      [type]: value
    };
    const existGroupId = id;
    setSaveDataForEdit({ _request, existGroupId, type, value });
    setConfirmationOpen(true);
  };

  const handleUpdateAdGroupReport = () => {
    if (savedDataForEdit) {
      setInlineLoader({ [savedDataForEdit?.type]: savedDataForEdit?.existGroupId });
      setConfirmationOpen(false);
      const _request = savedDataForEdit?._request;
      const existGroupId = savedDataForEdit?.existGroupId;
      dispatch(updateAdGroupModal({ _request, existGroupId })).then((res) => {
        setSaveDataForEdit({});
        if (res?.payload?.status == 201 || res?.payload?.status == 200) {
          const updatedAdgroupDetails = updateAdgroupDetails(adgroupDetails, existGroupId, [
            { [savedDataForEdit?.type]: savedDataForEdit?.value }
          ]);
          dispatch(setAdgroupDetails(updatedAdgroupDetails));
          setEdit({ name: null, status: null });
          setInlineLoader(null);
        } else if (res?.payload?.status === 406) {
          //When there is error
          setSaveDataForEdit({});
          setInlineLoader(null);
          setEdit({ name: null, status: null });
        }
      });
    }
  };

  const handleGroupStatusUpdate = (id, event, type) => {
    const _request = {
      [type]: event.target.value
    };
    const existGroupId = id;
    setSaveDataForEdit({ _request, existGroupId, type, value: event.target.value });
    setConfirmationOpen(true);
  };

  const handleColumnSelectorOpen = () => {
    setViewColumnsSelector(true);
  };

  const handleColumnSelectorClose = () => {
    setViewColumnsSelector(false);
  };

  const columns = [
    {
      name: 'name',
      label: 'Adgroup Name',
      default: true,
      options: {
        filter: true,
        sort: true,
        viewColumns: false,
        width: 550,
        sticky: true,
        leftTotal: 0,
        FontSize: '22px',
        draggable: false,
        // customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
        //   const customCss = { zIndex: 101, left: '0px' };
        //   return handleCustomTableHead({
        //     columnMeta,
        //     handleToggleColumn,
        //     sortOrder,
        //     columnName: 'name',
        //     columnLabel_1: 'Adgroup Name',
        //     customCss
        //   });
        // },
        customHeadLabelRender: () => {
          const customCss = { minWidth: '380px' };
          return CustomTableHead({
            columnLabel_1: 'Adgroup Name',
            customCss
          });
        },
        customBodyRender: (value, rowData) => {
          const data = adgroupDetails?.results[rowData?.rowIndex];
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              {edit.name == data?.name ? (
                <>
                  <TextField
                    defaultValue={value}
                    disabled={inlineLoader?.name}
                    autoFocus
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{loading ? <CircularProgress size={20} /> : ''}</InputAdornment>
                      )
                    }}
                    onBlur={(e) => {
                      if (e.target.value != value) {
                        handleSaveButton(e.target.value, data?.id, 'name');
                      } else {
                        setEdit({ name: null });
                      }
                    }}
                  />
                  {inlineLoader?.name && (
                    <Box sx={{ ml: 1 }}>
                      <Loader />
                    </Box>
                  )}
                </>
              ) : (
                <>
                  <Box>
                    {value?.length > 40 ? (
                      <Tooltip placement="top" sx={{ fontSize: '10px' }} title={value} arrow>
                        <Link className={'ffInter'} underline="none" sx={{ cursor: 'pointer' }}>
                          {value.substring(0, 40)}...
                        </Link>
                      </Tooltip>
                    ) : (
                      <Link className={'ffInter'} underline="none" sx={{ cursor: 'pointer' }}>
                        {value}
                      </Link>
                    )}
                  </Box>
                  <Link
                    onClick={() => {
                      setEdit({
                        ...edit,
                        name: data?.name
                      });
                    }}
                    href="javascript:void(0)"
                  >
                    <Tooltip sx={{ fontSize: '10px' }} title={'Edit'} arrow>
                      {' '}
                      <img src={editIcon} alt="Icon" />
                    </Tooltip>
                  </Link>
                </>
              )}
            </Box>
          );
        },
        ...(showSelectAdviser && {
          setCellProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: '0',
              minWidth: '380px',
              background: 'white',
              zIndex: 100
            }
          }),
          setCellHeaderProps: () => ({
            sx: {
              '& button': { padding: '8px 0px !important', margin: '0px' },
              '& button:hover': { backgroundColor: 'transparent' }
            },
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: 0,
              // background: 'white',
              zIndex: 101
            }
          })
        })
      }
    },
    {
      name: 'status',
      label: 'Status',
      default: true,
      options: {
        filter: true,
        sort: true,
        width: 550,
        viewColumns: false,
        sticky: true,
        leftTotal: 380,
        draggable: false,
        // customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
        //   const customCss = { zIndex: 101, left: '380px', width: '90px' };
        //   return handleCustomTableHead({
        //     columnMeta,
        //     handleToggleColumn,
        //     sortOrder,
        //     columnName: 'status',
        //     columnLabel_1: 'Status',
        //     customCss
        //   });
        // },
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Status'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = adgroupDetails?.results ? adgroupDetails?.results[rowData?.rowIndex] : [];
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {inlineLoader?.status && inlineLoader?.status === data?.id ? (
                <Loader />
              ) : (
                <Select
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                      border: 'none !important'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      boxShadow: 'none !important'
                    },
                    '.MuiSelect-select': {
                      paddingLeft: '0 !important' // Remove left padding
                    }
                  }}
                  size="small"
                  value={value}
                  // autoFocus
                  onChange={(e) => handleGroupStatusUpdate(data.id, e, 'status')}
                  disabled={!adgroupUpdate}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={selected === 'enabled' ? EnabledIcon : DisabledIcon}
                        alt={selected}
                        width={22}
                        height={22}
                        style={{ marginRight: '8px' }}
                      />
                      {/* The text is commented out to only show the icon when the select is closed */}
                      {/* {!isOpen && selected.charAt(0).toUpperCase() + selected.slice(1)} */}
                    </Box>
                  )}
                >
                  <MenuItem value="enabled">
                    {' '}
                    <img src={EnabledIcon} alt="EnabledIcon" width={22} height={22} style={{ marginRight: '8px' }} />
                    Enabled
                  </MenuItem>
                  <MenuItem value="disabled">
                    {' '}
                    <img src={DisabledIcon} alt="EnabledIcon" width={22} height={22} style={{ marginRight: '8px' }} /> Disabled
                  </MenuItem>
                </Select>
              )}
            </Box>
          );
        },
        ...(showSelectAdviser && {
          setCellProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              minWidth: '90px',
              left: 380,
              background: 'white',
              zIndex: 100
            }
          }),
          setCellHeaderProps: () => ({
            sx: {
              '& button': { padding: '8px 0px !important', margin: '0px' },
              '& button:hover': { backgroundColor: 'transparent' }
            },
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              minWidth: '90px',
              left: 380,
              // background: 'white',
              zIndex: 101
            }
          })
        })
      }
    },
    {
      name: 'profile_name',
      label: 'Profile Name',
      options: {
        filter: true,
        sort: true,
        // customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
        //   return handleCustomTableHead({
        //     columnMeta,
        //     handleToggleColumn,
        //     sortOrder,
        //     columnName: 'profile_name',
        //     columnLabel_1: 'Profile Name'
        //   });
        // },
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px 0px !important', margin: '0px' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Profile Name'
          });
        },
        customBodyRender: (value) => {
          return <Typography className="clr3">{value}</Typography>;
        }
      }
    },
    {
      name: 'campaign_name',
      label: 'Campaign Name',
      options: {
        filter: true,
        sort: true,
        // customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
        //   return handleCustomTableHead({
        //     columnMeta,
        //     handleToggleColumn,
        //     sortOrder,
        //     columnName: 'campaign_name',
        //     columnLabel_1: 'Campaign Name'
        //   });
        // },
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px 0px !important', margin: '0px' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Campaign Name'
          });
        },
        customBodyRender: (value, tableMeta) => {
          const data = adgroupDetails?.results ? adgroupDetails?.results[tableMeta.rowIndex] : [];
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                {value?.length > 30 ? (
                  <Tooltip placement="top" sx={{ fontSize: '10px' }} title={value} arrow>
                    <Link
                      href={`/campaign/${commaStringToNumber(data?.campaign_id)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={'ffInter'}
                      underline="none"
                      sx={{ cursor: 'pointer' }}
                    >
                      {value.substring(0, 30)}...
                    </Link>
                  </Tooltip>
                ) : (
                  <Link
                    href={`/campaign/${commaStringToNumber(data?.campaign_id)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={'ffInter'}
                    underline="none"
                    sx={{ cursor: 'pointer' }}
                  >
                    {value}
                  </Link>
                )}
              </Typography>
              <Box>
                <IconButton onClick={(e) => handleClick(e, data?.campaign, data)}>
                  <Tooltip sx={{ fontSize: '10px' }} title={'Action'} arrow>
                    {' '}
                    <img src={actionIcon} alt="icon" />
                  </Tooltip>
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button'
                  }}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: '20ch',
                      borderColor: colors?.grey100,
                      borderRadius: '6px'
                    }
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      if (targetingType == 'sba') {
                        setCampaignBrandModal({ ...campaignBrandModal, open: true });
                      }
                      if (targetingType == 'video') {
                        setCampaignVideoModal({ ...campaignVideoModal, open: true });
                      } else {
                        setCampaignModal({ ...campaignModal, open: true });
                      }
                    }}
                  >
                    <Box display={'flex'} alignItems={'center'} gap={'6px'}>
                      <img src={editIcon} alt="Icon" />
                      <Typography
                        sx={{
                          color: colors?.grey900,
                          '&:hover': {
                            color: colors?.darkTextHover,
                            fontWeight: 700
                          }
                        }}
                      >
                        Campaign
                      </Typography>
                    </Box>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setItemModal({ ...itemModal, open: true });
                    }}
                  >
                    <Box display={'flex'} alignItems={'center'} gap={'6px'}>
                      <img src={plusSimpleIcon} alt="Icon" />
                      <Typography
                        sx={{
                          color: colors?.grey900,
                          '&:hover': {
                            color: colors?.darkTextHover,
                            fontWeight: 700
                          }
                        }}
                      >
                        Add Items
                      </Typography>
                    </Box>
                  </MenuItem>
                  {isManual && (
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setKeywordModal({ ...keywordModal, open: true });
                      }}
                    >
                      <Box display={'flex'} alignItems={'center'} gap={'6px'}>
                        <img src={plusSimpleIcon} alt="Icon" />
                        <Typography
                          sx={{
                            color: colors?.grey900,
                            '&:hover': {
                              color: colors?.darkTextHover,
                              fontWeight: 700
                            }
                          }}
                        >
                          Add Keyword
                        </Typography>
                      </Box>
                    </MenuItem>
                  )}
                  {targetingType === 'sponsoredProducts' && (
                    <MenuItem onClick={handleAddAdGroup}>
                      <Box display={'flex'} alignItems={'center'} gap={'6px'}>
                        <img src={plusSimpleIcon} alt="Icon" />
                        <Typography
                          sx={{
                            color: colors?.grey900,
                            '&:hover': {
                              color: colors?.darkTextHover,
                              fontWeight: 700
                            }
                          }}
                        >
                          Add Adgroup
                        </Typography>
                      </Box>
                    </MenuItem>
                  )}
                </Menu>
              </Box>
            </Box>
          );
        }
      }
    },
    {
      name: 'campaign_type',
      label: 'Campaign Type',
      options: {
        filter: true,
        sort: true,
        // customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
        //   return handleCustomTableHead({
        //     columnMeta,
        //     handleToggleColumn,
        //     sortOrder,
        //     columnName: 'campaign_type',
        //     columnLabel_1: 'Campaign',
        //     columnLabel_2: 'Type'
        //   });
        // },
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px 0px !important', margin: '0px' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Campaign',
            columnLabel_2: 'Type'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = adgroupDetails?.results[rowData?.rowIndex];

          var type = '';
          if (value == 'sponsoredProducts') {
            type =
              data?.targeting_type == CAMPAIGN.TARGETING_TYPE.MANUAL
                ? CAMPAIGN.CAMPAIGN_TYPE.SP_MANUAL
                : CAMPAIGN.CAMPAIGN_TYPE.SP_AUTO;
          } else if (value == 'sba') {
            type = CAMPAIGN.CAMPAIGN_TYPE.SB;
          } else if (value == 'video') {
            type = CAMPAIGN.CAMPAIGN_TYPE.SV;
          }

          return (
            <Box>
              <Typography>{type}</Typography>
            </Box>
          );
        }
      }
    },
    {
      name: 'item_number',
      label: 'Items',
      options: {
        filter: true,
        sort: true,
        // customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
        //   return handleCustomTableHead({
        //     columnMeta,
        //     handleToggleColumn,
        //     sortOrder,
        //     columnName: 'item_number',
        //     columnLabel_1: 'Items'
        //   });
        // },
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px 0px !important', margin: '0px' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Items'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = adgroupDetails?.results[rowData?.rowIndex];
          return (
            <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
              {value ? (
                <a
                  className="link-dashna-ui"
                  rel="noreferrer noopener"
                  href={`/items/${data?.campaign}/${data?.id}/${CAMPAIGN.NAVIGATION_TYPE.ADGROUP}`}
                  target="_blank"
                >
                  {value}
                </a>
              ) : (
                <>--</>
              )}
            </Box>
          );
        }
      }
    },
    {
      name: 'keyword_number',
      label: 'Keywords',
      options: {
        filter: true,
        sort: true,
        // customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
        //   return handleCustomTableHead({
        //     columnMeta,
        //     handleToggleColumn,
        //     sortOrder,
        //     columnName: 'keyword_number',
        //     columnLabel_1: 'Keywords'
        //   });
        // },
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px 0px !important', margin: '0px' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Keywords'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = adgroupDetails?.results[rowData?.rowIndex];
          return (
            <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
              {value ? (
                <a
                  className="link-dashna-ui"
                  rel="noreferrer noopener"
                  href={`/keyword/${data?.campaign}/${data?.id}/${CAMPAIGN.NAVIGATION_TYPE.ADGROUP}`}
                  target="_blank"
                >
                  {value}
                </a>
              ) : (
                <>--</>
              )}
            </Box>
          );
        }
      }
    },
    {
      name: 'impressions',
      label: 'Impressions',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        // customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
        //   return handleCustomTableHead({
        //     columnMeta,
        //     handleToggleColumn,
        //     sortOrder,
        //     columnName: 'impressions',
        //     columnLabel_1: 'Impressions'
        //   });
        // },
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px 0px !important', margin: '0px' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Impressions'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = adgroupDetails?.results[rowData?.rowIndex];

          let currency = value.indexOf('$') > -1;

          return (
            <>
              {value}
              <CompareValueWithColor
                value={data?.comparison_impressions}
                Percentage={data?.percentage_impressions}
                UpDown={data?.is_higher_impressions}
                keys="impressions"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'clicks',
      label: 'Clicks',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        // customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
        //   return handleCustomTableHead({
        //     columnMeta,
        //     handleToggleColumn,
        //     sortOrder,
        //     columnName: 'clicks',
        //     columnLabel_1: 'Clicks'
        //   });
        // },
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px 0px !important', margin: '0px' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Clicks'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = adgroupDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value}
              <CompareValueWithColor
                value={data?.comparison_clicks}
                Percentage={data?.percentage_clicks}
                UpDown={data?.is_higher_clicks}
                keys="clicks"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'ctr',
      label: 'CTR',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        // customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
        //   return handleCustomTableHead({
        //     columnMeta,
        //     handleToggleColumn,
        //     sortOrder,
        //     columnName: 'ctr',
        //     columnLabel_1: 'CTR'
        //   });
        // },
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px 0px !important', margin: '0px' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'CTR'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = adgroupDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null%' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_ctr}
                Percentage={data?.percentage_ctr}
                UpDown={data?.is_higher_ctr}
                keys="ctr"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'total_attributed_orders',
      label: 'Orders',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        // customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
        //   return handleCustomTableHead({
        //     columnMeta,
        //     handleToggleColumn,
        //     sortOrder,
        //     columnName: 'total_attributed_orders',
        //     columnLabel_1: 'Orders'
        //   });
        // },
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px 0px !important', margin: '0px' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Orders'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = adgroupDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              <Typography>{value?.length > 0 ? value : '-'}</Typography>
              <CompareValueWithColor
                value={data?.comparison_total_attributed_orders}
                Percentage={data?.percentage_total_attributed_orders}
                UpDown={data?.is_higher_total_attributed_orders}
                keys="total_attributed_orders"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'cpa_orders',
      label: 'CPA (Orders)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        // customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
        //   return handleCustomTableHead({
        //     columnMeta,
        //     handleToggleColumn,
        //     sortOrder,
        //     columnName: 'cpa_orders',
        //     columnLabel_1: 'CPA',
        //     columnLabel_2: '(Orders)',
        //     isLabelAlignRight: true
        //   });
        // },
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px 0px !important', margin: '0px' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'CPA',
            columnLabel_2: '(Orders)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = adgroupDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              <Typography>{value?.length > 0 ? value : '-'}</Typography>
              <CompareValueWithColor
                value={data.comparison_cpa_orders}
                Percentage={data.percentage_cpa_orders}
                UpDown={data.is_higher_cpa_orders}
                keys="cpa_orders"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'cpa_units',
      label: 'CPA (Units)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        // customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
        //   return handleCustomTableHead({
        //     columnMeta,
        //     handleToggleColumn,
        //     sortOrder,
        //     columnName: 'cpa_units',
        //     columnLabel_1: 'CPA',
        //     columnLabel_2: '(Units)',
        //     isLabelAlignRight: true
        //   });
        // },
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px 0px !important', margin: '0px' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'CPA',
            columnLabel_2: '(Units)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = adgroupDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              <Typography>{value?.length > 0 ? value : '-'}</Typography>
              <CompareValueWithColor
                value={data?.comparison_cpa_units}
                Percentage={data?.percentage_cpa_units}
                UpDown={data?.is_higher_cpa_units}
                keys="cpa_units"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'ad_spend',
      label: 'Spend',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        // customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
        //   return handleCustomTableHead({
        //     columnMeta,
        //     handleToggleColumn,
        //     sortOrder,
        //     columnName: 'ad_spend',
        //     columnLabel_1: 'Spend'
        //   });
        // },
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px 0px !important', margin: '0px' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Spend'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = adgroupDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value}
              <CompareValueWithColor
                value={data?.comparison_ad_spend}
                Percentage={data?.percentage_ad_spend}
                UpDown={data?.is_higher_ad_spend}
                keys="ad_spend"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'ad_sales',
      label: 'Ad Sales',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        // customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
        //   return handleCustomTableHead({
        //     columnMeta,
        //     handleToggleColumn,
        //     sortOrder,
        //     columnName: 'ad_sales',
        //     columnLabel_1: 'Ad Sales'
        //   });
        // },
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px 0px !important', margin: '0px' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Ad Sales'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = adgroupDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value}
              <CompareValueWithColor
                value={data?.comparison_ad_sales}
                Percentage={data?.percentage_ad_sales}
                UpDown={data?.is_higher_ad_sales}
                keys="ad_sales"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'roas',
      label: 'RoAS',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        // customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
        //   return handleCustomTableHead({
        //     columnMeta,
        //     handleToggleColumn,
        //     sortOrder,
        //     columnName: 'roas',
        //     columnLabel_1: 'RoAS'
        //   });
        // },
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px 0px !important', margin: '0px' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'RoAS'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = adgroupDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null%' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_roas}
                Percentage={data?.percentage_roas}
                UpDown={data?.is_higher_roas}
                keys="roas"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'units_sold',
      label: 'Units Sold',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        // customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
        //   return handleCustomTableHead({
        //     columnMeta,
        //     handleToggleColumn,
        //     sortOrder,
        //     columnName: 'units_sold',
        //     columnLabel_1: 'Unit Sold'
        //   });
        // },
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px 0px !important', margin: '0px' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Unit Sold'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = adgroupDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null%' ? value : '0'}
              <CompareValueWithColor
                value={data.comparison_units_sold}
                Percentage={data.percentage_units_sold}
                UpDown={data.is_higher_units_sold}
                keys="units_sold"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'acos',
      label: 'ACoS',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        // customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
        //   return handleCustomTableHead({
        //     columnMeta,
        //     handleToggleColumn,
        //     sortOrder,
        //     columnName: 'acos',
        //     columnLabel_1: 'ACoS'
        //   });
        // },
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px 0px !important', margin: '0px' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'ACoS'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = adgroupDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null%' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_acos}
                Percentage={data?.percentage_acos}
                UpDown={data?.is_higher_acos}
                keys="acos"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'average_cpc',
      label: 'Average CPC',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        // customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
        //   return handleCustomTableHead({
        //     columnMeta,
        //     handleToggleColumn,
        //     sortOrder,
        //     columnName: 'average_cpc',
        //     columnLabel_1: 'Average',
        //     columnLabel_2: 'CPC',
        //     isLabelAlignRight: true
        //   });
        // },
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px 0px !important', margin: '0px' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Average',
            columnLabel_2: 'CPC',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = adgroupDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null%' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_average_cpc}
                Percentage={data?.percentage_average_cpc}
                UpDown={data?.is_higher_average_cpc}
                keys="average_cpc"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'cvr_orders',
      label: 'CVR (Orders)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        // customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
        //   return handleCustomTableHead({
        //     columnMeta,
        //     handleToggleColumn,
        //     sortOrder,
        //     columnName: 'cvr_orders',
        //     columnLabel_1: 'CVR',
        //     columnLabel_2: '(Orders)',
        //     isLabelAlignRight: true
        //   });
        // },
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px 0px !important', margin: '0px' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'CVR',
            columnLabel_2: '(Orders)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = adgroupDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== '$null' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_cvr_orders}
                Percentage={data?.percentage_cvr_orders}
                UpDown={data?.is_higher_cvr_orders}
                keys="cvr_orders"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'cvr_units',
      label: 'CVR (Units)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        // customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
        //   return handleCustomTableHead({
        //     columnMeta,
        //     handleToggleColumn,
        //     sortOrder,
        //     columnName: 'cvr_units',
        //     columnLabel_1: 'CVR',
        //     columnLabel_2: '(Units)',
        //     isLabelAlignRight: true
        //   });
        // },
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px 0px !important', margin: '0px' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'CVR',
            columnLabel_2: '(Units)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = adgroupDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== '$null' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_cvr_units}
                Percentage={data?.percentage_cvr_units}
                UpDown={data?.is_higher_cvr_units}
                keys="cvr_units"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'instore_advertised_sales',
      label: 'In-Store Advertised Sales (Beta)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        // customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
        //   return handleCustomTableHead({
        //     columnMeta,
        //     handleToggleColumn,
        //     sortOrder,
        //     columnName: 'instore_advertised_sales',
        //     columnLabel_1: 'In-Store Advertised',
        //     columnLabel_2: 'Sales (β)',
        //     isLabelAlignRight: true
        //   });
        // },
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px 0px !important', margin: '0px' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'In-Store Advertised',
            columnLabel_2: 'Sales (β)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = adgroupDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== '$null' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_instore_advertised_sales}
                Percentage={data?.percentage_instore_advertised_sales}
                UpDown={data?.is_higher_instore_advertised_sales}
                keys="instore_advertised_sales"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'instore_attributed_sales',
      label: 'In-Store Attributed Sales (Beta)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        // customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
        //   return handleCustomTableHead({
        //     columnMeta,
        //     handleToggleColumn,
        //     sortOrder,
        //     columnName: 'instore_attributed_sales',
        //     columnLabel_1: 'In-Store Attributed',
        //     columnLabel_2: 'Sales (β)',
        //     isLabelAlignRight: true
        //   });
        // },
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px 0px !important', margin: '0px' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'In-Store Attributed',
            columnLabel_2: 'Sales (β)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = adgroupDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_instore_attributed_sales}
                Percentage={data?.percentage_instore_attributed_sales}
                UpDown={data?.is_higher_instore_attributed_sales}
                keys="instore_attributed_sales"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'instore_orders',
      label: 'In-Store Orders (Beta)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        // customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
        //   return handleCustomTableHead({
        //     columnMeta,
        //     handleToggleColumn,
        //     sortOrder,
        //     columnName: 'instore_orders',
        //     columnLabel_1: 'In-Store',
        //     columnLabel_2: 'Orders (β)',
        //     isLabelAlignRight: true
        //   });
        // },
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px 0px !important', margin: '0px' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'In-Store',
            columnLabel_2: 'Orders (β)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = adgroupDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_instore_orders}
                Percentage={data?.percentage_instore_orders}
                UpDown={data?.is_higher_instore_orders}
                keys="instore_orders"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'instore_other_sales',
      label: 'In-Store Other Sales (Beta)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        // customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
        //   return handleCustomTableHead({
        //     columnMeta,
        //     handleToggleColumn,
        //     sortOrder,
        //     columnName: 'instore_other_sales',
        //     columnLabel_1: 'In-Store Other',
        //     columnLabel_2: 'Sales (β)',
        //     isLabelAlignRight: true
        //   });
        // },
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px 0px !important', margin: '0px' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'In-Store Other',
            columnLabel_2: 'Sales (β)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = adgroupDetails?.results[rowData?.rowIndex];
          let currency = value.indexOf('$') > -1;
          return (
            <>
              {value && value !== '$null' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_instore_other_sales}
                Percentage={data?.percentage_instore_other_sales}
                UpDown={data?.is_higher_instore_other_sales}
                keys="instore_other_sales"
                currency={currency}
              />
            </>
          );
        }
      }
    },
    {
      name: 'instore_units_sold',
      label: 'In-Store Units Sold (Beta)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'table-align-right clr3'
        }),
        // customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
        //   return handleCustomTableHead({
        //     columnMeta,
        //     handleToggleColumn,
        //     sortOrder,
        //     columnName: 'instore_other_sales',
        //     columnLabel_1: 'In-Store Units',
        //     columnLabel_2: 'Sold (β)',
        //     isLabelAlignRight: true
        //   });
        // },
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px 0px !important', margin: '0px' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'In-Store Units',
            columnLabel_2: 'Sold (β)',
            isLabelAlignRight: true
          });
        },
        customBodyRender: (value, rowData) => {
          const data = adgroupDetails?.results[rowData?.rowIndex];
          let currency = value?.indexOf('$') > -1;
          return (
            <>
              {value && value !== 'null' ? value : '0'}
              <CompareValueWithColor
                value={data?.comparison_instore_units_sold}
                Percentage={data?.percentage_instore_units_sold}
                UpDown={data?.is_higher_instore_units_sold}
                keys="instore_units_sold"
                currency={currency}
              />
            </>
          );
        }
      }
    }
  ];

  const initialColumnOrder = columns.map((_, index) => index);
  const [columnOrder, setColumnOrder] = useState(initialColumnOrder);

  const options = {
    search: false,
    filter: false,
    print: false,
    selectToolbarPlacement: 'none',
    responsive: 'standard',
    fixedHeader: true,
    fixedSelectColumn: false,
    viewColumns: false,
    tableBodyHeight: tableHeight,
    page: page,
    count: adgroupDetails?.count || 0,
    jumpToPage: false,
    download: false,
    pagination: true,
    serverSide: true,
    selectableRows: 'none',
    rowsPerPage: rowPerPage,
    rowsPerPageOptions: PageValue,
    customToolbar: () => <></>,
    draggableColumns: {
      enabled: false
    },
    columnOrder: columnOrder,
    textLabels: {
      body: {
        noMatch: showSelectAdviser ? (
          tableLoading ? (
            <Loader />
          ) : (
            'Sorry, there is no matching data to display'
          )
        ) : (
          'Please Select At List One Advertiser '
        )
      }
    },
    onChangeRowsPerPage: (row) => {
      setRowPerPage(row);
      getAdgroupListWithfilters(0, row);
    },
    onChangePage: (page) => {
      setPage(page);
      getAdgroupListWithfilters(page);
    },
    onColumnSortChange: (changedColumn, direction) => {
      setPage(0);
      setSearch('');
      const payload = {
        changedColumnSort: changedColumn,
        directionSort: direction
      };
      setAppliedSortTing(payload);
      getAdgroupListWithPagination(
        0,
        rowPerPage,
        '',
        changedColumn,
        direction,
        commonAdvertiser,
        shouldApplyFilter?.current ? filterForSearch : {},
        shouldApplyFilter?.current ? PerformanceFilterData : {}
      );
    },
    onColumnOrderChange: (newColumnOrder) => {
      if (newColumnOrder[0] !== 0 || newColumnOrder[1] !== 1) {
        setColumnOrder([...columnOrder]);
      } else {
        setColumnOrder(newColumnOrder);
      }
    },
    customTableBodyFooterRender: (opts) => {
      const optsColumns = opts?.columns;
      const reorderedColumns = columnOrder.map((index) => optsColumns[index]);
      return (
        <>
          {commonAdvertiser?.length > 0 && (
            <TableFooter>
              <TableRow className="table-footer-total" sx={{ position: 'sticky', bottom: 0, background: 'white', zIndex: 999 }}>
                {reorderedColumns?.map((col, index) => {
                  if (col && col.display === 'true' && newDataTotal) {
                    let currency = newDataTotal[col?.name] && newDataTotal[col?.name]?.indexOf('$') > -1;
                    return (
                      <TableCell
                        className={`${col?.sticky ? 'column-sticky' : 'column-sticky-1'} ${
                          alignRightColumn(col?.name) && 'table-align-right'
                        }`}
                        sx={
                          col?.sticky
                            ? {
                                whiteSpace: 'nowrap',
                                position: 'sticky',
                                left: col?.leftTotal,
                                fontSize: '14px',
                                // fontSize: col?.FontSize?.length > 0 ? col?.FontSize : '',
                                minwidth: col?.name === 'status' ? '90px' : '180px',
                                background: 'white',
                                zIndex: 100,
                                color: '#364152',
                                fontWeight: 'bold'
                              }
                            : {}
                        }
                        key={index}
                      >
                        {col?.name == 'name' ? (
                          'Total'
                        ) : (
                          <>
                            <span style={{ color: '#364152', fontWeight: 'bold' }}>{newDataTotal[col?.name] || ''}</span>

                            <CompareValueWithColor
                              value={newDataTotal[`comparison_${col?.name}`] && newDataTotal[`comparison_${col?.name}`]}
                              Percentage={
                                newDataTotal[`percentage_${col?.name}`] && parseFloat(newDataTotal[`percentage_${col?.name}`])
                              }
                              UpDown={
                                typeof newDataTotal[`is_higher_${col?.name}`] != 'undefined' &&
                                newDataTotal[`is_higher_${col?.name}`] == 'true'
                                  ? true
                                  : false
                              }
                              keys={col?.name}
                              isTotalRow={true}
                              currency={currency}
                            />
                          </>
                        )}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            </TableFooter>
          )}
        </>
      );
    }
  };

  // const handleSearch = useCallback(
  //   debounce((data) => {
  //     setPage(0);
  //     getAdgroupListWithPagination(0, rowPerPage, data);
  //   }, 500),
  //   []
  // );

  const handleSearchClick = () => {
    shouldApplyFilter.current = true;
    setFilterForSearch({ ...tableFilter });
    const filledPerformanceRanges = Object.entries(performanceRanges).reduce((acc, [performanceId, values]) => {
      const { min, max } = values;
      if (min?.length > 0 || max?.length > 0) {
        acc[performanceId] = values;
      }
      return acc;
    }, {});

    // tableFilter
    const comparisonValues =
      filterDataList?.Performance &&
      filterDataList?.Performance?.length > 0 &&
      filterDataList?.Performance.map((item) => item.value);

    // Filter dataRanges based on comparisonValues
    const PerformanceFilter =
      comparisonValues &&
      Object.fromEntries(Object.entries(filledPerformanceRanges).filter(([key]) => comparisonValues.includes(key)));

    setPerformanceFilterData(Object?.keys(PerformanceFilter)?.length > 0 ? PerformanceFilter : '');

    if (tableFilter.campaign__in) {
      getAdgroupListWithPagination(
        0,
        rowPerPage,
        _,
        appliedSortTing?.changedColumnSort,
        appliedSortTing?.directionSort,
        commonAdvertiser,
        tableFilter,
        PerformanceFilter ? PerformanceFilter : ''
      );
    } else if (params?.campaignID && filterValue.campaign__in[0]) {
      getAdgroupListWithPagination(
        page,
        rowPerPage,
        search,
        appliedSortTing?.changedColumnSort,
        appliedSortTing?.directionSort,
        commonAdvertiser,
        tableFilter,
        Object.keys(PerformanceFilter).length > 0 ? PerformanceFilter : ''
      );
    } else {
      getAdgroupListWithPagination(
        0,
        rowPerPage,
        '',
        appliedSortTing?.changedColumnSort,
        appliedSortTing?.directionSort,
        commonAdvertiser,
        tableFilter,
        Object.keys(PerformanceFilter).length > 0 ? PerformanceFilter : ''
      );
    }
  };

  const handleInputChange = (performanceId, valueType, value, showError) => {
    setErrorShow((ErrorShow) => ({
      ...ErrorShow,
      [performanceId]: {
        ...ErrorShow[performanceId],
        error: showError
      }
    }));

    let a = {
      ...ErrorShow,
      [performanceId]: {
        ...ErrorShow[performanceId],
        error: showError
      }
    };
    let output = Object.values(a).map((item) => {
      if (typeof item === 'object' && item !== null && 'error' in item) {
        return item.error;
      } else {
        return item;
      }
    });
    let result = [...new Set(output)];

    let finalOutPut = result.length == 1 && result[0] == false ? false : true;
    setFinalShow(finalOutPut);

    setPerformanceRanges((prevRanges) => ({
      ...prevRanges,
      [performanceId]: {
        ...prevRanges[performanceId],
        [valueType]: value
      }
    }));
  };

  return (
    <MainCard title="Adgroup List">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'space-between' }}>
        <CustomFilters filterType={'adgroup_filter'} defaultFilterKey={params?.id ? 'campaign' : null} />

        <Box display={'flex'}>
          {adgroupCreate && (
            <Button
              sx={{
                borderRadius: '8px',
                py: '12px',
                mr: 1
              }}
              variant="contained"
              startIcon={<Add />}
              onClick={() => navigate('/create-adgroup')}
            >
              Add Adgroup
            </Button>
          )}

          <Button sx={{ p: '0px !important', minWidth: '45px' }} onClick={handleColumnSelectorOpen}>
            <Tooltip arrow title="Select/Reorder Columns">
              {' '}
              <img src={viewcolumnsIcon} alt="filter icon" />{' '}
            </Tooltip>
          </Button>
          {adgroupDownload && (
            <Button sx={{ p: '0px !important', minWidth: '45px' }} onClick={handleDownloadCsv}>
              <Tooltip arrow title="Download">
                {' '}
                <img src={DownloadIcon} alt="filter icon" />{' '}
              </Tooltip>
            </Button>
          )}
        </Box>
      </Box>
      <Grid container sx={{ pt: 3, pb: 3 }} spacing={3}>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel color="secondary">Attr. window</InputLabel>
            <Select
              value={attribute || []}
              color="secondary"
              // size="small"
              label="Attr. window"
              fullWidth
              onChange={(e) => {
                setAttribute(e.target.value);

                setTableFilter({ ...tableFilter, attr_windows: e.target.value });
                setFilterValue({ ...filterValue, attr_windows: e.target.value });
              }}
              sx={{ width: '100%' }}
            >
              {attributeWindowList?.map((item) => {
                return (
                  <MenuItem key={item.value} value={item.value}>
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        {filterDataList && filterDataList?.Common?.find((item) => item?.value == 'campaign') && (
          <Grid item xs={12} md={3}>
            <CustomAutocomplete
              multiple
              id="Campaign_filter"
              name="Campaign"
              color="secondary"
              label="Campaign Name"
              options={campaignNameList || []}
              value={filterValue?.campaign__in || []}
              onChange={(e, value) => {
                handleAutoCompleteOnChange(value, 'campaign_search');
              }}
              optionLabelkey="name"
              optionIdkey="id"
              inputValue={isDropdownOpen ? searchText?.campaign_search : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, campaign_search: value });
                }
              }}
              onOpen={() => setIsDropdownOpen(true)}
              onClose={() => setIsDropdownOpen(false)}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('campaign_search')}
              placeholderCondition={filterValue?.campaign__in?.length > 0}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              limitTags={2}
            />
          </Grid>
        )}

        {filterDataList && filterDataList?.Common?.find((item) => item?.value == 'state') && (
          <Grid item xs={12} md={3}>
            <CustomAutocomplete
              multiple
              id="Campaign_State"
              name="campaign_status"
              color="secondary"
              label="Campaign Status"
              options={campaign_status || []}
              value={filterValue?.status || []}
              onChange={(e, value) => {
                handleAutoCompleteOnChange(value, 'status');
              }}
              optionLabelkey="name"
              optionIdkey="id"
              inputValue={isDropdownOpenCamSt ? searchText?.status : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, status: value });
                }
              }}
              onOpen={() => setIsDropdownOpenCamSt(true)}
              onClose={() => setIsDropdownOpenCamSt(false)}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('status')}
              placeholderCondition={filterValue?.status?.length > 0}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              limitTags={2}
            />
          </Grid>
        )}

        {filterDataList && filterDataList?.Common?.find((item) => item?.value == 'campaign_type') && (
          <Grid item xs={12} md={3}>
            <CustomAutocomplete
              multiple
              id="Campaign_State"
              name="campaign_type"
              color="secondary"
              label="Campaign Type"
              options={campaign_type || []}
              value={filterValue?.type || []}
              onChange={(e, value) => {
                handleAutoCompleteOnChange(value, 'campaign_type');
              }}
              optionLabelkey="name"
              optionIdkey="id"
              inputValue={isDropdownOpenCamType ? searchText?.campaign_type : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, campaign_type: value });
                }
              }}
              onOpen={() => setIsDropdownOpenCamType(true)}
              onClose={() => setIsDropdownOpenCamType(false)}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('campaign_type')}
              placeholderCondition={filterValue?.type?.length > 0}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              limitTags={2}
            />
          </Grid>
        )}

        {filterDataList && filterDataList?.Common?.find((item) => item?.value == 'sort_by') && (
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel color="secondary" id="demo-simple-select-label">
                Sory By
              </InputLabel>
              <Select
                value={selectedSortBy || []}
                color="secondary"
                // size="small"
                label="Sory By"
                placeholder="Select Sort By"
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  setSelectedSortBy(e.target.value);
                }}
                sx={{ width: '100%' }}
              >
                {sortByList?.map((item) => {
                  return (
                    <MenuItem key={item?.value} value={item?.value}>
                      {item?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        )}

        {filterDataList && filterDataList?.Common?.find((item) => item?.value == 'adgroup_name') && (
          <Grid item xs={12} md={3}>
            <CustomAutocomplete
              multiple
              id="adgroup_name"
              name="campaign_adgroup_name"
              color="secondary"
              label="Ad Group"
              options={adGroupShortList || []}
              value={filterValue?.ad_group || []}
              onChange={(e, value) => {
                handleAutoCompleteOnChange(value, 'ad_group_search');
              }}
              optionLabelkey="name"
              optionIdkey="id"
              inputValue={isDropdownOpenAdgroup ? searchText?.ad_group_search : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, ad_group_search: value });
                }
              }}
              onOpen={() => setIsDropdownOpenAdgroup(true)}
              onClose={() => setIsDropdownOpenAdgroup(false)}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('ad_group_search')}
              placeholderCondition={filterValue?.ad_group?.length > 0}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              limitTags={2}
            />
          </Grid>
        )}
        {filterDataList && filterDataList?.Common?.find((item) => item?.value == 'adgroup_state') && (
          <Grid item xs={12} md={3}>
            <CustomAutocomplete
              multiple
              id="adgroup_state"
              name="adgroup_state"
              color="secondary"
              label="Adgroup Status"
              options={adGroup_state || []}
              value={filterValue?.adGroup_state || []}
              onChange={(e, value) => {
                handleAutoCompleteOnChange(value, 'ad_group_status');
              }}
              optionLabelkey="name"
              optionIdkey="id"
              inputValue={isDropdownOpenCamAdSt ? searchText?.ad_group_status : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, ad_group_status: value });
                }
              }}
              onOpen={() => setIsDropdownOpenCamAdSt(true)}
              onClose={() => setIsDropdownOpenCamAdSt(false)}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('ad_group_status')}
              placeholderCondition={filterValue?.adGroup_state?.length > 0}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              limitTags={2}
            />
          </Grid>
        )}

        {filterDataList && filterDataList?.Common?.find((item) => item?.value == 'adgroup') && (
          <Grid item xs={12} md={3}>
            <BasicPopover
              tableFilter={tableFilter}
              setTableFilter={setTableFilter}
              searchCampaign={searchCampaign}
              setSearchCampaign={setSearchCampaign}
              from={'Adgroup'}
              isReset={shouldApplyFilter?.current}
            />
          </Grid>
        )}

        {filterDataList?.Performance && (
          <>
            {filterDataList?.Performance?.map((performanceItem) => (
              <Grid item xs={12} md={3} key={performanceItem.id}>
                <FilterPerformance
                  performanceItem={performanceItem}
                  isReset={shouldApplyFilter?.current}
                  handleInputChange={handleInputChange}
                />
              </Grid>
            ))}
          </>
        )}

        <Grid item md={3}>
          <Button
            sx={{
              borderRadius: '8px',
              py: '12px',
              minWidth: '135px'
            }}
            disabled={finalErrorShow}
            startIcon={<img src={searchIcon} alt="filter icon" style={{ width: '18px', height: '18px', fontFamily: 'Inter' }} />}
            onClick={handleSearchClick}
            size=""
            variant="contained"
          >
            Search
          </Button>
        </Grid>

        {/* Open confirmation popup */}
        <Dialog open={isConfirmationOpen} onClose={handleCloseConfirmation}>
          <DialogTitle>Edit Adgroup Details</DialogTitle>
          <DialogContent dividers>
            <DialogContentText>Are you sure you want to Edit this Adgroup Details?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirmation} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button variant="contained" onClick={handleUpdateAdGroupReport} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>

      {campaignModal.open && (
        <Dialog
          open={campaignModal.open}
          keepMounted
          onClose={handledEditClose}
          maxWidth="md"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="div"> Edit SP Campaign</Typography>
              <IconButton onClick={handledEditClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <EditCampaignSetting
              campaignModal={campaignModal}
              onClose={handledEditClose}
              GetApi={() =>
                getAdgroupListWithPagination(
                  page,
                  rowPerPage,
                  search,
                  appliedSortTing?.changedColumnSort,
                  appliedSortTing?.directionSort,
                  commonAdvertiser,
                  filterForSearch,
                  PerformanceFilterData
                )
              }
            />
          </DialogContent>
        </Dialog>
      )}

      {itemModal.open && (
        <Dialog
          open={itemModal.open}
          keepMounted
          onClose={handleItemModalClose}
          maxWidth="md"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="div"> Add item</Typography>
              <IconButton onClick={handleItemModalClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <ProductAdGroupSetting
              GetApi={() =>
                getAdgroupListWithPagination(
                  page,
                  rowPerPage,
                  search,
                  appliedSortTing?.changedColumnSort,
                  appliedSortTing?.directionSort,
                  commonAdvertiser,
                  filterForSearch,
                  PerformanceFilterData
                )
              }
              itemModal={itemModal}
              onClose={handleItemModalClose}
            />
          </DialogContent>
        </Dialog>
      )}

      {keywordModal.open && (
        <Dialog
          open={keywordModal.open}
          keepMounted
          onClose={handleKeywordModalClose}
          maxWidth="md"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="div"> Add Keyword</Typography>
              <IconButton onClick={handleKeywordModalClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            {/* <EditCampaignSetting campaignModal={itemModal} onClose={handleItemModalClose} /> */}
            <TargetAdGroupSetting keywordModal={keywordModal} onClose={handleKeywordModalClose} addKeywordModel={false} />
          </DialogContent>
        </Dialog>
      )}

      {campaignBrandModal.open && (
        <Dialog
          open={campaignBrandModal.open}
          keepMounted
          onClose={handledEditBrandClose}
          maxWidth="md"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="div"> Edit SB Campaign</Typography>
              <IconButton onClick={handledEditBrandClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <EditCampaignBrandSetting campaignModal={campaignBrandModal} onClose={handledEditBrandClose} />
          </DialogContent>
        </Dialog>
      )}

      {campaignVideoModal.open && (
        <Dialog
          open={campaignVideoModal.open}
          keepMounted
          onClose={handleEditVideoClose}
          maxWidth="md"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="div"> Edit SV Campaign</Typography>
              <IconButton onClick={handleEditVideoClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers sx={{ width: '900px' }}>
            <EditCampaignVideoSetting campaignModal={campaignVideoModal} onClose={handleEditVideoClose} />
          </DialogContent>
        </Dialog>
      )}

      {skeletonLoader && <TableSkeleton />}
      <Box sx={{ display: skeletonLoader ? 'none' : 'block' }} className={showSelectAdviser ? 'custom-adgroup-table' : ''}>
        <MUIDataTable
          actions={[
            {
              icon: 'save'
            }
          ]}
          data={tableLoading ? [] : showSelectAdviser ? newData : []}
          columns={getUpdatedColumns(columns, selectedColumns)}
          options={options}
          className="pnl-by-item"
        />
      </Box>

      {viewColumnsSelector && (
        <CustomColumnSelector
          open={viewColumnsSelector}
          handleClose={handleColumnSelectorClose}
          tableName="adgroup_column"
          defaultSelected={selectedColumns}
          columns={getUpdatedColumns(columns, selectedColumns)}
          setColumnOrder={setColumnOrder}
          columnOrder={columnOrder}
        />
      )}
    </MainCard>
  );
};

export default Adgroup;
