import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useState, useMemo, useCallback } from 'react';
import { debounce } from 'lodash';
import OutlinedDiv from 'views/filterPerformance/Outline';

const FilterAdvanced = (props) => {
  const { AdvanceItem, handleInputChangeAdvanced, isReset } = props;

  const [min, setMin] = useState();
  const [max, setMax] = useState();

  const handleKeyPress = (e) => {
    // Prevent the input of '-' and 'e' characters
    if (e.key === '-' || e.key === 'e') {
      e.preventDefault();
    }
  };

  // Debounced version of handleInputChangeAdvanced
  const debouncedHandleInputChangeAdvanced = useCallback(
    debounce((field, type, value, error) => {
      handleInputChangeAdvanced(field, type, value, error);
    }, 500), // Adjust debounce delay as needed
    [handleInputChangeAdvanced]
  );

  // Reset fields when isReset changes
  useMemo(() => {
    if (!isReset) {
      setMin(null);
      setMax(null);
    }
  }, [isReset]);

  const handleMinChange = (e) => {
    const value = e.target.value;
    setMin(value);
    debouncedHandleInputChangeAdvanced(
      AdvanceItem.value,
      'min',
      value || '',
      value?.length > 0 && max?.length > 0 && Number(value) > Number(max)
    );
  };

  const handleMaxChange = (e) => {
    const value = e.target.value;
    setMax(value);
    debouncedHandleInputChangeAdvanced(
      AdvanceItem.value,
      'max',
      value || '',
      min?.length > 0 && value?.length > 0 && Number(min) > Number(value)
    );
  };

  return (
    <div key={AdvanceItem?.id} className="performance-wrapper">
      <OutlinedDiv showError={min?.length > 0 && max?.length > 0 && Number(min) > Number(max)} label={AdvanceItem?.name}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            type="number"
            sx={{
              '& fieldset': { border: 'none' }
            }}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*' // Allow only digits
            }}
            value={min || ''}
            id="minPrice"
            placeholder="Min"
            className="ddd"
            size="small"
            onKeyPress={handleKeyPress}
            disableFocusRipple
            onChange={handleMinChange}
            style={{
              width: '45%',
              border: 'none',
              borderRadius: '5px',
              padding: '0px',
              outline: 'none',
              fontSize: '12px'
            }}
          />
          ~
          <TextField
            type="number"
            id="maxPrice"
            value={max || ''}
            sx={{
              '& fieldset': { border: 'none' },
              padding: '2.5px 4px 2.5px 8px !important'
            }}
            size="small"
            className="ddd"
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*' // Allow only digits
            }}
            disableFocusRipple
            onKeyPress={handleKeyPress}
            onChange={handleMaxChange}
            placeholder="Max"
            style={{
              width: '45%',
              border: 'none',
              borderRadius: '5px',
              padding: '2px',
              outline: 'none',
              fontSize: '12px'
            }}
          />
        </Box>
      </OutlinedDiv>
    </div>
  );
};

export default FilterAdvanced;
