import * as Sentry from '@sentry/react';

const sentryMiddleware = () => (next) => (action) => {
  if (action.type === 'notification/notificationFail') {
    Sentry.captureException(new Error(action.payload));
  }
  return next(action);
};

export default sentryMiddleware;
