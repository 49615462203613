import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  commonLoader: false,
  skeletonLoader: true,
  tableLoader: false,
  userDetails: [],
  allUserDetails: [],
  campaignDetails: {},
  campaignDetailsTotal: {},
  selectdItemData: [],
  keywordItemData: [],
  SuggestedKeywordItemData: [],
  filteredData: [],
  customSaveReportData: [],
  adgoupDetails: {},
  suggestedkeywordData: [],
  existingCampaignId: null,
  existingAdgroupId: null,
  campaignById: {},
  brandId: null,
  adgroupList: [],
  campaignDetailViewData: {},
  reviewLogData: [],
  adgroupDetails: [],
  adgroupDetailsTotal: {},
  campaignByIdData: {},
  advertiserState: [],
  userManagementDetails: [],
  latestReportDateData: {},
  apiUsageAnalyzeData: {},
  profileDetails: [],
  verifyUserDetails: [],
  commonAdvertiser: [],
  commonDateRange: { startDate: '', endDate: '' },
  isComparingDates: false,
  searchItems: [],
  videoLibraryList: [],
  itemsDetails: [],
  keywordsDetails: [],
  campaignNameList: [],
  userSortInfo: [],
  verifyCampaignFile: {},
  bulkOperations: [],
  campaignShortData: [],
  dashboardReport: [],
  adGroupShortList: [],
  adItemShortList: [],
  keywordShortList: [],
  brandLists: [],
  BudgetData: [],
  StorData: [],
  onDemandData: [],
  TopProductData: [],
  PerformanceComparisonData: [],
  BudgetPerformanceData: [],
  BudgetProfiles: [],
  EngagementData: [],
  SharedLinkData: [],
  AdPerformanceData: [],
  isDashboardLinkVerified: [],
  dashboardAdvertiserList: [],
  selectedDashboardAdvertiser: [],
  dashboardDateRange: [],
  isDashboardCompareDates: false,
  creatorData: [],
  selectedStoreData: {},
  AllStorDataAdvertiser: [],
  rankTrackerDetails: {},
  rankTrackerDetailsForCSV: {},
  selectedRankTrackerDetails: {},
  ruleVariables: {},
  createdRule: {},
  rulesList: [],
  ruleExists: false,
  BIDashboard: ''
};

const userDetailsSlice = createSlice({
  name: 'userDetailsSlice',
  initialState,
  reducers: {
    setReset: () => initialState,
    setCommonLoader: (state, action) => ({
      ...state,
      commonLoader: action.payload
    }),
    setSkeletonLoader: (state, action) => ({
      ...state,
      skeletonLoader: action.payload
    }),
    setTableLoader: (state, action) => ({
      ...state,
      tableLoader: action.payload
    }),
    setUserDetails: (state, action) => ({
      ...state,
      userDetails: action.payload
    }),
    setOnDemandData: (state, action) => ({
      ...state,
      onDemandData: action.payload
    }),
    setTopProductData: (state, action) => ({
      ...state,
      TopProductData: action.payload
    }),
    setPerformanceComparisonData: (state, action) => ({
      ...state,
      PerformanceComparisonData: action.payload
    }),
    setBudgetManagerData: (state, action) => ({
      ...state,
      BudgetData: action.payload
    }),
    setStorAdData: (state, action) => ({
      ...state,
      StorData: action.payload
    }),
    setAllUserDetails: (state, action) => ({
      ...state,
      allUserDetails: action.payload
    }),
    setCampaignDetails: (state, action) => ({
      ...state,
      campaignDetails: action.payload
    }),
    setCampaignTotal: (state, action) => ({
      ...state,
      campaignDetailsTotal: action.payload
    }),
    setAdgorupDetails: (state, action) => ({
      ...state,
      adgoupDetails: action.payload
    }),
    setSelectedKeyword: (state, action) => ({
      ...state,
      keywordTrackerDetails: action.payload
    }),
    setSelectItemData: (state, action) => ({
      ...state,
      selectdItemData: action.payload
    }),
    setSelectItemKeywordTracking: (state, action) => ({
      ...state,
      keywordItemData: action.payload
    }),
    setSelectSuggestedKeywordTracking: (state, action) => ({
      ...state,
      SuggestedKeywordItemData: action.payload
    }),
    setSelectedFilterData: (state, action) => ({
      ...state,
      filteredData: action.payload
    }),
    setSelectedCustomReport: (state, action) => ({
      ...state,
      customSaveReportData: action.payload
    }),
    setSuggestedkeywordData: (state, action) => ({
      ...state,
      suggestedkeywordData: action.payload
    }),
    setExistingCampaignId: (state, action) => ({
      ...state,
      existingCampaignId: action.payload
    }),
    setExistingAdgroupId: (state, action) => ({
      ...state,
      existingAdgroupId: action.payload
    }),
    setExistingBrandId: (state, action) => ({
      ...state,
      brandId: action.payload
    }),
    setCampaignById: (state, action) => ({
      ...state,
      campaignById: action.payload
    }),
    setAdgroupList: (state, action) => ({
      ...state,
      adgroupList: action.payload
    }),
    setCampaignDetailViewData: (state, action) => ({
      ...state,
      campaignDetailViewData: action.payload
    }),
    setReviewLogData: (state, action) => ({
      ...state,
      reviewLogData: action.payload
    }),
    setAdgroupDetails: (state, action) => ({
      ...state,
      adgroupDetails: action.payload
    }),
    setAdgroupDetailsTotal: (state, action) => ({
      ...state,
      adgroupDetailsTotal: action.payload
    }),
    setCampaignByIdData: (state, action) => ({
      ...state,
      campaignByIdData: action.payload
    }),
    setAdvertiserState: (state, action) => ({
      ...state,
      advertiserState: action.payload
    }),
    setUserManagementDetails: (state, action) => ({
      ...state,
      userManagementDetails: action.payload
    }),
    setVerifiedUserDetails: (state, action) => ({
      ...state,
      verifyUserDetails: action.payload
    }),
    setLatestReportDateData: (state, action) => ({
      ...state,
      latestReportDateData: action.payload
    }),
    setApiUsageAnalyzeData: (state, action) => ({
      ...state,
      apiUsageAnalyzeData: action.payload
    }),
    setProfileDetails: (state, action) => ({
      ...state,
      profileDetails: action.payload
    }),
    setCommonAdvertiser: (state, action) => ({
      ...state,
      commonAdvertiser: action.payload
    }),
    setCommonDateRange: (state, action) => ({
      ...state,
      commonDateRange: action.payload
    }),
    setIsCompareDates: (state, action) => ({
      ...state,
      isComparingDates: action.payload
    }),
    setDashboardDateRange: (state, action) => ({
      ...state,
      dashboardDateRange: action.payload
    }),
    setIsDashboardCompareDates: (state, action) => ({
      ...state,
      isDashboardCompareDates: action.payload
    }),
    setSearchItems: (state, action) => ({
      ...state,
      searchItems: action.payload
    }),
    setVideoLibraryList: (state, action) => ({
      ...state,
      videoLibraryList: action.payload
    }),
    setItemsDetails: (state, action) => ({
      ...state,
      itemsDetails: action.payload
    }),
    setItemsDetailsTotal: (state, action) => ({
      ...state,
      itemsDetailsTotal: action.payload
    }),
    setKeywordsDetails: (state, action) => ({
      ...state,
      keywordsDetails: action.payload
    }),
    setKeywordsDetailsTotal: (state, action) => ({
      ...state,
      keywordsDetailsTotal: action.payload
    }),
    setCampaignNameList: (state, action) => ({
      ...state,
      campaignNameList: action.payload
    }),
    setUserSortInfo: (state, action) => ({
      ...state,
      userSortInfo: action.payload
    }),
    setVerifyCampaignFile: (state, action) => ({
      ...state,
      verifyCampaignFile: action.payload
    }),
    setBulkOperations: (state, action) => ({
      ...state,
      bulkOperations: action.payload
    }),
    setAllCampaignShortData: (state, action) => ({
      ...state,
      campaignShortData: action.payload
    }),
    setDashboardReport: (state, action) => ({
      ...state,
      dashboardReport: action.payload
    }),
    setAdGroupShortList: (state, action) => ({
      ...state,
      adGroupShortList: action.payload
    }),
    setAdItemShortList: (state, action) => ({
      ...state,
      adItemShortList: action.payload
    }),
    setKeywordShortList: (state, action) => ({
      ...state,
      keywordShortList: action.payload
    }),
    setAllBrandLists: (state, action) => ({
      ...state,
      brandLists: action.payload
    }),
    setBudgetPerformanceData: (state, action) => ({
      ...state,
      BudgetPerformanceData: action.payload
    }),
    setBudgetProfiles: (state, action) => ({
      ...state,
      BudgetProfiles: action.payload
    }),
    setAdPerformanceData: (state, action) => ({
      ...state,
      AdPerformanceData: action.payload
    }),
    setEngagementData: (state, action) => ({
      ...state,
      EngagementData: action.payload
    }),
    setSharedLinkData: (state, action) => ({
      ...state,
      SharedLinkData: action.payload
    }),
    setCreatorData: (state, action) => ({
      ...state,
      creatorData: action.payload
    }),
    setAllStoreData: (state, action) => ({
      ...state,
      AllStorDataAdvertiser: action.payload
    }),
    setIsDashboardLinkVerified: (state, action) => ({
      ...state,
      isDashboardLinkVerified: action.payload
    }),
    setDashboardAdvertiserList: (state, action) => ({
      ...state,
      dashboardAdvertiserList: action.payload
    }),
    setSelectedDashboardAdvertiser: (state, action) => ({
      ...state,
      selectedDashboardAdvertiser: action.payload
    }),
    setSelectedStoreData: (state, action) => ({
      ...state,
      selectedStoreData: action.payload
    }),
    setRankTrackerDetails: (state, action) => ({
      ...state,
      rankTrackerDetails: action.payload
    }),
    setRankTrackerDetailsForCSV: (state, action) => ({
      ...state,
      rankTrackerDetailsForCSV: action.payload
    }),
    setSelectedRankTrackerDetails: (state, action) => ({
      ...state,
      selectedRankTrackerDetails: action.payload
    }),
    setRuleVariables: (state, action) => ({
      ...state,
      ruleVariables: action.payload
    }),
    setCreateRule: (state, action) => ({
      ...state,
      createdRule: action.payload
    }),
    setRulesList: (state, action) => ({
      ...state,
      rulesList: action.payload
    }),
    setRuleExists: (state, action) => ({
      ...state,
      ruleExists: action.payload
    }),
    setBIDashboard: (state, action) => ({
      ...state,
      BIDashboard: action.payload
    })
  }
});

export const {
  setCommonLoader,
  setSkeletonLoader,
  setTableLoader,
  setUserDetails,
  setOnDemandData,
  setTopProductData,
  setPerformanceComparisonData,
  setBudgetManagerData,
  setStorAdData,
  setAllUserDetails,
  setCampaignDetails,
  setCampaignTotal,
  setSelectItemData,
  setSelectItemKeywordTracking,
  setSelectSuggestedKeywordTracking,
  setSelectedFilterData,
  setSelectedCustomReport,
  setAdgorupDetails,
  setSelectedKeyword,
  setSuggestedkeywordData,
  setExistingCampaignId,
  setExistingAdgroupId,
  setCampaignById,
  setAdgroupList,
  setExistingBrandId,
  setCampaignDetailViewData,
  setReviewLogData,
  setAdgroupDetails,
  setAdgroupDetailsTotal,
  setCampaignByIdData,
  setAdvertiserState,
  setUserManagementDetails,
  setLatestReportDateData,
  setApiUsageAnalyzeData,
  setProfileDetails,
  setVerifiedUserDetails,
  setCommonAdvertiser,
  setCommonDateRange,
  setIsCompareDates,
  setReset,
  setSearchItems,
  setVideoLibraryList,
  setItemsDetails,
  setItemsDetailsTotal,
  setKeywordsDetails,
  setKeywordsDetailsTotal,
  setCampaignNameList,
  setUserSortInfo,
  setVerifyCampaignFile,
  setBulkOperations,
  setAllCampaignShortData,
  setDashboardReport,
  setAdItemShortList,
  setAdGroupShortList,
  setKeywordShortList,
  setAllBrandLists,
  setBudgetPerformanceData,
  setBudgetProfiles,
  setAdPerformanceData,
  setEngagementData,
  setSharedLinkData,
  setCreatorData,
  setAllStoreData,
  setIsDashboardLinkVerified,
  setDashboardAdvertiserList,
  setSelectedDashboardAdvertiser,
  setDashboardDateRange,
  setIsDashboardCompareDates,
  setSelectedStoreData,
  setRankTrackerDetails,
  setRankTrackerDetailsForCSV,
  setSelectedRankTrackerDetails,
  setRuleVariables,
  setCreateRule,
  setRulesList,
  setRuleExists,
  setBIDashboard
} = userDetailsSlice.actions;

export default userDetailsSlice.reducer;
