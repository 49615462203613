import { useState, useEffect } from 'react';
// import { useAppSelector } from 'store';
import colors from 'assets/scss/_themes-vars.module.scss';
import {
  // useParams,
  useLocation
} from 'react-router-dom';
import { useAppDispatch } from 'store';
import { createStoreWithOAuth, updateStoreWithOAuth, updateStoreOAuthExternalLink } from 'store/thunk/userThunk';
import { decryptText } from 'helper/commonHelper';
// import { setCommonLoader } from 'store/slices/userDetailsSlice';
import CustomLoader from 'ui-component/custom-loader';

// material-ui
import { Grid, Typography, Button, IconButton, Box, Container, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// project imports
import DashnaLogo from 'assets/images/dashna/dashnaIco.svg';
import SuccessIcon from 'assets/images/icons/success.svg';
import WarningIcon from 'assets/images/dashna/warning.svg';

const WalmartRedirectSuccess = () => {
  const dispatch = useAppDispatch();
  // const { sellerType } = useParams(); // Get the 3p or 1p from the URL
  const query = new URLSearchParams(useLocation().search);

  // const { selectedStoreData } = useAppSelector((state) => state?.userDetailsSlice);
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [response, setResponce] = useState();
  const [responseOAuthUpdate, setResponceOAuthUpdate] = useState();
  //Color
  const primaryMain = colors?.primaryMain;
  const errorMain = colors?.errorMain;
  const primaryTextColor = colors?.primaryTextColor;
  const pageBackground = colors?.pageBackground;
  const paper = colors?.paper;
  const borderColor = colors?.grey300;

  const setSuccessMessage = (addNewStoreExternal, sellerType) => {
    if (addNewStoreExternal) {
      setMessage(
        'Your Walmart store has been successfully connected. We’ll now begin syncing your data to ensure smooth management and optimization of your store. You may now close this tab.'
      );
    } else {
      if (sellerType === '1p') {
        setMessage(
          'Your Supplier One store has been successfully connected. You can  close this tab and continue where you left off.'
        );
      }
      if (sellerType === '3p') {
        setMessage(
          'Your Marketplace store has been successfully connected. You can  close this tab and continue where you left off.'
        );
      }
    }
  };

  //To compare entered Seller/Supplier Id with the responce partner/seller Id, it must be same
  const compareEnterIdWithWalmartId = (stateInfo, partnerId) => {
    const sellerType = stateInfo?.sellerType;
    const enteredId = sellerType === '1p' ? stateInfo?.supplierId : stateInfo?.sellerId;
    if (enteredId === partnerId) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    // Extract the query parameters
    const code = query.get('code');
    // const type = query.get('type');
    // const clientId = query.get('clientId');
    // const sellerId = query.get('sellerId');
    const partnerId = query.get('partnerId') || query.get('sellerId');
    const partnerType = query.get('partnerType');
    const state = query.get('state').replace(/\s/g, '+'); //Get Unique state key passed by us in walmart login responce url
    const checkState = localStorage.getItem(state);
    if (checkState) {
      const stateInfo = JSON.parse(decryptText(checkState));
      //To compare entered Seller/Supplier Id with the responce partner/seller Id, it must be same
      const isIdSame = compareEnterIdWithWalmartId(stateInfo, partnerId);
      if (isIdSame) {
        // console.log('stateInfo ', stateInfo);
        // console.log('sellerType ', stateInfo?.sellerType);
        // console.log('type', type);
        // console.log('partnerId ', partnerId);
        // console.log('partnerType ', partnerType);
        // console.log('checkState ', checkState);
        // console.log('code', code);
        // console.log('clientId', clientId);
        // console.log('sellerId', sellerId);
        // console.log('state', state);

        const editStore = stateInfo?.editStore;

        const request = {
          authorization_code: code,
          nick_name: stateInfo?.storeNickname,
          seller_type: stateInfo?.sellerType,
          seller_id: stateInfo?.sellerId,
          supplier_id: stateInfo?.supplierId,
          distributor_id: stateInfo?.distributor_id,
          secretKey: stateInfo?.secretKey,
          addNewStoreExternal: stateInfo?.addNewStoreExternal,
          is_client_id_and_secret: stateInfo?.is_client_id_and_secret,
          storeId: stateInfo?.storeId,
          partner_id: partnerId?.toString() || '',
          partner_type: partnerType,
          setResponce: setResponce
        };

        setSuccessMessage(stateInfo?.addNewStoreExternal, stateInfo?.sellerType);

        if (editStore) {
          dispatch(updateStoreWithOAuth(request));
        } else {
          dispatch(createStoreWithOAuth(request));
        }
      } else {
        //If entered Seller/Supplier Id is not matched with walmart login responce partner/seller Id
        const type = stateInfo?.sellerType === '1p' ? 'Supplier' : 'Seller';
        const errorMsg = `${type} ID mismatch: The provided ${type} ID does not match the one linked to your Walmart credentials. Please verify you have entered the correct information.`;
        const resp = {
          error: 'closed already',
          message: errorMsg
        };
        setResponce(resp);
        setTitle('Error!');
        setMessage(errorMsg);
        localStorage.setItem('isProcessingStoreCreation', false); //Go back to previous screen in loader screen
      }
    } else {
      const tenantAndWalmartId = decryptText(state);
      if (!tenantAndWalmartId) {
        //when User close the loader screen and click back button then this process is not valid but eventually user completed the Walmart Login Authentication login. then display below error message
        const errorMsg = 'The connection has been interrupted. Please close this tab and try again.';
        const resp = {
          error: 'closed already',
          message: errorMsg
        };
        setResponce(resp);
        setTitle('Oops!');
        setMessage(errorMsg);
      } else {
        //Update Store OAuth
        const result = tenantAndWalmartId.split('_');
        const partnerIdOfDB = result[2]; //if 1p then it is supplier_id and if 3p then it is seller_id passed while update OAuth copy link
        if (result.length) {
          //Partner ID (seller/supplier ID) of Our DB and Partner ID that return from Walmart must be same in the case of Update OAuth
          if (partnerIdOfDB !== partnerId) {
            const msg = `The id from walmart does not match in dashna to verify the correct walmart account is being used or that dashna needs to be updated.
            <br><br>Walmart Id = ${partnerId}
            <br>Dashna Id = ${partnerIdOfDB}`;
            setTitle('Error!');
            setMessage(msg);
            const resp = {
              error: true,
              message: msg
            };
            setResponce(resp);
          } else {
            const request = {
              authorization_code: code,
              tenant_id: Number(result[0]),
              store_id: Number(result[1]), //primary store id
              setResponceOAuthUpdate: setResponceOAuthUpdate
            };
            console.log('Update Store request payload => ', request);
            dispatch(updateStoreOAuthExternalLink(request));
          }
        }
      }
    }
  }, []);

  //When store creation of oAuth walmart is success or error, it will affect the parent loader screen to go next or back of the screen
  useEffect(() => {
    if (typeof response !== 'undefined') {
      if (response?.error === false) {
        setTitle('Success!');
        localStorage.setItem('isProcessingStoreCreation', true);
      }
      if (response?.error === true) {
        setTitle('Error!');
        setMessage(response?.message);
        localStorage.setItem('isProcessingStoreCreation', false);
      }
    }
  }, [response]);

  //When update store using OAuth Api Responce
  useEffect(() => {
    if (typeof responseOAuthUpdate !== 'undefined') {
      if (responseOAuthUpdate?.error === false) {
        const msg = 'Store OAuth Updated Successfully.';
        setTitle('Success!');
        setMessage(msg);
        const resp = {
          error: false,
          message: msg
        };
        setResponce(resp);
      }
      if (responseOAuthUpdate?.error === true) {
        //Display actual error_description return from the Walmart side
        const msg =
          typeof responseOAuthUpdate?.message === 'string'
            ? responseOAuthUpdate?.message
            : responseOAuthUpdate?.message?.error_description || 'Some Unknown error has occured';
        setTitle('Error!');
        setMessage(msg);
        const resp = {
          error: true,
          message: msg
        };
        setResponce(resp);
      }
    }
  }, [responseOAuthUpdate]);

  const handleClose = () => {
    window.close();
  };

  return (
    <>
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} minHeight={'100vh'} backgroundColor={pageBackground}>
        <Container maxWidth="sm" sx={{ maxWidth: '700px !important' }}>
          <Grid item xs={12} md={12}>
            <Box backgroundColor={paper} borderRadius={'10px'} border={`1px solid ${borderColor}`}>
              <Grid item xs={12}>
                {typeof response === 'undefined' ? (
                  <Box px={4} py={18} display={'flex'} justifyContent={'center'}>
                    <CustomLoader />
                  </Box>
                ) : (
                  <>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} px={2} py={0.5}>
                      <Typography className={'ffInter fs20 fw600'} color={response?.error ? errorMain : primaryMain}>
                        {title}
                      </Typography>
                      <IconButton>
                        <img src={DashnaLogo} alt="Dashna Logo" />
                      </IconButton>
                      {typeof responseOAuthUpdate === 'undefined' && (
                        <IconButton
                          // aria-label="close"
                          onClick={handleClose}
                          sx={{
                            // position: 'absolute',
                            // right: 8,
                            // top: 8,
                            color: (theme) => theme.palette.grey[500]
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      )}
                    </Box>
                    <Divider />
                    <Box px={4} py={18}>
                      <Box>
                        <img src={response?.error ? WarningIcon : SuccessIcon} alt="Success icon" />
                      </Box>
                      <Typography color={primaryTextColor} className="ffInter fs24 fw600" mt={2}>
                        {title}
                      </Typography>
                      <Typography color={primaryTextColor} className="ffInter fs16 fw400" mt={2}>
                        <Box dangerouslySetInnerHTML={{ __html: message }}></Box>
                      </Typography>
                    </Box>
                    {typeof responseOAuthUpdate === 'undefined' && (
                      <>
                        <Divider />
                        <Box display={'flex'} justifyContent={'flex-end'} px={3} py={1.5}>
                          <Button onClick={() => handleClose()} variant="outlined" color="primary">
                            Close
                          </Button>
                        </Box>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </Box>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default WalmartRedirectSuccess;
